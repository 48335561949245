<mat-toolbar color="custom" id="main-menu-header">
	<a [routerLink]="[homeUrl]">
		<img src="{{logourl}}" class="logo" />
	</a>
	<span *ngIf="user_type=='company' || user_type=='fleet'" class="ms-5" style="font-size: 14px;">
		{{company_or_fleet?.name}}
	</span>
	<span class="example-spacer"></span>
	<a *ngIf="!isMobile" mat-button [routerLink]="[homeUrl]" [routerLinkActive]="'my-active'" matTooltip="Home">
		<!-- <fa-icon [icon]="['fas', 'home']"></fa-icon>--><span>Home</span>
	</a>
	<a *ngIf="!isMobile" mat-button [routerLink]="[inventoryDashboardUrl]" [routerLinkActive]="'my-active'"
		matTooltip="Pharma">
		<!-- <fa-icon [icon]="['fas', 'capsules']"></fa-icon> --><span>Pharma</span>
	</a>
	<a *ngIf="!isMobile && user_type!='fleet'" mat-button [routerLink]="[mhsDashboardUrl]"
		[routerLinkActive]="'my-active'" matTooltip="Psychometry">
		<!-- <fa-icon [icon]="['fas', 'brain']"></fa-icon> -->
		<span>Psychometry</span>
	</a>
	<a *ngIf="!isMobile" mat-button [routerLink]="[hcNewDashboardUrl]" [routerLinkActive]="'my-active'"
		matTooltip="RMA">
		<!-- <fa-icon [icon]="['fas', 'ship']"></fa-icon> -->
		<span>RMA</span>
	</a>
	<a *ngIf="!isMobile && user_type!='fleet'" mat-button [routerLink]="[clipDashboardUrl]"
		[routerLinkActive]="'my-active'" matTooltip="Shore Care">
		<!-- <fa-icon [icon]="['fas', 'laptop-medical']"></fa-icon> -->
		<span>Shore Care</span>
	</a>
	<button mat-button [ngbPopover]="popContent1" placement="bottom" popoverClass="popup-menu" matTooltip="Menu">
		<fa-icon [icon]="['fas', 'th']"></fa-icon>
	</button>
	<ng-template #popContent1>
		<div class="row">
			<a class="col-lg-4 col-md-4 col-sm-12 col-xs-12 popup-menu-item" [routerLink]="[homeUrl]"
				[routerLinkActive]="'popup-menu-active'">
				<fa-icon [icon]="['fas', 'home']" class="text-danger"></fa-icon>
				<p>Home</p>
			</a>
			<a class="col-lg-4 col-md-4 col-sm-12 col-xs-12 popup-menu-item" [routerLink]="[inventoryDashboardUrl]"
				[routerLinkActive]="'popup-menu-active'">
				<fa-icon [icon]="['fas', 'capsules']" class="text-success"></fa-icon>
				<p>Pharma</p>
			</a>
			<a *ngIf="user_type!='fleet'" class="col-lg-4 col-md-4 col-sm-12 col-xs-12 popup-menu-item"
				[routerLink]="[mhsDashboardUrl]" [routerLinkActive]="'popup-menu-active'">
				<fa-icon [icon]="['fas', 'brain']" class="text-warning"></fa-icon>
				<p>Psychometry</p>
			</a>
			<a class="col-lg-4 col-md-4 col-sm-12 col-xs-12 popup-menu-item" [routerLink]="[hcNewDashboardUrl]"
				[routerLinkActive]="'popup-menu-active'">
				<fa-icon [icon]="['fas', 'ship']" class="text-primary"></fa-icon>
				<p>RMA</p>
			</a>
			<a *ngIf="user_type!='fleet'" class="col-lg-4 col-md-4 col-sm-12 col-xs-12 popup-menu-item"
				[routerLink]="[clipDashboardUrl]" [routerLinkActive]="'popup-menu-active'">
				<fa-icon [icon]="['fas', 'laptop-medical']" class="text-info"></fa-icon>
				<p>Shore Care</p>
			</a>
			<a *ngIf="user_type=='cdm'" class="col-lg-4 col-md-4 col-sm-12 col-xs-12 popup-menu-item"
				[routerLink]="['/app/users']" [routerLinkActive]="'popup-menu-active'">
				<fa-icon [icon]="['fas', 'user-alt']" class="text-danger"></fa-icon>
				<p>Users</p>
			</a>
			<a *ngIf="user_type=='cdm'" class="col-lg-4 col-md-4 col-sm-12 col-xs-12 popup-menu-item"
				[routerLink]="['/app/training']" [routerLinkActive]="'popup-menu-active'">
				<fa-icon [icon]="['fas', 'chalkboard']" class="text-success"></fa-icon>
				<p>Training</p>
			</a>
			<a *ngIf="user_type=='cdm'" class="col-lg-4 col-md-4 col-sm-12 col-xs-12 popup-menu-item"
				[routerLink]="['/app/overhead']" [routerLinkActive]="'popup-menu-active'">
				<fa-icon [icon]="['fas', 'plus']" class="text-warning"></fa-icon>
				<p>Overhead</p>
			</a>
		</div>
	</ng-template>
	<button mat-button [ngbPopover]="popContent2" placement="bottom" popoverClass="popup-menu" matTooltip="MDM"
		*ngIf="user_type=='cdm'">
		<fa-icon [icon]="['fas', 'database']"></fa-icon>
	</button>
	<ng-template #popContent2>
		<div class="row">
			<a class="col-lg-4 col-md-4 col-sm-12 col-xs-12 popup-menu-item" [routerLink]="['/app/mdm-users']"
				[routerLinkActive]="'popup-menu-active'">
				<fa-icon [icon]="['fas', 'users']" class="text-primary"></fa-icon>
				<p>Users</p>
			</a>
			<a class="col-lg-4 col-md-4 col-sm-12 col-xs-12 popup-menu-item" [routerLink]="['/app/company']"
				[routerLinkActive]="'popup-menu-active'">
				<fa-icon [icon]="['fas', 'building']" class="text-info"></fa-icon>
				<p>Companies</p>
			</a>
			<a class="col-lg-4 col-md-4 col-sm-12 col-xs-12 popup-menu-item" [routerLink]="['/app/fleet']"
				[routerLinkActive]="'popup-menu-active'">
				<fa-icon [icon]="['fas', 'city']" class="text-success"></fa-icon>
				<p>Fleets</p>
			</a>
			<a class="col-lg-4 col-md-4 col-sm-12 col-xs-12 popup-menu-item" [routerLink]="['/app/country']"
				[routerLinkActive]="'popup-menu-active'">
				<fa-icon [icon]="['fas', 'flag']" class="text-danger"></fa-icon>
				<p>Countries</p>
			</a>
			<a class="col-lg-4 col-md-4 col-sm-12 col-xs-12 popup-menu-item" [routerLink]="['/app/rank']"
				[routerLinkActive]="'popup-menu-active'">
				<fa-icon [icon]="['fas', 'star']" class="text-warning"></fa-icon>
				<p>Ranks</p>
			</a>
			<a class="col-lg-4 col-md-4 col-sm-12 col-xs-12 popup-menu-item" [routerLink]="['/app/vessel']"
				[routerLinkActive]="'popup-menu-active'">
				<fa-icon [icon]="['fas', 'ship']" class="text-primary"></fa-icon>
				<p>Vessels</p>
			</a>
			<a class="col-lg-4 col-md-4 col-sm-12 col-xs-12 popup-menu-item" [routerLink]="['/app/vessel-type']"
				[routerLinkActive]="'popup-menu-active'">
				<fa-icon [icon]="['fas', 'bars']" class="text-info"></fa-icon>
				<p>Vessel Types</p>
			</a>
			<a class="col-lg-4 col-md-4 col-sm-12 col-xs-12 popup-menu-item" [routerLink]="['/app/poc']"
				[routerLinkActive]="'popup-menu-active'">
				<fa-icon [icon]="['fas', 'user']" class="text-success"></fa-icon>
				<p>POC</p>
			</a>
			<a class="col-lg-4 col-md-4 col-sm-12 col-xs-12 popup-menu-item" [routerLink]="['/app/owners']"
				[routerLinkActive]="'popup-menu-active'">
				<fa-icon [icon]="['fas', 'users']" class="text-danger"></fa-icon>
				<p>Owners</p>
			</a>
		</div>
	</ng-template>

	<button mat-button [matMenuTriggerFor]="mymenu">
		<ng-container *ngIf="!isMobile">
			<fa-icon [icon]="['fas', 'user-circle']"></fa-icon>
			{{user_name}}
			<fa-icon [icon]="['fas', 'angle-down']"></fa-icon>
		</ng-container>
		<fa-icon *ngIf="isMobile" [icon]="['fas', 'user-circle']"></fa-icon>
	</button>
	<mat-menu #mymenu="matMenu">
		<button mat-menu-item (click)="resetPasswordModal()">
			<fa-icon [icon]="['fas', 'lock']"></fa-icon>
			Change Password
		</button>
		<button mat-menu-item (click)="logoutUser()">
			<fa-icon [icon]="['fas', 'power-off']"></fa-icon>
			Logout
		</button>
	</mat-menu>
</mat-toolbar>
<div class="main-content" id="page-content-container-common" [@routeAnimations]="prepareRoute(o)">
	<router-outlet #o="outlet"></router-outlet>
</div>
<a *ngIf="showScrollBtn" (click)="scrollToTop()" id="scrollToTopButton" matTooltip="Scroll to top">
	<fa-icon [icon]="['fas', 'angle-up']" style="font-size: 15px;"></fa-icon>
</a>
<div class="modal fade" id="changePasswordModal" role="dialog">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<form class="form-horizontal" [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()"
				#passform="ngForm">
				<div class="modal-header">
					<h4 class="modal-title" id="modal-basic-title">Change Password</h4>
					<button type="button" class="btn-close" (click)="clearResetPassword()"></button>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-sm-4">
							<mat-form-field>
								<mat-label>New Password</mat-label>
								<input type="password" matInput id="password_nav" formControlName="password"
									name="password">
								<mat-error
									*ngIf="f.password.invalid && (passform.submitted || f.password.touched || f.password.dirty)">
									<span *ngIf="f.password.errors?.required">
										Required
									</span>
								</mat-error>
							</mat-form-field>
						</div>
						<div class="col-sm-4">
							<mat-form-field>
								<mat-label>Confirm Password</mat-label>
								<input type="password" matInput id="confirmPassword_nav"
									formControlName="confirmPassword" name="confirmPassword">
								<mat-error
									*ngIf="f.confirmPassword.invalid && (passform.submitted || f.confirmPassword.touched || f.confirmPassword.dirty)">
									<span *ngIf="f.confirmPassword.errors?.required">
										Required
									</span>
								</mat-error>
							</mat-form-field>
							<mat-error
								*ngIf="resetPasswordForm.errors && (passform.submitted || f.confirmPassword.touched)">
								<p *ngIf="resetPasswordForm.errors?.passwordNotMatch">
									Password and Confirm Password not matched!
									<fa-icon [icon]="['fas', 'times']"></fa-icon>
								</p>
							</mat-error>
						</div>
						<div class="col-sm-4">
							<div *ngIf="f.password.invalid && (passform.submitted || f.password.touched)" type="danger">
								<p [ngClass]="f.password.errors?.minlengtherror?'text-danger':'text-success'">
									Must be atleast 8 characters long.
								</p>
								<p [ngClass]="f.password.errors?.specialcharerror?'text-danger':'text-success'">
									Must have atleast 1 special character.
								</p>
								<p [ngClass]="f.password.errors?.uppercaseerror?'text-danger':'text-success'">
									Must have atleast 1 upper case character.
								</p>
								<p [ngClass]="f.password.errors?.lowercaseerror?'text-danger':'text-success'">
									Must have atleast 1 lower case character.
								</p>
								<p [ngClass]="f.password.errors?.digiterror?'text-danger':'text-success'">
									Must have atleast 1 digit.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="submit" class="btn btn-outline-primary btn-sm">Reset</button>
					<button type="button" class="btn btn-outline-warning btn-sm" data-dismiss="modal"
						(click)="clearResetPassword();">Cancel
					</button>
				</div>
			</form>
		</div>
	</div>
</div>