<breadcrumb [breadcrumbList]="breadcrumbList"></breadcrumb>
<div *ngIf="accessLoader" class="loader text-center">
	<img src="../assets/img/cc_loader.gif" />
</div>
@if (user_type=='cdm') {
@if(UserModuleAccess=='Full'){
<ng-container [ngTemplateOutlet]="dashboardtemplate"></ng-container>
}
} @else if(user_type=='company') {
@if(UserModuleAccess=='Full' && !isCompanyIdNull){
<ng-container [ngTemplateOutlet]="dashboardtemplate"></ng-container>
}
}
<ng-template #dashboardtemplate>
	<!-- <div class="card mb-3">
		<div class="card-header bg-primary">
			<h4 class="text-light">{{pagetitle}}</h4>
		</div>
		<div class="card-body mar-b-20">
			<mat-accordion [hideToggle]="false">
				<mat-expansion-panel (opened)="accordionOpened('BillingListReports')" [expanded]="false">
					<mat-expansion-panel-header class="bg-acc_style_1">
						<mat-panel-title>
							<b>{{BillingListReportsHeader}}</b>
						</mat-panel-title>
						<mat-panel-description>
							<button class="btn btn-sm btn-light" matTooltip="Excel" [matTooltipPosition]="'below'"
								(click)="getBillingListReportsExcel($event)">
								<fa-icon *ngIf="!BillingListReportsExcelLoader"
									[icon]="['fas', 'cloud-download-alt']"></fa-icon>
								<fa-icon *ngIf="BillingListReportsExcelLoader" [icon]="['fas', 'spinner']"
									animation="spin"></fa-icon>
							</button>
						</mat-panel-description>
					</mat-expansion-panel-header>
					<div class="row">
						<div class="col-lg-12">
							<date-filter (changed)="dateChanged($event)"></date-filter>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-12">
							<div class="row">
								<div class="col-sm-12">
									<div class="table-responsive" style="height: 450px;overflow-y: auto;">
										<table class="myTable table table-bordered table-hover mt-2"
											style="width: 100%;">
											<thead>
												<tr>
													<th >Company</th>
													<th >Invoice No.</th>
													<th >Invoice Date</th>
													<th >Appt. Count</th>
													<th >Amount</th>
													<th >Currency</th>
													<th >Due Date</th>
												</tr>
											</thead>
											<tbody *ngIf="BillingListReports?.length != 0">
												<tr class="table_td_style"
													*ngFor="let a of BillingListReports;let i = index;">
													<td>{{a.company}}</td>
													<td>{{a.invoice_no}}</td>
													<td>{{a.invoice_date}}</td>
													<td>{{a.appt_count}}</td>
													<td>{{a.amount}}</td>
													<td>{{a.currency}}</td>
													<td>{{a.due_date}}</td>
												</tr>
											</tbody>
										</table>
										<p *ngIf="BillingListReports?.length == 0 && !BillingListReportsLoader"
											class="text-center">
											No data found!
										</p>
										<p *ngIf="BillingListReportsLoader" class="text-center text-bold">
											<mat-spinner style="margin: 0 auto;"></mat-spinner>
											Loading...
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</mat-expansion-panel>
			</mat-accordion>
		</div>
	</div> -->

	<div class="card mar-t-20">
		<div class="card-header bg-primary">
			<h4 class="text-light">Psychometry Dashboard </h4>
		</div>
		<div class="card-body">
			<div class="row">
				<!-- <div class="col-lg-12">
				<date-filter (changed)="dateFilterPsychometry ($event)"></date-filter>
			</div> -->
				<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
					<mat-form-field>
						<mat-label>Start Date</mat-label>
						<input matInput [matDatepicker]="start_date_picker_1" [(ngModel)]="cardsFilter.start_date">
						<mat-hint>MM/DD/YYYY</mat-hint>
						<mat-datepicker-toggle matIconSuffix [for]="start_date_picker_1"></mat-datepicker-toggle>
						<mat-datepicker #start_date_picker_1></mat-datepicker>
					</mat-form-field>
				</div>
				<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
					<mat-form-field>
						<mat-label>End Date</mat-label>
						<input matInput [matDatepicker]="end_date_picker_1" [matDatepickerFilter]="filterDates"
							[(ngModel)]="cardsFilter.end_date">
						<mat-hint>MM/DD/YYYY</mat-hint>
						<mat-datepicker-toggle matIconSuffix [for]="end_date_picker_1"></mat-datepicker-toggle>
						<mat-datepicker #end_date_picker_1></mat-datepicker>
					</mat-form-field>
				</div>
				<div class="col-lg-5 col-md-5 col-sm-12 col-xs-12" *ngIf="user_type=='cdm'">
					<ng-select appearance="outline" id="company" appendTo="body" [items]="companyList_dropdown"
						bindLabel="name" [clearable]="true" [multiple]="false" [(ngModel)]="cardsFilter.company_id"
						placeholder="Select Company" (change)="setCompany(cardsFilter.company_id)">
					</ng-select>
				</div>
				<div class="col-lg-1 col-md-1 col-sm-6 col-xs-6">
					<button type="button" class="btn btn-md btn-success mt-2 radius-50"
						(click)="mhs_dashboard_no_candidates_per_day();getCompanyWiseData();" matTooltip="Search">
						<fa-icon [icon]="['fas', 'search']"></fa-icon>
					</button>
				</div>
			</div>
			<ng-container>
				<div class="row">
					<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 mt-2 mb-2">
						<div class="card">
							<div class="card-header">
								<h4>NUMBER OF CANDIDATES / DAY</h4>
							</div>
							<div class="card-body scroll">
								<p class="text-center" *ngIf="no_candidates_per_day_loader">
									<fa-icon [icon]="['fas', 'spinner']" animation="spin" size="3x"></fa-icon>
								</p>
								<ng-container *ngIf="no_candidates_per_day.length && !no_candidates_per_day_loader">

									<div>
										<ngx-charts-bar-vertical [results]="no_candidates_per_day" [gradient]="false"
											[xAxis]="false" [yAxis]="true" [legend]="false"
											[legendPosition]="pieChartLegendPosition" [legendTitle]="''"
											[showXAxisLabel]="true" [showYAxisLabel]="true" [xAxisLabel]="''"
											[yAxisLabel]="''" [animations]="true"
											[customColors]="noOfCandidatesPerDayColors" [trimXAxisTicks]="true"
											[rotateXAxisTicks]="true">
										</ngx-charts-bar-vertical>
										<!-- [view]="[getHeightWidthForChart('sub_symptoms_bar_chart_hc').width,getHeightWidthForChart('sub_symptoms_bar_chart_hc').height]" -->
									</div>
									<div class="text-center ms-auto me-auto">
										<div style="color: gray;font-size: 12px;" class="legend-item text-center">
											<div class="legend-color-box" style="background-color:#ff6037">&nbsp;
											</div>
											CASES
										</div>
									</div>
								</ng-container>
							</div>
						</div>

					</div>
					<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 mt-2 mb-2">
						<div class="card ">
							<div class="card-header">
								<h4>KEY PERSONALITY TRAITS SCREENED</h4>
							</div>
							<div class="card-body scroll">
								<ng-select [items]="personalityScales" [(ngModel)]="cardsFilter.personalityScale"
									(change)="getPersonalityScales()" [multiple]="true" bindLabel="name" bindValue="id"
									placeholder="Select Personality Scale" [clearable]="false">
									<ng-template ng-label-tmp let-item="item">
										{{item.name}}<span (click)="deselectByIdClinical(item.id)"
											style="cursor: pointer;"></span>
									</ng-template>
									<ng-template ng-option-tmp let-item="item" let-search="searchTerm"
										let-index="index">
										{{item.name}}
									</ng-template>
								</ng-select>
								<p class="text-center" *ngIf="keyPersonalityLoader">
									<fa-icon [icon]="['fas', 'spinner']" animation="spin" size="3x"></fa-icon>
								</p>
								<div *ngIf="!keyPersonalityLoader">
									<ngx-charts-bar-horizontal-stacked [results]="personalityScalesBar"
										[gradient]="false" [xAxis]="true" [yAxis]="true" [legend]="true"
										[legendPosition]="pieChartLegendPosition" [legendTitle]="''"
										[showXAxisLabel]="true" [showYAxisLabel]="true" [xAxisLabel]="''"
										[yAxisLabel]="''" [animations]="true" [customColors]="personality_colors">
									</ngx-charts-bar-horizontal-stacked>
									<!-- [view]="[getHeightWidthForChart('sub_symptoms_bar_chart_hc').width,getHeightWidthForChart('sub_symptoms_bar_chart_hc').height]" -->
								</div>
								<!-- <div>
									<div *ngFor="let item of subSymptomBarChartColors" class="legend-item">
										<div class="legend-color-box" [ngStyle]="{'background-color':item.value}">&nbsp;
										</div>
										{{item.name}}
									</div>
								</div> -->
							</div>
						</div>
					</div>

					<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 mt-2 mb-2">
						<div class="card">
							<div class="card-header">
								<h4>KEY CLINICAL TRAITS SCREENED</h4>
							</div>
							<div class="card-body scroll p-3">
								<ng-select [items]="clinicalScales" [(ngModel)]="cardsFilter.clinicalScale"
									(change)="getClinicalScales()" [multiple]="true" bindLabel="name" bindValue="id"
									placeholder="Select Clinical Scale" [clearable]="false">
									<ng-template ng-label-tmp let-item="item">
										{{item.name}}<span (click)="deselectByIdClinical(item.id)"
											style="cursor: pointer;"></span>
									</ng-template>
									<ng-template ng-option-tmp let-item="item" let-search="searchTerm"
										let-index="index">
										{{item.name}}
									</ng-template>
								</ng-select>
								<p class="text-center" *ngIf="keyClinicalLoader">
									<fa-icon [icon]="['fas', 'spinner']" animation="spin" size="3x"></fa-icon>
								</p>
								<div *ngIf="!keyClinicalLoader">
									<ngx-charts-bar-horizontal-stacked [results]="clinicalScalesBar" [gradient]="false"
										[xAxis]="true" [yAxis]="true" [legend]="true"
										[legendPosition]="pieChartLegendPosition" [legendTitle]="''"
										[showXAxisLabel]="true" [showYAxisLabel]="true" [xAxisLabel]="''"
										[yAxisLabel]="''" [animations]="true" [customColors]="clinical_colors">
									</ngx-charts-bar-horizontal-stacked>
									<!-- [view]="[getHeightWidthForChart('sub_symptoms_bar_chart_hc').width,getHeightWidthForChart('sub_symptoms_bar_chart_hc').height]" -->
								</div>


							</div>
						</div>
					</div>

					<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 mt-2 mb-2">
						<div class="card">
							<div class="card-header">
								<h4>Overview</h4>
							</div>
							<div class="card-body scroll mar-b-20">
								<div class="row">
									<div class="col-lg-12">
										<div class="ag-format-container">
											<div class="ag-courses_box">
												<div class="ag-courses_item">
													<span href="#" class="ag-courses-item_link">
														<div class="ag-courses-item_bg"></div>
														<div class="ag-courses-item_title">
															@if(overview_countLoader) {
															<fa-icon [icon]="['fas', 'spinner']"
																animation="spin"></fa-icon>
															} @else {
															{{totalNoOfTest}}
															}
														</div>
														<div class="ag-courses-item_date-box">
															<span class="ag-courses-item_date">
																Total Number Of Test
															</span>
														</div>
													</span>
												</div>
											</div>
										</div>

										<div class="ag-format-container">
											<div class="ag-courses_box">
												<div class="ag-courses_item">
													<span href="#" class="ag-courses-item_link">
														<div class="ag-courses-item_bg"></div>
														<div class="ag-courses-item_title">
															@if(overview_countLoader) {
															<fa-icon [icon]="['fas', 'spinner']"
																animation="spin"></fa-icon>
															} @else {
															{{completionTime}}
															}
														</div>
														<div class="ag-courses-item_date-box">
															<span class="ag-courses-item_date">
																Average Time For TestTaken(Minutes)
															</span>
														</div>
													</span>
												</div>
											</div>
										</div>

										<div class="ag-format-container">
											<div class="ag-courses_box">
												<div class="ag-courses_item">
													<span href="#" class="ag-courses-item_link">
														<div class="ag-courses-item_bg"></div>
														<div class="ag-courses-item_title">
															@if(overview_countLoader) {
															<fa-icon [icon]="['fas', 'spinner']"
																animation="spin"></fa-icon>
															} @else {
															{{prejoining}}
															}
														</div>
														<div class="ag-courses-item_date-box">
															<span class="ag-courses-item_date">
																Pre-Joining(%)
															</span>
														</div>
													</span>
												</div>
											</div>
										</div>

										<div class="ag-format-container">
											<div class="ag-courses_box">
												<div class="ag-courses_item">
													<span href="#" class="ag-courses-item_link">
														<div class="ag-courses-item_bg"></div>
														<div class="ag-courses-item_title">
															@if(overview_countLoader) {
															<fa-icon [icon]="['fas', 'spinner']"
																animation="spin"></fa-icon>
															} @else {
															{{prejoiningandpromotion}}
															}
														</div>
														<div class="ag-courses-item_date-box">
															<span class="ag-courses-item_date">
																Prejoining & Promotion Only Promotion (%)
															</span>
														</div>
													</span>
												</div>
											</div>
										</div>

										<div class="ag-format-container">
											<div class="ag-courses_box">
												<div class="ag-courses_item">
													<span href="#" class="ag-courses-item_link">
														<div class="ag-courses-item_bg"></div>
														<div class="ag-courses-item_title">
															@if(overview_countLoader) {
															<fa-icon [icon]="['fas', 'spinner']"
																animation="spin"></fa-icon>
															} @else {
															{{totalNoOfMACITest}}
															}
														</div>
														<div class="ag-courses-item_date-box">
															<span class="ag-courses-item_date">
																Total Number MACI Of Test
															</span>
														</div>
													</span>
												</div>
											</div>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 mt-2 mb-2">
						<div>
							<div class="card">
								<div class="card-header">
									<h4>TEST INVALID(%)</h4>
								</div>
								<div class="card-body scroll">
									<p class="text-center" *ngIf="keyReportStatusLoader">
										<fa-icon [icon]="['fas', 'spinner']" animation="spin" size="3x"></fa-icon>
									</p>
									<div *ngIf="!keyReportStatusLoader">
										<ngx-charts-pie-chart [results]="report_status_pie_chart_data1"
											[view]="[280,300]" [gradient]="false" [legend]="true"
											[legendPosition]="pieChartLegendPosition" [legendTitle]="''"
											[arcWidth]="arc_width" [labels]="false" [doughnut]="true" [labels]="true"
											[customColors]="pieChartColors1">
											<ng-template #tooltipTemplate let-model="model">
												<h4 class="my-0">{{model.name}}</h4>
												<h5 class="my-0">{{model.value}}%</h5>
											</ng-template>
										</ngx-charts-pie-chart>
									</div>
									<div>
										<!-- <div *ngFor="let item of pieChartColors1" class="legend-item">
											<div class="legend-color-box" [ngStyle]="{'background-color':item.value}">&nbsp;
											</div>
											{{item.name}}
										</div> -->
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 mt-2">
						<div>
							<div class="card ">
								<div class="card-header">
									<h4>REPORT STATUS(%)</h4>
								</div>
								<div style="text-align: -webkit-center;" class="card-body scroll">
									<p class="text-center" *ngIf="keyReportStatusLoader">
										<fa-icon [icon]="['fas', 'spinner']" animation="spin" size="3x"></fa-icon>
									</p>
									<div *ngIf="!keyReportStatusLoader">
										<ngx-charts-pie-chart [results]="report_status_pie_chart_data2"
											[view]="[220,220]" [gradient]="false" [legend]="true"
											[legendPosition]="pieChartLegendPosition" [legendTitle]="''"
											[labels]="false" [doughnut]="true" [arcWidth]="arc_width_thin"
											[doughnut]="true" [customColors]="reportPieChartColors2">
											<ng-template #tooltipTemplate let-model="model">
												<h4 class="my-0">{{model.name}}</h4>
												<h5 class="my-0">{{model.value}}%</h5>
											</ng-template>
										</ngx-charts-pie-chart>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 mt-2">

						<div id="report_status">
							<div class="card ">
								<div class="card-header">
									<h4>APPOINTMENT STATUS(%)</h4>
								</div>
								<div style="text-align: -webkit-center;" class="card-body scroll">
									<p class="text-center" *ngIf="keyReportStatusLoader">
										<fa-icon [icon]="['fas', 'spinner']" animation="spin" size="3x"></fa-icon>
									</p>
									<div *ngIf="!keyReportStatusLoader">
										<ngx-charts-pie-chart [results]="report_status_pie_chart_data3"
											[view]="[220,220]" [gradient]="false" [legend]="true"
											[legendPosition]="pieChartLegendPosition" [legendTitle]="''"
											[labels]="false" [doughnut]="true" [arcWidth]="arc_width_thin"
											[customColors]="reportPieChartColors3">
											<ng-template #tooltipTemplate let-model="model">
												<h4 class="my-0">{{model.name}}</h4>
												<h5 class="my-0">{{model.value}}%</h5>
											</ng-template>
										</ngx-charts-pie-chart>
									</div>
									<!-- <div *ngFor="let item of pieChartColors3" class="legend-item">
										<div class="legend-color-box" [ngStyle]="{'background-color':item.value}">&nbsp;
										</div>
										{{item.name}}
									</div> -->
								</div>
							</div>
						</div>
					</div>

					<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 mt-2">
						<div id="report_status">
							<div class="card ">
								<div class="card-header">
									<h4>LANGUAGE OF TEST TAKEN(%)</h4>
								</div>
								<div class="card-body scroll">
									<p class="text-center" *ngIf="keyReportStatusLoader">
										<fa-icon [icon]="['fas', 'spinner']" animation="spin" size="3x"></fa-icon>
									</p>
									<div id="case_closure_pie_chart_hc" *ngIf="!keyReportStatusLoader">
										<ngx-charts-pie-chart [results]="report_status_pie_chart_data4"
											[view]="[320,300]" [gradient]="false" [legend]="true"
											[legendPosition]="pieChartLegendPosition" [legendTitle]="''" [labels]="true"
											[doughnut]="true" [arcWidth]="arc_width"
											[customColors]="reportPieChartColors4">
											<ng-template #tooltipTemplate let-model="model">
												<h4 class="my-0">{{model.name}}</h4>
												<h5 class="my-0">{{model.value}}%</h5>
											</ng-template>
										</ngx-charts-pie-chart>
									</div>
									<!-- <div *ngFor="let item of pieChartColors4" class="legend-item">
										<div class="legend-color-box" [ngStyle]="{'background-color':item.value}">&nbsp;
										</div>
										{{item.name}}
									</div> -->
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
						<div id="report_status">
							<div class="card ">
								<div class="card-header bg-primary">
									<h4 class="text-light">NATIONALITY DISTRIBUTION</h4>
								</div>
								<div class="card-body scroll p-0">
									<p class="text-center" *ngIf="mapLoader">
										<fa-icon [icon]="['fas', 'spinner']" animation="spin" size="3x"></fa-icon>
									</p>
									<div id="world-map" *ngIf="!mapLoader"></div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
						<div id="report_status">
							<div class="card">
								<div class="card-header">
									<h4>APPOINTMENT DISTRIBUTION BY COMPANY</h4>
								</div>
								<div class="card-body scroll">
									<p class="text-center" *ngIf="apptDistributionLoader">
										<fa-icon [icon]="['fas', 'spinner']" animation="spin" size="3x"></fa-icon>
									</p>
									<div id="sub_symptoms_bar_chart_hc" *ngIf="!apptDistributionLoader">
										<ngx-charts-bar-vertical-stacked [results]="appointment_chart_data"
											[gradient]="false" [xAxis]="false" [yAxis]="true" [legend]="false"
											[legendPosition]="pieChartLegendPosition" [legendTitle]="''"
											[showXAxisLabel]="false" [showYAxisLabel]="true" [xAxisLabel]="''"
											[yAxisLabel]="''" [animations]="true"
											[customColors]="appointmentBarChartColors">
										</ngx-charts-bar-vertical-stacked>
										<!-- [view]="[getHeightWidthForChart('sub_symptoms_bar_chart_hc').width,getHeightWidthForChart('sub_symptoms_bar_chart_hc').height]" -->
									</div>
									<div *ngIf="!apptDistributionLoader">
										<div *ngFor="let item of appointmentBarChartColors" class="legend-item">
											<div class="legend-color-box" [ngStyle]="{'background-color':item.value}">
												&nbsp;
											</div>
											{{item.name}}
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>

					<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 mt-2">
						<div id="report_status">
							<div class="card">
								<div class="card-header bg-primary">
									<h4 class="text-light">RANK DISTRIBUTION</h4>
								</div>
								<div class="card-body scroll">
									<p class="text-center" *ngIf="rankDistributionLoader">
										<fa-icon [icon]="['fas', 'spinner']" animation="spin" size="3x"></fa-icon>
									</p>
									<div *ngIf="!rankDistributionLoader">
										<ngx-charts-pie-chart [results]="rankChart_data" [view]="[320,250]"
											[gradient]="false" [legend]="true" [legendPosition]="pieChartLegendPosition"
											[legendTitle]="''" [labels]="true" [doughnut]="true" [arcWidth]="arc_width"
											[doughnut]="true" [customColors]="rankPieChartColors">
											<ng-template #tooltipTemplate let-model="model">
												<h4 class="my-0">{{model.name}}</h4>
												<h5 class="my-0">{{model.value}}%</h5>
											</ng-template>
										</ngx-charts-pie-chart>
									</div>
									<!-- <div *ngFor="let item of rankPieChartColors" class="legend-item">
											<div class="legend-color-box" [ngStyle]="{'background-color':item.value}">&nbsp;
											</div>
											{{item.name}}
										</div> -->
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-9 col-md-9 col-sm-12 col-xs-12 mt-2">
						<div class="card">
							<div class="card-header">
								<h4>RANK DISTRIBUTION OVERVIEW</h4>
							</div>
							<div class="card-body scroll">
								<div class="col-lg-4">
									<ng-select id="rank" appendTo="body" [items]="rankList_dropdown" bindLabel="name"
										bindValue="id" [multiple]="false" [(ngModel)]="cardsFilter.rank"
										(change)="rank_distribution_pie_chart_data();" [virtualScroll]="true"
										placeholder="Select Rank Categories">
									</ng-select>
								</div>
								<div class="row">
									<div class="col-lg-4">
										<div>
											<h4>RANK - PERSONALITY DISTRIBUTION Distribution of Scales > 75</h4>
										</div>
										<p class="text-center" *ngIf="rank_distribution_pie_chart_loader">
											<fa-icon [icon]="['fas', 'spinner']" animation="spin" size="3x"></fa-icon>
										</p>
										<div *ngIf="!rank_distribution_pie_chart_loader">
											<ngx-charts-pie-chart [results]="rank_distribution_pie_chart1"
												[view]="[340,250]" [gradient]="false" [legend]="true"
												[legendPosition]="pieChartLegendPosition" [legendTitle]="''"
												[labels]="true" [doughnut]="true" [arcWidth]="arc_width"
												[doughnut]="true" [customColors]="rankPieChartColors1">
												<ng-template #tooltipTemplate let-model="model">
													<h4 class="my-0">{{model.name}}</h4>
													<h5 class="my-0">{{model.value}}%</h5>
												</ng-template>
											</ngx-charts-pie-chart>
										</div>
										<!-- <div *ngFor="let item of rankPieChartColors1" class="legend-item">
													<div class="legend-color-box" [ngStyle]="{'background-color':item.value}">&nbsp;
													</div>
													{{item.name}}
												</div> -->
									</div>
									<div class="col-lg-4">
										<div>
											<h4>RANK - CLINICAL DISTRIBUTION
												Distribution of Scales > 75</h4>
										</div>
										<p class="text-center" *ngIf="rank_distribution_pie_chart_loader">
											<fa-icon [icon]="['fas', 'spinner']" animation="spin" size="3x"></fa-icon>
										</p>
										<div *ngIf="!rank_distribution_pie_chart_loader">
											<ngx-charts-pie-chart [results]="rank_distribution_pie_chart2"
												[view]="[340,250]" [gradient]="false" [legend]="true"
												[legendPosition]="pieChartLegendPosition" [legendTitle]="''"
												[labels]="true" [doughnut]="true" [arcWidth]="arc_width"
												[doughnut]="true" [customColors]="rankPieChartColors2">
												<ng-template #tooltipTemplate let-model="model">
													<h4 class="my-0">{{model.name}}</h4>
													<h5 class="my-0">{{model.value}}%</h5>
												</ng-template>
											</ngx-charts-pie-chart>
										</div>
										<!-- <div *ngFor="let item of rankPieChartColors2" class="legend-item">
													<div class="legend-color-box" [ngStyle]="{'background-color':item.value}">&nbsp;
													</div>
													{{item.name}}
												</div> -->
									</div>
									<div class="col-lg-4">
										<div>
											<h4>RANK BY AGE DISTRIBUTION</h4><br />
										</div>
										<p class="text-center" *ngIf="rank_distribution_pie_chart_loader">
											<fa-icon [icon]="['fas', 'spinner']" animation="spin" size="3x"></fa-icon>
										</p>
										<div *ngIf="!rank_distribution_pie_chart_loader">
											<ngx-charts-pie-chart [results]="rank_distribution_pie_chart3"
												[view]="[340,250]" [gradient]="false" [legend]="true"
												[legendPosition]="pieChartLegendPosition" [legendTitle]="''"
												[labels]="true" [doughnut]="true" [arcWidth]="arc_width"
												[doughnut]="true" [customColors]="rankPieChartColors3">
												<ng-template #tooltipTemplate let-model="model">
													<h4 class="my-0">{{model.name}}</h4>
													<h5 class="my-0">{{model.value}}%</h5>
												</ng-template>
											</ngx-charts-pie-chart>
										</div>
										<!-- <div *ngFor="let item of rankPieChartColors3" class="legend-item">
													<div class="legend-color-box" [ngStyle]="{'background-color':item.value}">&nbsp;
													</div>
													{{item.name}}
												</div> -->
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<p class="text-center" *ngIf="feedbackLoader">
						<fa-icon [icon]="['fas', 'spinner']" animation="spin" size="3x"></fa-icon>
					</p>
					<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 mt-2 mb-2"
						*ngFor="let feedbackData of feedBackDataAll;let i = index;">
						<div class="card scroll"><br>
							<h4 class="text-center mt-3">Q{{i+1}}. {{feedbackData[0].name}}
							</h4>
							<div>
								<ngx-charts-bar-horizontal-stacked [results]="feedbackData" [gradient]="false"
									[xAxis]="true" [yAxis]="false" [legend]="true"
									[legendPosition]="pieChartLegendPosition" [legendTitle]="''" [showXAxisLabel]="true"
									[showYAxisLabel]="true" [xAxisLabel]="''" [yAxisLabel]="''" [animations]="true"
									[customColors]="feedColor">
								</ngx-charts-bar-horizontal-stacked>
							</div>
						</div>
					</div>
					<!-- <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 mt-2 mb-2">
						<div class="card scroll"><br>
							<h4 class="text-center mt-3">Q1. The awareness video provided was informative and relevant?
							</h4>
							<p class="text-center" *ngIf="feedbackLoader">
								<fa-icon [icon]="['fas', 'spinner']" animation="spin" size="3x"></fa-icon>
							</p>
							<div *ngIf="!feedbackLoader">
								<ngx-charts-bar-horizontal-stacked [results]="feedbackbar1" [gradient]="false"
									[xAxis]="true" [yAxis]="false" [legend]="true"
									[legendPosition]="pieChartLegendPosition" [legendTitle]="''" [showXAxisLabel]="true"
									[showYAxisLabel]="true" [xAxisLabel]="''" [yAxisLabel]="''" [animations]="true"
									[customColors]="feedColors1">
								</ngx-charts-bar-horizontal-stacked>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 mt-2 mb-2">
						<div class="card scroll"><br>
							<h4 class="text-center mt-3">
								Q2. The statements/ questions in the assessment were simple to understand?
							</h4>
							<p class="text-center" *ngIf="feedbackLoader">
								<fa-icon [icon]="['fas', 'spinner']" animation="spin" size="3x"></fa-icon>
							</p>
							<div *ngIf="!feedbackLoader">
								<ngx-charts-bar-horizontal-stacked [results]="feedbackbar2" [gradient]="false"
									[xAxis]="true" [yAxis]="false" [legend]="true"
									[legendPosition]="pieChartLegendPosition" [legendTitle]="''" [showXAxisLabel]="true"
									[showYAxisLabel]="false" [xAxisLabel]="''" [yAxisLabel]="''" [animations]="true"
									[customColors]="feedColors2">
								</ngx-charts-bar-horizontal-stacked>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 mt-2 mb-2">
						<div class="card scroll"><br>
							<h4 class="text-center mt-3"> Q3. The instructions for the assessment and interaction with
								the specialist were helpful?</h4>
							<p class="text-center" *ngIf="feedbackLoader">
								<fa-icon [icon]="['fas', 'spinner']" animation="spin" size="3x"></fa-icon>
							</p>
							<div *ngIf="!feedbackLoader">
								<ngx-charts-bar-horizontal-stacked [results]="feedbackbar3" [gradient]="false"
									[xAxis]="true" [yAxis]="false" [legend]="true"
									[legendPosition]="pieChartLegendPosition" [legendTitle]="''" [showXAxisLabel]="true"
									[showYAxisLabel]="false" [xAxisLabel]="''" [yAxisLabel]="''" [animations]="true"
									[customColors]="feedColors3">
								</ngx-charts-bar-horizontal-stacked>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 mt-2 mb-2">
						<div class="card scroll">
							<br>
							<h4 class="text-center mt-3">
								Q4. The interaction and process of connecting for the psychometric assessment was simple
								and convenient?
							</h4>
							<p class="text-center" *ngIf="feedbackLoader">
								<fa-icon [icon]="['fas', 'spinner']" animation="spin" size="3x"></fa-icon>
							</p>
							<div *ngIf="!feedbackLoader">
								<ngx-charts-bar-horizontal-stacked [results]="feedbackbar4" [gradient]="false"
									[xAxis]="true" [yAxis]="false" [legend]="true"
									[legendPosition]="pieChartLegendPosition" [legendTitle]="''" [showXAxisLabel]="true"
									[showYAxisLabel]="false" [xAxisLabel]="''" [yAxisLabel]="''" [animations]="true"
									[customColors]="feedColors4">
								</ngx-charts-bar-horizontal-stacked>
							</div>
						</div>
					</div> -->
				</div>
			</ng-container>
		</div>
	</div>
</ng-template>
@if (user_type=='cdm') {
@if(UserModuleAccess!='Full'){
<h3 class="text-center">You don't have access to this section.</h3>
}
} @else if(user_type=='company') {
@if(UserModuleAccess!='Full' || isCompanyIdNull){
<h3 class="text-center">Service not subscribed.</h3>
}
} @else if(user_type=='fleet') {
<h3 class="text-center">Service not subscribed.</h3>
}