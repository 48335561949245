<breadcrumb [breadcrumbList]="breadcrumbList"></breadcrumb>
<div *ngIf="accessLoader" class="loader text-center">
	<img src="../assets/img/cc_loader.gif" />
</div>
<div class="card" *ngIf="UserModuleAccess=='Full' && user_type=='cdm'">
	<div class="card-header bg-primary">
		{{pagetitle}}
	</div>
	<div class="card-body">
		<form class="form-horizontal" [formGroup]="moduleForm" (ngSubmit)="saveData()" #modform="ngForm">
			<div class="row mb-3">
				<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
					<div class="form-floating">
						<input class="form-control" [ngClass]="{'is-invalid':showError('FirstName')}" id="FirstName"
							formControlName="FirstName" placeholder="First Name*">
						<label for="FirstName">First Name*</label>
					</div>
					<p *ngIf="showError('FirstName')" class="text-danger">
						<span *ngIf="f.FirstName.errors?.required">
							Required
						</span>
						<span *ngIf="f.FirstName.errors?.incorrect">
							{{customError.FirstName}}
						</span>
					</p>
				</div>
				<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
					<div class="form-floating">
						<input class="form-control" [ngClass]="{'is-invalid':showError('LastName')}" id="LastName"
							formControlName="LastName" placeholder="Last Name*">
						<label for="LastName">Last Name*</label>
					</div>
					<p *ngIf="showError('LastName')" class="text-danger">
						<span *ngIf="f.LastName.errors?.required">
							Required
						</span>
						<span *ngIf="f.LastName.errors?.incorrect">
							{{customError.LastName}}
						</span>
					</p>
				</div>
				<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
					<div class="form-floating">
						<input class="form-control" [ngClass]="{'is-invalid':showError('Email')}" id="Email"
							formControlName="Email" placeholder="Email Id*">
						<label for="Email">Email Id*</label>
					</div>
					<p *ngIf="showError('Email')" class="text-danger">
						<span *ngIf="f.Email.errors?.required">
							Required
						</span>
						<span *ngIf="f.Email.errors?.pattern">
							Invalid
						</span>
						<span *ngIf="f.Email.errors?.incorrect">
							{{customError.Email}}
						</span>
					</p>
				</div>
				<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
					<label>Two Factor Auth.:</label>
					<br />
					<ng-toggle formControlName="enable2fa" [color]="{ unchecked: '#bfcbd9', checked: '#2494f2' }"
						[fontColor]="{checked: '#ffffff',unchecked: '#000000'}"
						[switchColor]="{checked: '#198754',unchecked: '#dc3545'}"
						[labels]="{'unchecked': 'Disable','checked': 'Enable'}" [width]="90"></ng-toggle>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
					<label>User Type:</label>
					<br />
					<mat-radio-group aria-label="Select an option" (change)="userTypeChanged()"
						formControlName="user_type">
						<mat-radio-button [name]="'user_type'" ngDefaultControl [value]="'cdm'" [color]="'primary'">
							CDM
						</mat-radio-button>
						<mat-radio-button [name]="'user_type'" ngDefaultControl [value]="'company'" [color]="'primary'">
							Company
						</mat-radio-button>
						<mat-radio-button [name]="'user_type'" ngDefaultControl [value]="'fleet'" [color]="'primary'">
							Fleet
						</mat-radio-button>
					</mat-radio-group>
				</div>
				<div class="col-lg-4 col-md-4 col-sm-6 col-xs-6 mt-1"
					*ngIf="f.user_type.value == 'company' || f.user_type.value == 'fleet'">
					<ng-select appearance="outline" appendTo="body" [items]="companyList" bindLabel="name"
						[clearable]="true" [multiple]="false" id="company_id" formControlName="company_id"
						placeholder="Company" (change)="getFleetList(true);">
					</ng-select>
					<p *ngIf="showError('company_id')" class="text-danger">
						<span *ngIf="f.company_id.errors?.required">
							Required
						</span>
						<span *ngIf="f.company_id.errors?.incorrect">
							{{customError.company_id}}
						</span>
					</p>
				</div>
				<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12" *ngIf="f.user_type.value == 'fleet'">
					<ng-select appearance="outline" appendTo="body" [items]="fleetList" bindLabel="name"
						[clearable]="true" [multiple]="false" id="fleet_id" formControlName="fleet_id"
						placeholder="Fleet">
					</ng-select>
					<p *ngIf="showError('fleet_id')" class="text-danger">
						<span *ngIf="f.fleet_id.errors?.required">
							Required
						</span>
						<span *ngIf="f.fleet_id.errors?.incorrect">
							{{customError.fleet_id}}
						</span>
					</p>
				</div>
			</div>
			<div class="row mb-3" *ngIf="!uId">
				<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
					<div class="row">
						<div class="col-lg-11 col-md-11 col-sm-11 col-xs-11 pe-0">
							<div class="form-floating">
								<input class="form-control" [type]="hideP ? 'password' : 'text'"
									[ngClass]="{'is-invalid':showError('Password')}" id="Password"
									formControlName="Password" placeholder="Password*">
								<label for="Password">Password*</label>
							</div>
						</div>
						<div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 ps-0">
							<button type="button" (click)="hideP = !hideP" class="btn btn-sm btn-outline-dark h-100">
								<fa-icon *ngIf="hideP" [icon]="['fas', 'eye']" style="font-size: medium;"></fa-icon>
								<fa-icon *ngIf="!hideP" [icon]="['fas', 'eye-slash']"
									style="font-size: medium;"></fa-icon>
							</button>
						</div>
					</div>
					<p *ngIf="showError('Password')" class="text-danger">
						<span *ngIf="f.Password.errors?.required">
							Required
						</span>
						<span *ngIf="f.Password.errors?.incorrect">
							{{customError.Password}}
						</span>
					</p>
				</div>
				<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
					<div class="form-floating">
						<input class="form-control" type="password"
							[ngClass]="{'is-invalid':showError('confirmPassword')}" id="confirmPassword"
							formControlName="confirmPassword" placeholder="Confirm Password*">
						<label for="confirmPassword">Confirm Password*</label>
					</div>
					<p *ngIf="showError('confirmPassword')" class="text-danger">
						<span *ngIf="f.confirmPassword.errors?.required">
							Required
						</span>
						<span *ngIf="f.confirmPassword.errors?.incorrect">
							{{customError.confirmPassword}}
						</span>
					</p>
					<p *ngIf="moduleForm.errors && (modform.submitted || f.confirmPassword.touched)"
						class="text-danger">
						<span *ngIf="moduleForm.errors?.passwordNotMatch">
							Password and Confirm Password not matched!
							<fa-icon [icon]="['fas', 'times']"></fa-icon>
						</span>
					</p>
				</div>
				<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
					<div *ngIf="showError('Password')">
						<p [ngClass]="f.Password.errors?.minlengtherror?'text-danger':'text-success'">
							Must be atleast 8 characters long.
						</p>
						<p [ngClass]="f.Password.errors?.specialcharerror?'text-danger':'text-success'">
							Must have atleast 1 special character.
						</p>
						<p [ngClass]="f.Password.errors?.uppercaseerror?'text-danger':'text-success'">
							Must have atleast 1 upper case character.
						</p>
						<p [ngClass]="f.Password.errors?.lowercaseerror?'text-danger':'text-success'">
							Must have atleast 1 lower case character.
						</p>
						<p [ngClass]="f.Password.errors?.digiterror?'text-danger':'text-success'">
							Must have atleast 1 digit.
						</p>
					</div>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
					<table class="table table-bordered" style="width: 100%;">
						<tbody>
							<ng-container *ngFor="let access of allPageAccess;let i = index;">
								<tr>
									<td style="width: 50% !important;">
										<div class="row">
											<div class="col">
												<label style="padding-top: 9px;">
													{{access.mod_name}}
												</label>
											</div>
											<div class="col">
												<!-- <mat-button-toggle-group [vertical]="false" [name]="access.key_name"
													[value]="access.value" [appearance]="'legacy'"
													(change)="radioChanged1($event,i);formChanged();">
													<mat-button-toggle ngDefaultControl [value]="'Full'">
														<fa-icon [icon]="['fas', 'check']"
															class="text-success"></fa-icon>
														Full Access
													</mat-button-toggle>
													<mat-button-toggle ngDefaultControl [value]="'NoAccess'">
														<fa-icon [icon]="['fas', 'times']"
															class="text-danger"></fa-icon>
														No Access
													</mat-button-toggle>
												</mat-button-toggle-group> -->
												<mat-radio-group aria-label="Select an option"
													[(ngModel)]="access.value" [ngModelOptions]="{standalone: true}"
													(ngModelChange)="radioChanged1Old(access.value,i);formChanged();">
													<mat-radio-button [name]="access.key_name" ngDefaultControl
														[value]="'Full'" [color]="'primary'">
														Full Access
													</mat-radio-button>
													<mat-radio-button [name]="access.key_name" ngDefaultControl
														[value]="'NoAccess'" [color]="'primary'">
														No Access
													</mat-radio-button>
												</mat-radio-group>
											</div>
										</div>
									</td>
									<td style="width: 50% !important;">
										<div class="row mb-1" *ngFor="let subAcc of access.sub_list;let j = index;">
											<div class="col">
												<label style="padding-top: 9px;">
													{{subAcc.mod_name}}
												</label>
											</div>
											<div class="col">
												<!-- <mat-button-toggle-group [vertical]="false" [name]="subAcc.key_name"
													[value]="subAcc.value" [appearance]="'legacy'"
													(change)="radioChanged2($event,i,j);formChanged();">
													<mat-button-toggle ngDefaultControl [value]="'Full'">
														<fa-icon [icon]="['fas', 'check']"
															class="text-success"></fa-icon>
														Full Access
													</mat-button-toggle>
													<mat-button-toggle ngDefaultControl [value]="'NoAccess'">
														<fa-icon [icon]="['fas', 'times']"
															class="text-danger"></fa-icon>
														No Access
													</mat-button-toggle>
												</mat-button-toggle-group> -->
												<mat-radio-group aria-label="Select an option"
													[(ngModel)]="subAcc.value" [ngModelOptions]="{standalone: true}"
													(ngModelChange)="formChanged();">
													<mat-radio-button [name]="subAcc.key_name" ngDefaultControl
														[value]="'Full'" [color]="'primary'">
														Full Access
													</mat-radio-button>
													<mat-radio-button [name]="subAcc.key_name" ngDefaultControl
														[value]="'NoAccess'" [color]="'primary'">
														No Access
													</mat-radio-button>
												</mat-radio-group>
											</div>
										</div>
									</td>
								</tr>
							</ng-container>
						</tbody>
					</table>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
					<button type="submit" [disabled]="showLoader || !modform.dirty"
						class="btn btn-sm btn-outline-success">
						<span class="me-1">
							<fa-icon *ngIf="showLoader" [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
							<fa-icon *ngIf="!showLoader" [icon]="['fas', 'save']"></fa-icon>
						</span>
						<span>Save</span>
					</button>
					<a type="button" class="btn btn-sm btn-outline-dark ms-1" [routerLink]="['/app/users']">Cancel</a>
				</div>
			</div>
		</form>
	</div>
</div>
<div *ngIf="UserModuleAccess!='Full' || user_type!='cdm'">
	<h3 class="text-center">You don't have access to this section.</h3>
</div>