import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { ApiService, HttpParamsOptions } from '../../api.service';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Config } from 'datatables.net';
import Swal from 'sweetalert2';
import { password_validation } from '../../shared/constants';

class DataTablesResponse {
	data!: any[];
	draw!: number;
	recordsFiltered!: number;
	recordsTotal!: number;
}

@Component({
	selector: 'app-vessel-type-list',
	templateUrl: './vessel-type-list.component.html',
	styleUrl: './vessel-type-list.component.css'
})
export class VesselTypeListComponent implements OnInit, OnDestroy, AfterViewInit {
	breadcrumbList: any[] = [
		{
			path: this.apiService.getHomeURL(),
			name: "Home"
		},
		/* {
			path: '/app/dashboard-mdm',
			name: "MDM"
		}, */
		{
			path: "/app/vessel-type",
			name: "Vessel Type"
		}
	];

	@ViewChild(DataTableDirective, { static: false }) datatableElement!: DataTableDirective;
	baseURL = environment.API_URL + "mdm/";
	dtOptions: Config = {};
	dataList: any = [];
	showDeleteLoader: any = null;
	pagetitle = "Vessel Type Master";
	pageLoading = false;
	allFlagStates: any[] = [];
	flagState: any = null;
	UserModuleAccess: any = "Full";
	passwordId: any;
	resetPasswordForm!: FormGroup;
	showReset2FALoader: any = null;
	updateInventoryLoader = false;

	constructor(private formBuilder: FormBuilder, private apiService: ApiService, private activeRoute: ActivatedRoute, private toastr: ToastrService, private http: HttpClient) {
		/* this.apiService.getUserAccess().subscribe((data: any) => {
			try {
				let accessObj: any = JSON.parse(data.res[0].UserModuleAccess);
				this.UserModuleAccess = accessObj["USER_Module"];
			} catch (error) { }
		}, (error: any) => {
			this.UserModuleAccess = "NoAccess";
		}); */
	}

	ngOnInit(): void {
		this.activeRoute.params.subscribe(routeParams => {
			this.initDatatable();
			this.resetPasswordForm = this.formBuilder.group({
				password: ['', [Validators.required, password_validation]],
				confirmPassword: ['', [Validators.required]],
			});
		});
	}

	ngOnDestroy(): void {

	}

	ngAfterViewInit(): void {

	}

	initDatatable() {
		this.dtOptions = {
			pagingType: 'full_numbers',
			serverSide: true,
			processing: true,
			ordering: false,
			ajax: (dataTablesParameters: any, callback) => {
				let data: any = {
					draw: dataTablesParameters.draw,
					start: dataTablesParameters.start,
					length: dataTablesParameters.length,
					searchVal: dataTablesParameters.search.value
				}
				const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
				this.http.get<DataTablesResponse>(this.baseURL + "mdm_vesselType", { params: new HttpParams(httpParams) }).subscribe((response: any) => {
					this.dataList = response.data;
					callback({
						recordsTotal: response.recordsTotal,
						recordsFiltered: response.recordsFiltered,
						data: []
					});
				}, (error: any) => {
					if (error.status == 401) { return }
					this.toastr.error("Something went wrong.", 'Error!!!', {
						timeOut: 3000,
						tapToDismiss: true,
						closeButton: true
					});
				});
			}
		}
	}

	deleteConfirm(uId: any, index: number) {
		Swal.fire({
			title: 'Warning !!!',
			html: 'Are you sure you want to delete record?',
			icon: 'warning',
			showCancelButton: true,
			showConfirmButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#dc3545'
		}).then((result) => {
			if (result.value) {
				this.showDeleteLoader = index;
				this.apiService.deleteVesselType({ id: uId }).subscribe(data => {
					this.showDeleteLoader = null;
					Swal.fire('Success', 'Deleted successfully.', 'success');
					this.refreshTable();
				}, (error) => {
					this.showDeleteLoader = null;
					if (error.status == 401) { return }
					if (error.status == 404) {
						Swal.fire('Error', error.error, 'error');
					} else {
						Swal.fire('Error', 'Something went wrong.', 'error');
					}
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}

	refreshTable() {
		this.datatableElement.dtInstance.then((dtInstance) => {
			dtInstance.draw();
		});
	}

	get f() { return this.resetPasswordForm.controls; }
}
