<breadcrumb [breadcrumbList]="breadcrumbList"></breadcrumb>
<div *ngIf="accessLoader" class="loader text-center">
	<img src="../assets/img/cc_loader.gif" />
</div>
<div class="card" *ngIf="UserModuleAccess=='Full' && user_type=='cdm'">
	<div class="card-body">
		<h2 class="card-title">
			Users
			<a class="btn btn-sm btn-outline-success float-end" [routerLink]="['/app/create-user']">
				<fa-icon [icon]="['fas', 'plus']"></fa-icon>
				Create User
			</a>
		</h2>
		<div class="row">
			<div class="col-md-12">
				<div class="table-responsive">
					<table datatable [dtOptions]="dtOptions" class="table table-striped table-bordered table-hover"
						style="width: 100%;">
						<thead>
							<tr class="table_head">
								<th style="width: 25% !important;">First Name</th>
								<th style="width: 25% !important;">Last Name</th>
								<th style="width: 25% !important;">Email</th>
								<th style="width: 10% !important;">User Type</th>
								<th style="width: 15% !important;">Action</th>
							</tr>
						</thead>
						<tbody *ngIf="dataList?.length != 0">
							<tr *ngFor="let v of dataList;let i = index;">
								<td>{{ v.FirstName }}</td>
								<td>{{ v.LastName }}</td>
								<td>{{ v.Email }}</td>
								<td>
									<ng-container *ngIf="v.user_type=='company' || user_type=='fleet'">
										Company
									</ng-container>
									<ng-container *ngIf="v.user_type=='fleet'">
										Fleet
									</ng-container>
									<ng-container *ngIf="v.user_type=='cdm'">
										CDM
									</ng-container>
								</td>
								<td>
									<a class="btn btn-sm btn-outline-primary" matTooltip="Edit" id="btnEdit{{v.id}}"
										[routerLink]="['/app/edit-user',v.id]">
										<fa-icon [icon]="['fas', 'pencil-alt']"></fa-icon>
									</a>
									<button class="btn btn-sm btn-outline-danger ms-1" matTooltip="Delete"
										id="btnDelete{{v.id}}" (click)="deleteConfirm(v.id,i)">
										<fa-icon *ngIf="showDeleteLoader != i" [icon]="['fas', 'times']"></fa-icon>
										<fa-icon *ngIf="showDeleteLoader == i" [icon]="['fas', 'spinner']"
											animation="spin"></fa-icon>
									</button>
									<button class="btn btn-sm btn-outline-info ms-1" matTooltip="Change Password"
										id="btnResetPass{{v.id}}" (click)="resetPasswordModal(v.id)">
										<fa-icon [icon]="['fas', 'lock']"></fa-icon>
									</button>
									<button *ngIf="v.enable2fa" class="btn btn-sm btn-outline-success ms-1"
										matTooltip="Reset 2 Factor Authentication" id="btnReset2FA{{v.id}}"
										(click)="reset2FA(v.id)">
										<fa-icon *ngIf="showReset2FALoader != v.id" [icon]="['fas', 'key']"></fa-icon>
										<fa-icon *ngIf="showReset2FALoader == v.id" [icon]="['fas', 'spinner']"
											animation="spin"></fa-icon>
									</button>
								</td>
							</tr>
						</tbody>
						<tbody *ngIf="dataList?.length == 0">
							<tr>
								<td colspan="5" class="text-center">No data found!</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</div>
<div *ngIf="UserModuleAccess!='Full' || user_type!='cdm'">
	<h3 class="text-center">You don't have access to this section.</h3>
</div>
<div class="modal fade" id="changeUserPasswordModal" role="dialog">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<form class="form-horizontal" [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()"
				#passform="ngForm">
				<div class="modal-header">
					<h4 class="modal-title" id="modal-basic-title">Change Password</h4>
					<button type="button" class="btn-close" aria-label="Close" (click)="clearResetPassword()"></button>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-sm-4">
							<mat-form-field>
								<mat-label>New Password</mat-label>
								<input type="password" matInput id="password" formControlName="password"
									name="password">
								<mat-error
									*ngIf="f.password.invalid && (passform.submitted || f.password.touched || f.password.dirty)">
									<span *ngIf="f.password.errors?.required">
										Required
									</span>
								</mat-error>
							</mat-form-field>
						</div>
						<div class="col-sm-4">
							<mat-form-field>
								<mat-label>Confirm Password</mat-label>
								<input type="password" matInput id="confirmPassword" formControlName="confirmPassword"
									name="confirmPassword">
								<mat-error
									*ngIf="f.confirmPassword.invalid && (passform.submitted || f.confirmPassword.touched || f.confirmPassword.dirty)">
									<span *ngIf="f.confirmPassword.errors?.required">
										Required
									</span>
								</mat-error>
							</mat-form-field>
							<mat-error
								*ngIf="resetPasswordForm.errors && (passform.submitted || f.confirmPassword.touched)">
								<p *ngIf="resetPasswordForm.errors?.passwordNotMatch">
									Password and Confirm Password not matched!
									<fa-icon [icon]="['fas', 'times']"></fa-icon>
								</p>
							</mat-error>
						</div>
						<div class="col-sm-4">
							<div *ngIf="f.password.invalid && (passform.submitted || f.password.touched)" type="danger">
								<p [ngClass]="f.password.errors?.minlengtherror?'text-danger':'text-success'">
									Must be atleast 8 characters long.
								</p>
								<p [ngClass]="f.password.errors?.specialcharerror?'text-danger':'text-success'">
									Must have atleast 1 special character.
								</p>
								<p [ngClass]="f.password.errors?.uppercaseerror?'text-danger':'text-success'">
									Must have atleast 1 upper case character.
								</p>
								<p [ngClass]="f.password.errors?.lowercaseerror?'text-danger':'text-success'">
									Must have atleast 1 lower case character.
								</p>
								<p [ngClass]="f.password.errors?.digiterror?'text-danger':'text-success'">
									Must have atleast 1 digit.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="submit" class="btn btn-outline-primary btn-sm">Reset</button>
					<button type="button" class="btn btn-outline-warning btn-sm" data-dismiss="modal"
						(click)="clearResetPassword();">Cancel
					</button>
				</div>
			</form>
		</div>
	</div>
</div>