import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';
import { ApiService } from '../api.service';
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrl: './login.component.css'
})
export class LoginComponent implements OnInit, AfterViewInit {
	user: any = { username: '', password: '' };
	year: number = new Date().getFullYear();
	ScreenHeight: number = window.innerHeight;
	loadershow: boolean = false;
	loginErrorshow: boolean = false;
	loginFormErr: string = "";
	adminAccess: any;
	data: any = null;
	step: number = 1;
	showQR = false;
	qrToShow: any;
	auth: any = { otp: null };
	qrStep = 1;

	constructor(private apiService: ApiService, private router: Router, private renderer: Renderer2, private toastr: ToastrService, private route: ActivatedRoute,) { }

	ngAfterViewInit(): void { }

	ngOnInit(): void {
		if (localStorage.getItem('cdm_token') != null && localStorage.getItem('cdm_token') != undefined) {
			this.gotoDashboard();
		}
		if (localStorage.getItem('sessionexpire') == "true") {
			this.loginErrorshow = true;
			this.loginFormErr = "Your session has been expired.";
			setTimeout(() => {
				this.loginErrorshow = false;
				localStorage.removeItem('sessionexpire')
			}, 5000);
		}
		if(this.route.snapshot.queryParams['returnUrl'] && this.route.snapshot.queryParams['returnUrl'].indexOf("login")>=0){
			this.router.navigate(["/login"]);
		}
	}

	submitForm(user: any) {
		this.loadershow = true;
		this.apiService.loginAPI(user).subscribe((data: any) => {
			this.loadershow = false;
			this.data = data;
			if (this.data.enable2fa && this.data.first_login) {
				this.step = 2;
				this.showQR = true;
				this.generateQR();
			} else if (this.data.enable2fa && !this.data.first_login) {
				this.step = 2;
				this.showQR = false;
				this.qrStep = 2;
			} else {
				this.proceedLogin();
			}
		}, (error: any) => {
			this.loadershow = false;
			this.loginErrorshow = true;
			this.loginFormErr = "Username Or Password is incorrect.";
			setTimeout(() => {
				this.loginErrorshow = false;
			}, 5000);
		}
		);
	}

	gotoDashboard() {
		const returnUrl = this.route.snapshot.queryParams['returnUrl'] || this.apiService.getHomeURL();
		this.router.navigateByUrl(returnUrl);
		/* this.router.navigate([this.apiService.getHomeURL()]); */
	}

	next() {
		this.qrStep = 2;
	}

	generateQR() {
		this.loadershow = true;
		let data: any = {
			id: this.data.main_user,
			user: this.data.emailId
		};
		this.apiService.generate_qr(data).subscribe((data: any) => {
			this.loadershow = false;
			this.createImageFromBlob(data);
		}, (error: any) => {
			this.loadershow = false;
			this.loginErrorshow = true;
			this.loginFormErr = "Something went wrong";
			setTimeout(() => {
				this.loginErrorshow = false;
			}, 5000);
		});
	}

	createImageFromBlob(image: Blob) {
		let reader = new FileReader();
		reader.addEventListener("load", () => {
			this.qrToShow = reader.result;
		}, false);
		if (image) {
			reader.readAsDataURL(image);
		}
	}

	verifyOTP() {
		if (!this.auth.otp) {
			this.toastr.warning("Please enter OTP", 'Error', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
			return;
		}
		this.loadershow = true;
		let data: any = {
			id: this.data.main_user,
			otp: this.auth.otp
		};
		this.apiService.verifyGoogleAuthOTP(data).subscribe((data: any) => {
			this.loadershow = false;
			this.proceedLogin();
		}, (error: any) => {
			this.loadershow = false;
			this.loginErrorshow = true;
			this.loginFormErr = "Invalid OTP";
			setTimeout(() => {
				this.loginErrorshow = false;
			}, 5000);
		});
	}

	proceedLogin() {
		localStorage.setItem('cdm_token', "Bearer " + this.data["token"]);
		localStorage.setItem('main_user', this.data["main_user"]);
		/* localStorage.setItem('emailId', this.data["emailId"]);
		localStorage.setItem('first_name', this.data["first_name"]);
		localStorage.setItem('last_name', this.data["last_name"] ? this.data["last_name"] : "");
		localStorage.setItem('username', this.data["username"]);
		localStorage.setItem('user_type', this.data["user_type"]); */
		this.toastr.success("Logged in successfully", 'Success', {
			tapToDismiss: true,
			closeButton: true,
			timeOut: 3000
		});
		this.gotoDashboard();
	}
}
