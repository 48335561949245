<breadcrumb [breadcrumbList]="breadcrumbList"></breadcrumb>
<div *ngIf="accessLoader" class="loader text-center">
	<img src="../assets/img/cc_loader.gif" />
</div>
<div class="card" *ngIf="UserModuleAccess=='Full' && user_type=='cdm'">
	<div class="card-body">
		<h2 class="card-title mb-0 pb-0">
			{{pagetitle}}
		</h2>
		<div class="row">
			<div class="col-md-2 pt-3">
				<h4>
					<p class="mb-0">Total INR:<strong>{{totalINR || "-"}}</strong></p>
					<p class="mb-0">Total USD:<strong>{{totalUSD || "-"}}</strong></p>
				</h4>
			</div>
			<div class="col-md-3">
				<mat-form-field appearance="outline">
					<mat-label>From</mat-label>
					<input matInput [matDatepicker]="start_date_picker" [(ngModel)]="filters.start_date"
						(change)="refreshTable()">
					<mat-datepicker-toggle matIconSuffix [for]="start_date_picker"></mat-datepicker-toggle>
					<mat-datepicker #start_date_picker (closed)="refreshTable()"></mat-datepicker>
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>To</mat-label>
					<input matInput [matDatepicker]="end_date_picker" [(ngModel)]="filters.end_date"
						(change)="refreshTable()">
					<mat-datepicker-toggle matIconSuffix [for]="end_date_picker"></mat-datepicker-toggle>
					<mat-datepicker #end_date_picker (closed)="refreshTable()"></mat-datepicker>
				</mat-form-field>
			</div>
			<div class="col-md-2">
				<ng-select appendTo="body" [items]="servicesList" bindLabel="service_name" bindValue="id"
					[clearable]="true" [(ngModel)]="filters.services_master_id" placeholder="Filter by service"
					(change)="refreshTable()" appearance="outline">
				</ng-select>
			</div>
			<div class="col-md-2">
				<ng-select appendTo="body" [items]="currencyList" bindLabel="name" bindValue="name" [clearable]="true"
					[(ngModel)]="filters.currency" placeholder="Currency" (change)="refreshTable()"
					appearance="outline">
				</ng-select>
			</div>
			<div class="col-md-3">
				<a class="btn btn-sm btn-outline-success float-end ms-2" [routerLink]="['/app/create-invoice']">
					<fa-icon [icon]="['fas', 'plus']"></fa-icon>
					Create Invoice
				</a>
				<button class="btn btn-sm btn-outline-primary float-end ms-2" matTooltip="Download Excel"
					(click)="invoiceExcelDownload($event)">
					<fa-icon *ngIf="!invExcelLoader" [icon]="['fas', 'cloud-download-alt']"></fa-icon>
					<fa-icon *ngIf="invExcelLoader" [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
				</button>
				<!-- <button class="btn btn-sm btn-outline-info float-end" matTooltip="Create from excel"
					(click)="openCreateModal()">
					<fa-icon [icon]="['fas', 'cloud-upload-alt']"></fa-icon>
				</button> -->
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="table-responsive">
					<table datatable [dtOptions]="dtOptions" class="table table-striped table-bordered table-hover"
						style="width: 100%;">
						<thead>
							<tr class="table_head">
								<th style="width: 15% !important;">Invoice No.</th>
								<th style="width: 10% !important;">Invoice Date</th>
								<th style="width: 15% !important;">Amount</th>
								<!-- <th style="width: 15% !important;">Currency</th> -->
								<!-- <th style="width: 5% !important;">Paid</th> -->
								<th style="width: 10% !important;">Paid Date</th>
								<th style="width: 25% !important;">Company</th>
								<th style="width: 15% !important;">Service</th>
								<th style="width: 10% !important;">Action</th>
							</tr>
						</thead>
						<tbody *ngIf="dataList?.length != 0">
							<tr *ngFor="let v of dataList;let i = index;">
								<td>{{ v.inv_no }}</td>
								<td>{{ reverseDateString(v.inv_date) }}</td>
								<td>
									<span [innerHTML]="v.credit_notes?'-':'&nbsp;&nbsp;'"></span>
									<span [matTooltip]="v.currency">
										<strong *ngIf="v.currency=='SGD'">S</strong>
										<fa-icon [icon]="['fas', getCurrencyIcon(v.currency)]"
											*ngIf="v.currency"></fa-icon>
									</span>
									{{ v.amount }}
								</td>
								<!-- <td>{{ v.currency }}</td> -->
								<!-- <td>
									<fa-icon [icon]="['fas', 'check']" class="text-success" *ngIf="v.is_paid"
										matTooltip="Paid"></fa-icon>
									<fa-icon [icon]="['fas', 'times']" class="text-danger" *ngIf="!v.is_paid"
										matTooltip="Unpaid"></fa-icon>
								</td> -->
								<td>{{ reverseDateString(v.paid_date) }}</td>
								<td>{{ v.company_master.name }}</td>
								<td>{{ v.services_master.service_name }}</td>
								<td>
									<a class="btn btn-sm btn-outline-primary" matTooltip="Edit" id="btnEdit{{v.id1}}"
										[routerLink]="['/app/edit-invoice',v.id1]">
										<fa-icon [icon]="['fas', 'pencil-alt']"></fa-icon>
									</a>
									<button class="btn btn-sm btn-outline-danger ms-1" matTooltip="Delete"
										id="btnDelete{{v.id1}}" (click)="deleteConfirm(v.id1,i)">
										<fa-icon *ngIf="showDeleteLoader != i" [icon]="['fas', 'times']"></fa-icon>
										<fa-icon *ngIf="showDeleteLoader == i" [icon]="['fas', 'spinner']"
											animation="spin"></fa-icon>
									</button>
								</td>
							</tr>
						</tbody>
						<tbody *ngIf="dataList?.length == 0">
							<tr>
								<td colspan="7" class="text-center">No data found!</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</div>
<div *ngIf="UserModuleAccess!='Full' || user_type!='cdm'">
	<h3 class="text-center">You don't have access to this section.</h3>
</div>
<div class="modal fade" id="createInvoiceModal" role="dialog">
	<div class="modal-dialog modal-md">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title"><strong>Create Invoice</strong></h4>
			</div>
			<div class="modal-body">
				<div class="row mb-2">
					<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
						<ngx-file-drop dropZoneLabel="Drop file here" browseBtnLabel="Browse file" [multiple]="false"
							(onFileDrop)="uploadFile($event)" [disabled]="fileUploaderLoader">
							<ng-template multiple="false" ngx-file-drop-content-tmp
								let-openFileSelector="openFileSelector">
								<div style="text-align: center;">
									<button type="button" class="btn btn-outline-primary btn-sm"
										(click)="openFileSelector()" [disabled]="fileUploaderLoader">
										<fa-icon *ngIf="fileUploaderLoader" [icon]="['fas', 'spinner']"
											animation="spin"></fa-icon>
										Drop Or Browse file
									</button>
								</div>
							</ng-template>
						</ngx-file-drop>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
						<a class="btn btn-sm btn-info btn-block" (click)="downloadTemplate()">
							Download Template
						</a>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-warning btn-sm ms-1" data-bs-dismiss="modal">
					Cancel
				</button>
			</div>
		</div>
	</div>
</div>