import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from '../../api.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { noWhitespaceInRequiredValidator } from '../../shared/constants';

@Component({
	selector: 'app-country-create',
	templateUrl: './country-create.component.html',
	styleUrl: './country-create.component.css'
})
export class CountryCreateComponent implements OnInit, AfterViewInit {
	breadcrumbList = [
		{
			path: this.apiService.getHomeURL(),
			name: "Home"
		},
		/* {
			path: '/app/dashboard-mdm',
			name: "MDM"
		}, */
		{
			path: "/app/country",
			name: "Countries"
		}
	];
	uId: any = null;
	pagetitle = "Create Country";
	moduleForm!: FormGroup;
	showLoader: boolean = false;
	showDeleteLoader: boolean = false;
	pageLoading: boolean = false;
	formSubmitted: boolean = false;
	details: any = null;
	customError: any = {};
	UserModuleAccess: any = "Full";
	hideP = true;
	user_type: any = "";
	accessLoader = false;

	constructor(private toastr: ToastrService, private apiService: ApiService, private formBuilder: FormBuilder, private router: Router, private activeRoute: ActivatedRoute) {
		this.accessLoader = true;
		this.apiService.getUserAccess().subscribe((data: any) => {
			this.accessLoader = false;
			try {
				this.user_type = data.res[0].user_type;
				/* let accessObj: any = JSON.parse(data.res[0].UserModuleAccess);
				this.UserModuleAccess = accessObj["USER_Module"]; */
			} catch (error) { }
		}, (error: any) => {
			this.accessLoader = false;
			/* this.UserModuleAccess = "NoAccess"; */
		});
	}
	ngAfterViewInit(): void { }

	ngOnInit(): void {
		this.activeRoute.params.subscribe(routeParams => {
			this.uId = routeParams["uId"] == undefined ? null : routeParams["uId"];
			this.moduleForm = this.formBuilder.group({
				name: [null, [Validators.required, noWhitespaceInRequiredValidator]],
				sh_name: [null, [Validators.required, noWhitespaceInRequiredValidator]],
				sh_name1: [null, [Validators.required, noWhitespaceInRequiredValidator]],
				nationality: [null, [Validators.required, noWhitespaceInRequiredValidator]]
			})
			if (this.uId) {
				this.breadcrumbList.push({ path: "/app/edit-country/" + this.uId, name: "Edit Country" });
				this.pagetitle = "Edit Country";
				this.pageLoading = true;
				this.getDetails();
			} else {
				this.breadcrumbList.push({ path: "/app/create-country", name: "Add Country" });
				this.pageLoading = true;
			}
		});
	}

	formChanged() {
		this.moduleForm.markAsDirty();
	}

	showError(field:string){
		if(this.f[field].invalid && (this.formSubmitted || this.f[field].touched || this.f[field].dirty)){
			return true;
		}
		return false;
	}

	getDetails() {
		this.apiService.getCountryDetails({ "id": this.uId }).subscribe((data: any) => {
			this.details = data.data;
			this.f.name.setValue(this.details.name);
			this.f.sh_name.setValue(this.details.sh_name);
			this.f.sh_name1.setValue(this.details.sh_name1);
			this.f.nationality.setValue(this.details.nationality);
		}, (error) => {
			this.pageLoading = false;
			if (error.status == 401) { return }
			this.toastr.error("Unable to fetch details.", 'Error', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
		});
	}

	get f() { return this.moduleForm.controls; }

	clearCustomError() {
		Object.keys(this.f).forEach(key => {
			if (this.f[key].hasError('incorrect')) {
				try {
					delete this.f[key].errors?.incorrect;
					this.f[key].updateValueAndValidity();
				} catch (error) {

				}

			}
		});
		this.customError = {};
	}

	saveData() {
		this.formSubmitted = true;
		this.clearCustomError();
		if (this.moduleForm.invalid) {
			this.toastr.info("Please check required parameters.", 'Warning', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
			return;
		}
		let payload: any = {
			name: this.f.name.value,
			sh_name: this.f.sh_name.value,
			sh_name1: this.f.sh_name1.value,
			nationality: this.f.nationality.value
		};
		if (this.uId) {
			payload["id"] = this.uId;
		}
		this.showLoader = true;
		this.apiService.createUpdateCountry(payload).subscribe(data => {
			this.showLoader = false;
			this.toastr.success("Saved successfully.", 'Success', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
			this.gotoList();
		}, (error: any) => {
			this.showLoader = false;
			if (error.status == 401) { return }
			if (error.status == 409) {
				Swal.fire({
					title: 'Already Exists',
					html: 'Country already exists. Do you want to restore it?',
					icon: 'question',
					showCancelButton: true,
					showConfirmButton: true,
					confirmButtonText: 'Yes, Restore!',
					cancelButtonText: 'Cancel',
					confirmButtonColor: '#3e4fb1'
				}).then((result) => {
					if (result.value) {
						this.apiService.restoreCountry(error.error.id).subscribe((data: any) => {
							this.toastr.success("Country restored successfully", 'Success', {
								timeOut: 3000,
								tapToDismiss: true,
								closeButton: true
							});
							this.gotoList();
						}, (error) => {
							this.toastr.error("Something went wrong", 'Error', {
								timeOut: 3000,
								tapToDismiss: true,
								closeButton: true
							});
						});
					} else if (result.dismiss === Swal.DismissReason.cancel) { }
				})
			} else {
				let errorMsg: any = "Something went wrong.";
				if (error.status == 400) {
					errorMsg = error.error;
					if (typeof errorMsg == 'object') {
						for (const [key, value] of Object.entries(errorMsg)) {
							let val: any = value;
							if (val == "This field must be unique.") {
								val = "Already exists";
							}
							if (this.moduleForm.controls[key]) {
								this.moduleForm.controls[key].setErrors({ incorrect: true });
								this.customError[key] = val;
								let selector = "#" + key;
								try {
									$('html, body').animate({
										scrollTop: $(selector).offset().top - 60 + 'px'
									}, 'fast');
								} catch (error) { }
								$(selector).focus();
							} else {
								this.toastr.error(key + ":" + val, 'Error', {
									timeOut: 3000,
									tapToDismiss: true,
									closeButton: true
								});
							}

						}
						return;
					}
				} else {
					errorMsg = error.error;
				}
				this.toastr.error(errorMsg, 'Error', {
					timeOut: 3000,
					tapToDismiss: true,
					closeButton: true
				});
			}
		});
	}

	gotoList() {
		this.router.navigate(['/app/country']);
	}
}
