<breadcrumb [breadcrumbList]="breadcrumbList"></breadcrumb>
<div *ngIf="accessLoader" class="loader text-center">
	<img src="../assets/img/cc_loader.gif" />
</div>
<div class="card" *ngIf="UserModuleAccess=='Full' && user_type=='cdm'">
	<div class="card-header bg-primary">
		{{pagetitle}}
	</div>
	<div class="card-body">
		<form class="form-horizontal" [formGroup]="moduleForm" (ngSubmit)="saveData()" #modform="ngForm">
			<div class="row mb-3">
				<div class="col">
					<div class="form-floating">
						<input class="form-control" [ngClass]="{'is-invalid':showError('first_name')}" id="first_name"
							formControlName="first_name" placeholder="First Name*">
						<label for="first_name">First Name*</label>
					</div>
					<p *ngIf="showError('first_name')" class="text-danger">
						<span *ngIf="f.first_name.errors?.required">
							Required
						</span>
						<span *ngIf="f.first_name.errors?.incorrect">
							{{customError.first_name}}
						</span>
					</p>
				</div>
				<div class="col">
					<div class="form-floating">
						<input class="form-control" [ngClass]="{'is-invalid':showError('last_name')}" id="last_name"
							formControlName="last_name" placeholder="Last Name*">
						<label for="last_name">Last Name*</label>
					</div>
					<p *ngIf="showError('last_name')" class="text-danger">
						<span *ngIf="f.last_name.errors?.required">
							Required
						</span>
						<span *ngIf="f.last_name.errors?.incorrect">
							{{customError.last_name}}
						</span>
					</p>
				</div>
				<div class="col">
					<div class="form-floating">
						<input class="form-control" [ngClass]="{'is-invalid':showError('email')}" id="email"
							formControlName="email" placeholder="Email Id*">
						<label for="email">Email Id*</label>
					</div>
					<p *ngIf="showError('email')" class="text-danger">
						<span *ngIf="f.email.errors?.required">
							Required
						</span>
						<span *ngIf="f.email.errors?.pattern">
							Invalid
						</span>
						<span *ngIf="f.email.errors?.incorrect">
							{{customError.email}}
						</span>
					</p>
				</div>
				<div class="col">
					<mat-label>Gender<sup>*</sup></mat-label>
					<br />
					<!-- <mat-button-toggle-group [vertical]="false" [name]="'gender'" [value]="f.gender.value"
						[appearance]="'legacy'" (change)="genderChanged($event);formChanged();">
						<mat-button-toggle ngDefaultControl [value]="'Male'">
							<fa-icon [icon]="['fas', 'male']" class="text-success"></fa-icon>
							Male
						</mat-button-toggle>
						<mat-button-toggle ngDefaultControl [value]="'Female'">
							<fa-icon [icon]="['fas', 'female']" class="text-success"></fa-icon>
							Female
						</mat-button-toggle>
					</mat-button-toggle-group> -->
					<mat-radio-group formControlName="gender">
						<mat-radio-button [value]="'Male'" [color]="'primary'">
							Male
						</mat-radio-button>
						<mat-radio-button [value]="'Female'" [color]="'primary'">
							Female
						</mat-radio-button>
					</mat-radio-group>
					<mat-error *ngIf="f.gender.invalid && (modform.submitted || f.gender.touched || f.gender.dirty)">
						<span *ngIf="f.gender.errors?.required">
							Required
						</span>
						<span *ngIf="f.gender.errors?.incorrect">
							{{customError.gender}}
						</span>
					</mat-error>
				</div>
				<div class="col">
					<mat-label>Is Specialist</mat-label>
					<br />
					<mat-radio-group formControlName="is_specialist">
						<mat-radio-button [value]="1" [color]="'primary'">
							Yes
						</mat-radio-button>
						<mat-radio-button [value]="0" [color]="'primary'">
							No
						</mat-radio-button>
					</mat-radio-group>
					<mat-error
						*ngIf="f.is_specialist.invalid && (modform.submitted || f.is_specialist.touched || f.is_specialist.dirty)">
						<span *ngIf="f.is_specialist.errors?.required">
							Required
						</span>
						<span *ngIf="f.is_specialist.errors?.incorrect">
							{{customError.is_specialist}}
						</span>
					</mat-error>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col">
					<div class="w-50 float-start">
						<ng-select appendTo="body" [items]="countries" bindLabel="display" bindValue="dial_code"
							[clearable]="true" [multiple]="false" id="country_code" formControlName="country_code"
							name="country_code" placeholder="Country Code" [appearance]="'outline'">
						</ng-select>
						<p *ngIf="showError('country_code')" class="text-danger">
							<span *ngIf="f.country_code.errors?.required">
								Required
							</span>
							<span *ngIf="f.country_code.errors?.incorrect">
								{{customError.country_code}}
							</span>
						</p>
					</div>
					<div class="w-50 float-start">
						<div class="form-floating">
							<input class="form-control" [ngClass]="{'is-invalid':showError('contact_no')}"
								id="contact_no" formControlName="contact_no" placeholder="">
							<label for="contact_no">Contact No.*</label>
						</div>
						<p *ngIf="showError('contact_no')" class="text-danger">
							<span *ngIf="f.contact_no.errors?.required">
								Required
							</span>
							<span *ngIf="f.contact_no.errors?.pattern">
								Invalid
							</span>
							<span *ngIf="f.contact_no.errors?.incorrect">
								{{customError.contact_no}}
							</span>
						</p>
					</div>
				</div>
				<div class="col">
					<div class="form-floating">
						<input class="form-control" [ngClass]="{'is-invalid':showError('registration_no')}"
							id="registration_no" formControlName="registration_no" placeholder="">
						<label for="registration_no">Registration No.</label>
					</div>
					<p *ngIf="showError('registration_no')" class="text-danger">
						<span *ngIf="f.registration_no.errors?.incorrect">
							{{customError.registration_no}}
						</span>
					</p>
				</div>
				<div class="col">
					<div class="form-floating">
						<input class="form-control" [ngClass]="{'is-invalid':showError('qualification')}"
							id="qualification" formControlName="qualification" placeholder="">
						<label for="qualification">Qualification</label>
					</div>
					<p *ngIf="showError('qualification')" class="text-danger">
						<span *ngIf="f.qualification.errors?.incorrect">
							{{customError.qualification}}
						</span>
					</p>
				</div>
				<div class="col">
					<div class="form-floating">
						<input class="form-control" [ngClass]="{'is-invalid':showError('dg_no')}" id="dg_no"
							formControlName="dg_no" placeholder="">
						<label for="dg_no">DG No.</label>
					</div>
					<p *ngIf="showError('dg_no')" class="text-danger">
						<span *ngIf="f.dg_no.errors?.incorrect">
							{{customError.dg_no}}
						</span>
					</p>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col">
					<div class="form-floating">
						<input class="form-control" [ngClass]="{'is-invalid':showError('licence_auth')}"
							id="licence_auth" formControlName="licence_auth" placeholder="">
						<label for="licence_auth">Licence Authority</label>
					</div>
					<p *ngIf="showError('licence_auth')" class="text-danger">
						<span *ngIf="f.licence_auth.errors?.incorrect">
							{{customError.licence_auth}}
						</span>
					</p>
				</div>
				<div class="col">
					<mat-form-field>
						<mat-label>Licence Date(MM/DD/YYYY)</mat-label>
						<input matInput [matDatepicker]="licence_date_picker" formControlName="licence_date">
						<!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
						<mat-datepicker-toggle matIconSuffix [for]="licence_date_picker"></mat-datepicker-toggle>
						<mat-datepicker #licence_date_picker></mat-datepicker>
					</mat-form-field>
				</div>
				<div class="col"></div>
				<div class="col"></div>
			</div>
			<div class="row mb-3" *ngIf="!uId">
				<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
					<div class="row">
						<div class="col-lg-11 col-md-11 col-sm-11 col-xs-11 pe-0">
							<div class="form-floating">
								<input class="form-control" [type]="hideP ? 'password' : 'text'"
									[ngClass]="{'is-invalid':showError('password')}" id="password"
									formControlName="password" placeholder="">
								<label for="password">Password*</label>
							</div>
						</div>
						<div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 ps-0">
							<button type="button" (click)="hideP = !hideP" class="btn btn-sm btn-outline-dark h-100">
								<fa-icon *ngIf="hideP" [icon]="['fas', 'eye']" style="font-size: medium;"></fa-icon>
								<fa-icon *ngIf="!hideP" [icon]="['fas', 'eye-slash']"
									style="font-size: medium;"></fa-icon>
							</button>
						</div>
					</div>
					<p *ngIf="showError('password')" class="text-danger">
						<span *ngIf="f.password.errors?.required">
							Required
						</span>
						<span *ngIf="f.password.errors?.incorrect">
							{{customError.password}}
						</span>
					</p>
				</div>
				<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
					<div class="form-floating">
						<input class="form-control" type="password"
							[ngClass]="{'is-invalid':showError('confirm_password')}" id="confirm_password"
							formControlName="confirm_password" placeholder="Confirm Password*">
						<label for="confirm_password">Confirm Password*</label>
					</div>
					<p *ngIf="showError('confirm_password')" class="text-danger">
						<span *ngIf="f.confirm_password.errors?.required">
							Required
						</span>
						<span *ngIf="f.confirm_password.errors?.incorrect">
							{{customError.confirm_password}}
						</span>
					</p>
					<p *ngIf="moduleForm.errors && (modform.submitted || f.confirm_password.touched)"
						class="text-danger">
						<span *ngIf="moduleForm.errors?.passwordNotMatch">
							Password and Confirm Password not matched!
							<fa-icon [icon]="['fas', 'times']"></fa-icon>
						</span>
					</p>
				</div>
				<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
					<div *ngIf="showError('password')">
						<p [ngClass]="f.password.errors?.minlengtherror?'text-danger':'text-success'">
							Must be atleast 8 characters long.
						</p>
						<p [ngClass]="f.password.errors?.specialcharerror?'text-danger':'text-success'">
							Must have atleast 1 special character.
						</p>
						<p [ngClass]="f.password.errors?.uppercaseerror?'text-danger':'text-success'">
							Must have atleast 1 upper case character.
						</p>
						<p [ngClass]="f.password.errors?.lowercaseerror?'text-danger':'text-success'">
							Must have atleast 1 lower case character.
						</p>
						<p [ngClass]="f.password.errors?.digiterror?'text-danger':'text-success'">
							Must have atleast 1 digit.
						</p>
					</div>
				</div>
			</div>
			<!-- <div class="row mb-3">
				<div class="col">
					<mat-label>Signature
						<fa-icon [icon]="['fas', 'sync']" class="ms-1" style="cursor: pointer;" matTooltip="Clear"
							(click)="clearSign()"></fa-icon>
					</mat-label>
					<div style="border: 1px solid #dbd9d9;">
						<signature-pad [options]="signaturePadOptions" (drawStart)="drawStart()"
							(drawEnd)="drawComplete()"></signature-pad>
					</div>
				</div>
				<div class="col">
					<mat-label>Upload File</mat-label>
					<ngx-file-drop dropZoneLabel="Drop file here" browseBtnLabel="Browse file" [multiple]="false"
						(onFileDrop)="caseFilesUpload($event)">
						<ng-template multiple="false" ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
							<div style="text-align: center;">
								<label>*Drop file here</label><br>
								<label>OR</label><br>
								<button type="button" class="btn btn-outline-primary btn-sm" (click)="openFileSelector()">Browse
									file</button>
							</div>
						</ng-template>
					</ngx-file-drop>
					<img *ngIf="digitalSignaturePreview" src="{{digitalSignaturePreview}}" style="width: 100px;" />
				</div>
				<div class="col">
					<ng-container *ngIf="signaturePath">
						<mat-label>Previous Signature</mat-label>
						<img src="{{signaturePath}}" />
					</ng-container>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col">
					<mat-label>Email Signature </mat-label>
					<quill-editor formControlName="email_signature" class="w-100" [placeholder]="'Email Signature'" [modules]="quillConfig"></quill-editor>
				</div>
			</div> -->
			<div class="row mb-3">
				<div class="col text-center">
					<button type="submit" [disabled]="showLoader || !modform.dirty"
						class="btn btn-sm btn-outline-success">
						<span class="me-1">
							<fa-icon *ngIf="showLoader" [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
							<fa-icon *ngIf="!showLoader" [icon]="['fas', 'save']"></fa-icon>
						</span>
						<span>Save</span>
					</button>
					<a class="btn btn-sm btn-outline-dark ms-1" [routerLink]="['/app/mdm-users']">Cancel</a>
				</div>
			</div>
		</form>
	</div>
</div>
<div *ngIf="UserModuleAccess!='Full' || user_type!='cdm'">
	<h3 class="text-center">You don't have access to this section.</h3>
</div>