import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from '../../api.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
import Swal from 'sweetalert2';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { emailPattern, mobilePattern, noWhitespaceInRequiredValidator } from '../../shared/constants';

@Component({
	selector: 'app-poc-create',
	templateUrl: './poc-create.component.html',
	styleUrl: './poc-create.component.css'
})
export class PocCreateComponent implements OnInit, AfterViewInit {
	breadcrumbList = [
		{
			path: this.apiService.getHomeURL(),
			name: "Home"
		},
		/* {
			path: '/app/dashboard-mdm',
			name: "MDM"
		}, */
		{
			path: "/app/poc",
			name: "POC"
		}
	];
	uId: any = null;
	pagetitle = "Create Point of Contact";
	moduleForm!: FormGroup;
	showLoader: boolean = false;
	showDeleteLoader: boolean = false;
	pageLoading: boolean = false;
	formSubmitted: boolean = false;
	details: any = null;
	customError: any = {};
	UserModuleAccess: any = "Full";
	hideP = true;
	companyList: any = [];
	fleetList: any = [];
	requiredValidator = Validators.required;
	defaultTypeVal = 1;
	user_type: any = "";
	accessLoader = false;

	constructor(private toastr: ToastrService, private apiService: ApiService, private formBuilder: FormBuilder, private router: Router, private activeRoute: ActivatedRoute) {
		this.accessLoader = true;
		this.apiService.getUserAccess().subscribe((data: any) => {
			this.accessLoader = false;
			try {
				this.user_type = data.res[0].user_type;
				/* let accessObj: any = JSON.parse(data.res[0].UserModuleAccess);
				this.UserModuleAccess = accessObj["USER_Module"]; */
			} catch (error) { }
		}, (error: any) => {
			this.accessLoader = false;
			/* this.UserModuleAccess = "NoAccess"; */
		});
	}
	ngAfterViewInit(): void { }

	ngOnInit(): void {
		this.activeRoute.params.subscribe(routeParams => {
			this.uId = routeParams["uId"] == undefined ? null : routeParams["uId"];
			this.moduleForm = this.formBuilder.group({
				f_name: [null, [this.requiredValidator, noWhitespaceInRequiredValidator]],
				l_name: [null, [this.requiredValidator, noWhitespaceInRequiredValidator]],
				notes: [null],
				email: [null, [this.requiredValidator, noWhitespaceInRequiredValidator]],
				phone_no: [null, [this.requiredValidator, noWhitespaceInRequiredValidator]],
				type: [this.defaultTypeVal, [this.requiredValidator, noWhitespaceInRequiredValidator]]
			})
			this.getCompanyList();
			if (this.uId) {
				this.breadcrumbList.push({ path: "/app/edit-poc/" + this.uId, name: "Edit POC" });
				this.pagetitle = "Edit Point of Contact";
				this.pageLoading = true;
				this.getDetails();
			} else {
				this.breadcrumbList.push({ path: "/app/create-poc", name: "Add POC" });
				this.pageLoading = true;
			}
		});
	}

	formChanged() {
		this.moduleForm.markAsDirty();
	}

	showError(field: string) {
		if (this.f[field].invalid && (this.formSubmitted || this.f[field].touched || this.f[field].dirty)) {
			return true;
		}
		return false;
	}

	getDetails() {
		this.apiService.getPocDetails({ "id": this.uId }).subscribe((data: any) => {
			this.details = data.data;
			this.f.f_name.setValue(this.details.f_name);
			this.f.l_name.setValue(this.details.l_name);
			this.f.notes.setValue(this.details.notes);
			let email: any;
			try {
				email = JSON.parse(this.details.email);
			} catch (error) {
				email = [];
			}
			this.f.email.setValue(email);
			let phone_no: any;
			try {
				phone_no = JSON.parse(this.details.phone_no);
			} catch (error) {
				phone_no = [];
			}
			this.f.phone_no.setValue(phone_no);
			this.f.type.setValue(this.details.type?this.details.type:this.defaultTypeVal);
		}, (error) => {
			this.pageLoading = false;
			if (error.status == 401) { return }
			this.toastr.error("Unable to fetch details.", 'Error', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
		});
	}

	phonePatternValidator(control: FormControl) {
		const regex = /(^(\+ *[0-9]+)([,0-9 ]*)([0-9 ])*$)|(^ *$)/;
		if (!control.value.match(regex)) {
			return {
				'phnerr': true
			};
		}
		return null;
	}

	getCompanyList() {
		this.apiService.getCompanyDetails({}).subscribe((data: any) => {
			this.companyList = data.data;
		}, (error) => {
			this.companyList = [];
		});
	}

	getFleetList(clearFleet: boolean) {
		if (clearFleet) {
			this.f.fleet.setValue(null);
		}
		if (this.f.company.value) {
			this.apiService.getFleetsByCompany({ id: this.f.company.value }).subscribe((data: any) => {
				this.fleetList = data.data;
			}, (error) => {
				this.fleetList = [];
			});
		} else {
			this.fleetList = [];
		}
	}

	get f() { return this.moduleForm.controls; }

	clearCustomError() {
		Object.keys(this.f).forEach(key => {
			if (this.f[key].hasError('incorrect')) {
				try {
					delete this.f[key].errors?.incorrect;
					this.f[key].updateValueAndValidity();
				} catch (error) {

				}

			}
		});
		this.customError = {};
	}

	saveData() {
		this.formSubmitted = true;
		this.clearCustomError();
		if (this.moduleForm.invalid) {
			this.toastr.info("Please check required parameters.", 'Warning', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
			return;
		}
		let phone_no: any;
		try {
			phone_no = JSON.stringify(this.f.phone_no.value);
		} catch (error) {
			phone_no = JSON.stringify([]);
		}
		let email: any;
		try {
			email = JSON.stringify(this.f.email.value);
		} catch (error) {
			email = JSON.stringify([]);
		}
		let payload: any = {
			f_name: this.f.f_name.value,
			l_name: this.f.l_name.value,
			notes: this.f.notes.value,
			email: email,
			phone_no: phone_no,
			type: this.f.type.value
		};
		if (this.uId) {
			payload["id"] = this.uId;
		}
		this.showLoader = true;
		this.apiService.createUpdatePoc(payload).subscribe(data => {
			this.showLoader = false;
			this.toastr.success("Saved successfully.", 'Success', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
			this.gotoList();
		}, (error: any) => {
			this.showLoader = false;
			if (error.status == 401) { return }
			if (error.status == 409) {
				Swal.fire({
					title: 'Already Exists',
					html: 'POC already exists. Do you want to restore it?',
					icon: 'question',
					showCancelButton: true,
					showConfirmButton: true,
					confirmButtonText: 'Yes, Restore!',
					cancelButtonText: 'Cancel',
					confirmButtonColor: '#3e4fb1'
				}).then((result) => {
					if (result.value) {
						this.apiService.restorePoc(error.error.id).subscribe((data: any) => {
							this.toastr.success("POC restored successfully", 'Success', {
								timeOut: 3000,
								tapToDismiss: true,
								closeButton: true
							});
							this.gotoList();
						}, (error) => {
							this.toastr.error("Something went wrong", 'Error', {
								timeOut: 3000,
								tapToDismiss: true,
								closeButton: true
							});
						});
					} else if (result.dismiss === Swal.DismissReason.cancel) { }
				})
			} else {
				let errorMsg: any = "Something went wrong.";
				if (error.status == 400) {
					errorMsg = error.error;
					if (typeof errorMsg == 'object') {
						for (const [key, value] of Object.entries(errorMsg)) {
							let val: any = value;
							if (val == "This field must be unique.") {
								val = "Already exists";
							}
							if (this.moduleForm.controls[key]) {
								this.moduleForm.controls[key].setErrors({ incorrect: true });
								this.customError[key] = val;
								let selector = "#" + key;
								try {
									$('html, body').animate({
										scrollTop: $(selector).offset().top - 60 + 'px'
									}, 'fast');
								} catch (error) { }
								$(selector).focus();
							} else {
								this.toastr.error(key + ":" + val, 'Error', {
									timeOut: 3000,
									tapToDismiss: true,
									closeButton: true
								});
							}

						}
						return;
					}
				} else {
					errorMsg = error.error;
				}
				this.toastr.error(errorMsg, 'Error', {
					timeOut: 3000,
					tapToDismiss: true,
					closeButton: true
				});
			}
		});
	}

	gotoList() {
		this.router.navigate(['/app/poc']);
	}
}
