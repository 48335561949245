
import { Subscription, ReplaySubject, Subject, Observable, fromEvent, of } from 'rxjs';
import { Component, OnInit, Input, OnDestroy, ViewChild, AfterViewInit, Inject, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from '@angular/common';
import { ApiService, HttpParamsOptions } from '../../api.service';
import { ToastrService } from 'ngx-toastr';
import { EventEmitter, Output } from '@angular/core';
declare var $: any;
import { debounceTime } from "rxjs/operators";
import { DataTableDirective } from 'angular-datatables';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import * as FileSaver from 'file-saver';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl, ValidatorFn, AbstractControl, ValidationErrors, AsyncValidatorFn } from '@angular/forms';
import { Config } from 'datatables.net';
import Swal from 'sweetalert2';
import { password_validation } from '../../shared/constants';
import { NgxFileDropEntry } from 'ngx-file-drop';

class DataTablesResponse {
	data!: any[];
	draw!: number;
	recordsFiltered!: number;
	recordsTotal!: number;
}

@Component({
	selector: 'app-invoice-list',
	templateUrl: './invoice-list.component.html',
	styleUrl: './invoice-list.component.scss'
})
export class InvoiceListComponent implements OnInit {
	breadcrumbList: any[] = [
		{
			path: this.apiService.getHomeURL(),
			name: "Home"
		},
		{
			path: "/app/invoices",
			name: "Invoices"
		}
	];

	@ViewChild(DataTableDirective, { static: false }) datatableElement!: DataTableDirective;
	baseURL = environment.API_URL + "api/";
	dtOptions: Config = {};
	dataList: any = [];
	showDeleteLoader: any = null;
	pagetitle = "Invoice List";
	pageLoading = false;
	accessLoader = false;
	allFlagStates: any[] = [];
	flagState: any = null;
	UserModuleAccess: any = "Full";
	user_type: any = "";
	servicesList: any = [];
	filters: any = {
		services_master_id: null,
		currency: null,
		start_date: null,
		end_date: null
	}
	invExcelLoader = false;
	errorLog: any = null;
	errorLogs = false;
	fileUploaderLoader = false;
	totalINR: any;
	totalUSD: any;
	currencyList: any = [];

	constructor(private formBuilder: FormBuilder, private apiService: ApiService, private activeRoute: ActivatedRoute, private toastr: ToastrService, private http: HttpClient) {
		this.accessLoader = true;
		this.apiService.getUserAccess().subscribe({
			next: (data: any) => {
				this.accessLoader = false;
				try {
					this.user_type = data.res[0].user_type;
					let accessObj: any = JSON.parse(data.res[0].UserModuleAccess);
					this.UserModuleAccess = accessObj["Invoice_Module"];
				} catch (error) { }
			}, error: (error: any) => {
				this.accessLoader = false;
				this.UserModuleAccess = "NoAccess";
			}
		});
	}

	ngOnInit(): void {
		this.getServicesList();
		this.getCurrencyList();
		this.initDatatable();
	}

	initDatatable() {
		this.dtOptions = {
			pagingType: 'full_numbers',
			serverSide: true,
			processing: true,
			ordering: false,
			ajax: (dataTablesParameters: any, callback) => {
				let data: any = {
					draw: dataTablesParameters.draw,
					start: dataTablesParameters.start,
					length: dataTablesParameters.length,
					searchVal: dataTablesParameters.search.value
				}
				if (this.filters.services_master_id) {
					data.services_master_id = this.filters.services_master_id;
				}
				if (this.filters.currency) {
					data.currency = this.filters.currency;
				}
				if (this.filters.start_date) {
					data.start_date = this.apiService.getDateStringFromDate(this.filters.start_date);
				}
				if (this.filters.end_date) {
					data.end_date = this.apiService.getDateStringFromDate(this.filters.end_date);
				}
				const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
				this.http.get<DataTablesResponse>(this.baseURL + "InvoiceMasterApi", { params: new HttpParams(httpParams) }).subscribe({
					next: (response: any) => {
						this.dataList = response.data;
						this.totalINR = response.total_inr !== null ? Math.round((response.total_inr + Number.EPSILON) * 100) / 100 : null;
						this.totalUSD = response.total_usd !== null ? Math.round((response.total_usd + Number.EPSILON) * 100) / 100 : null;
						callback({
							recordsTotal: response.recordsTotal,
							recordsFiltered: response.recordsFiltered,
							data: []
						});
					}, error: (error: any) => {
						if (error.status == 401) { return }
						this.toastr.error("Something went wrong.", 'Error!!!', {
							timeOut: 3000,
							tapToDismiss: true,
							closeButton: true
						});
					}
				});
			}
		}
	}

	getServicesList() {
		this.apiService.getServicesList().subscribe({
			next: (data: any) => {
				this.servicesList = data;
			}, error: (error) => {
				this.servicesList = [];
			}
		});
	}

	getCurrencyList() {
		this.apiService.currencyList().subscribe({
			next: (data: any) => {
				this.currencyList = data;
			}, error: (error) => {
			}
		});
	}

	getCurrencyIcon(currencyName: any) {
		let len = this.currencyList.length;
		for (let i = 0; i < len; i++) {
			if (this.currencyList[i].name == currencyName) {
				return this.currencyList[i].icon;
			}
		}
		return "rupee-sign";
	}

	deleteConfirm(uId: any, index: number) {
		Swal.fire({
			title: 'Warning !!!',
			html: 'Are you sure you want to delete invoice <p style="text-align:center;font-size: 15px;"><strong>' + this.dataList[index].FirstName + '</strong>?</p>',
			icon: 'warning',
			showCancelButton: true,
			showConfirmButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#dc3545'
		}).then((result) => {
			if (result.value) {
				this.showDeleteLoader = index;
				this.apiService.deleteInvoice({ id: uId }).subscribe({
					next: data => {
						this.showDeleteLoader = null;
						Swal.fire('Success', 'Invoice Deleted Successfully.', 'success');
						this.refreshTable();
					}, error: (error) => {
						this.showDeleteLoader = null;
						if (error.status == 401) { return }
						if (error.status == 404) {
							Swal.fire('Error', error.error, 'error');
						} else {
							Swal.fire('Error', 'Something went wrong.', 'error');
						}
					}
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}

	refreshTable() {
		this.datatableElement.dtInstance.then((dtInstance) => {
			dtInstance.draw();
		});
	}

	invoiceExcelDownload(e: any) {
		e.stopPropagation();
		let data: any = {};
		if (this.filters.services_master_id) {
			data.services_master_id = this.filters.services_master_id;
		}
		if (this.filters.currency) {
			data.currency = this.filters.currency;
		}
		if (this.filters.start_date) {
			data.start_date = this.apiService.getDateStringFromDate(this.filters.start_date);
		}
		if (this.filters.end_date) {
			data.end_date = this.apiService.getDateStringFromDate(this.filters.end_date);
		}
		this.invExcelLoader = true;
		this.apiService.invoiceExcelDownload(data).subscribe(res => {
			let excelData: any = res.body;
			this.invExcelLoader = false;
			let blob = new Blob([excelData], { type: "application/ms-excel" });
			let fileName = "Invoices.xlsx";
			FileSaver.saveAs(blob, fileName);
		}, (error) => {
			this.invExcelLoader = false;
			if (error.status == 401) { return }
			this.toastr.error("Unable to download excel", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
		});
	}

	openCreateModal() {
		$("#createInvoiceModal").modal("show");
	}

	downloadTemplate() {
		var links = [
			'../../assets/files/InvoiceTemplate.xlsx'
		];
		links.forEach((link) => {
			var a = document.createElement('a');
			a.href = link;
			a.download = link.substring(link.lastIndexOf('/') + 1);
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		});
	}

	public uploadFile(files: NgxFileDropEntry[]) {
		let droppedFile = files[0];
		if (droppedFile.fileEntry.isFile) {
			const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
			fileEntry.file((file: File) => {
				const formdata = new FormData();
				formdata.append("excel_file", file)
				this.fileUploaderLoader = true;
				this.apiService.createInvoiceFromExcel(formdata).subscribe({
					next: (data: any) => {
						this.fileUploaderLoader = false;
						this.toastr.success("Created successfully", 'Success', {
							timeOut: 3000,
							tapToDismiss: true,
							closeButton: true
						});
						$("#createInvoiceModal").modal("hide");
						this.refreshTable();
					}, error: (error: any) => {
						this.fileUploaderLoader = false;
						Swal.fire("Error", error.error, "error");
					}
				});
			});
		} else {
			this.errorLog = "Invalid File Format.";
			this.errorLogs = true;
			setTimeout(() => {
				this.errorLogs = false;
			}, 10000);
			this.toastr.error("Invalid File Format.", 'Error', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
		}
	}

	reverseDateString(dateString:any){
		if (dateString == "" || dateString == null || dateString == undefined) {
			return "";
		}
		try {
			return dateString.split("-").reverse().join("-");
		} catch (error) {
			return "";
		}
	}
}
