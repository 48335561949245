<breadcrumb [breadcrumbList]="breadcrumbList"></breadcrumb>
<div *ngIf="accessLoader" class="loader text-center">
	<img src="../assets/img/cc_loader.gif" />
</div>
@if (user_type=='cdm') {
@if(UserModuleAccess=='Full'){
<ng-container [ngTemplateOutlet]="dashboardtemplate"></ng-container>
}
} @else if(user_type=='company') {
@if(UserModuleAccess=='Full' && !isCompanyIdNull){
<ng-container [ngTemplateOutlet]="dashboardtemplate"></ng-container>
}
}
<ng-template #dashboardtemplate>
	<div class="card">
		<div class="card-header bg-primary">
			{{pagetitle}}
		</div>
		<div class="card-body">
			<div class="row">
				<div class="col-lg-3 col-md-3 col-sm-6 col-xs-6">
					<mat-form-field>
						<mat-label>Start Date</mat-label>
						<input matInput [matDatepicker]="start_date_picker_1" [(ngModel)]="cardsFilter.start_date">
						<mat-hint>MM/DD/YYYY</mat-hint>
						<mat-datepicker-toggle matIconSuffix [for]="start_date_picker_1"></mat-datepicker-toggle>
						<mat-datepicker #start_date_picker_1></mat-datepicker>
					</mat-form-field>
				</div>
				<div class="col-lg-3 col-md-3 col-sm-6 col-xs-6">
					<mat-form-field>
						<mat-label>End Date</mat-label>
						<input matInput [matDatepicker]="end_date_picker_1" [(ngModel)]="cardsFilter.end_date">
						<mat-hint>MM/DD/YYYY</mat-hint>
						<mat-datepicker-toggle matIconSuffix [for]="end_date_picker_1"></mat-datepicker-toggle>
						<mat-datepicker #end_date_picker_1></mat-datepicker>
					</mat-form-field>
				</div>
				<div class="col-lg-3 col-md-3 col-sm-6 col-xs-6 mt-1" *ngIf="user_type=='cdm'">
					<ng-select appearance="outline" appendTo="body" [items]="companyList_dropdown" bindLabel="name"
						[clearable]="true" [multiple]="false" [(ngModel)]="cardsFilter.company_id" placeholder="Company"
						(change)="setCompany(cardsFilter.company_id)">
					</ng-select>
				</div>
				<div class="col-lg-1 col-md-1 col-sm-6 col-xs-6">
					<button type="button" class="btn btn-md btn-success mt-2 radius-50" (click)="filterChanged();">
						<fa-icon [icon]="['fas', 'search']"></fa-icon>
					</button>
				</div>
			</div>
			<mat-accordion [hideToggle]="false">
				<!-- <mat-expansion-panel [expanded]="true" (opened)="accordionOpened('CubeInvoicesReport')">
						<mat-expansion-panel-header class="bg-acc_style_1">
							<mat-panel-title>
								<b>{{CubeInvoicesReportHeader}}</b>
							</mat-panel-title>
							<mat-panel-description>
								<button class="btn btn-sm btn-light" matTooltip="Excel" [matTooltipPosition]="'below'"
									(click)="getCubeInvoicesReportExcel($event)">
									<fa-icon *ngIf="!CubeInvoicesReportExcelLoader"
										[icon]="['fas', 'cloud-download-alt']"></fa-icon>
									<fa-icon *ngIf="CubeInvoicesReportExcelLoader" [icon]="['fas', 'spinner']"
										animation="spin"></fa-icon>
								</button>
							</mat-panel-description>
						</mat-expansion-panel-header>
						<div class="row">
							<div class="col-sm-12">
								<div class="row">
									<div class="col-sm-12">
										<div class="table-responsive" style="height: 450px;overflow-y: auto;">
											<table class="myTable table table-bordered table-hover mt-2" style="width: 100%;">
												<thead>
													<tr>
														<th style="width: 20% !important;">Invoice No</th>
														<th style="width: 20% !important;">Invoice Date</th>
														<th style="width: 40% !important;">Doctor</th>
														<th style="width: 20% !important;">Amount</th>
													</tr>
												</thead>
												<tbody *ngIf="CubeInvoicesReport?.length != 0">
													<tr class="table_td_style"
														*ngFor="let a of CubeInvoicesReport;let i = index;">
														<td>{{a.invoice_no}}</td>
														<td>{{a.invoice_date}}</td>
														<td>{{a.doctor}}</td>
														<td>{{a.amount}}</td>
													</tr>
												</tbody>
											</table>
											<p *ngIf="CubeInvoicesReport?.length == 0 && !CubeInvoicesReportLoader"
												class="text-center">
												No data found!
											</p>
											<p *ngIf="CubeInvoicesReportLoader" class="text-center text-bold">
												<fa-icon [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
												Loading...
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</mat-expansion-panel> -->
				<mat-expansion-panel (opened)="accordionOpened('CandBillingReport')">
					<mat-expansion-panel-header class="bg-acc_style_2">
						<mat-panel-title>
							<b>{{CandBillingReportHeader}}</b>
						</mat-panel-title>
						<mat-panel-description>
							<button class="btn btn-sm btn-light" matTooltip="Excel" [matTooltipPosition]="'below'"
								(click)="getCandBillingReportExcel($event)">
								<fa-icon *ngIf="!CandBillingReportExcelLoader"
									[icon]="['fas', 'cloud-download-alt']"></fa-icon>
								<fa-icon *ngIf="CandBillingReportExcelLoader" [icon]="['fas', 'spinner']"
									animation="spin"></fa-icon>
							</button>
						</mat-panel-description>
					</mat-expansion-panel-header>
					<div class="row">
						<div class="col-sm-12">
							<div class="row">
								<div class="col-sm-12">
									<div class="table-responsive" style="height: 450px;overflow-y: auto;">
										<table class="myTable table table-bordered table-hover mt-2"
											style="width: 100%;">
											<thead>
												<tr>
													<!-- <th style="width: 7% !important;">Bill No</th>
													<th style="width: 7% !important;">Company</th>
													<th style="width: 7% !important;">Candidate Name</th>
													<th style="width: 7% !important;">Rank</th>
													<th style="width: 7% !important;">Vessel</th>
													<th style="width: 7% !important;">Invoice Date</th>
													<th style="width: 9% !important;">Test</th>
													<th style="width: 7% !important;">Cost</th>
													<th style="width: 7% !important;">Total Amount</th>
													<th style="width: 7% !important;">Paid Amount</th>
													<th style="width: 7% !important;">Net Amount</th>
													<th style="width: 7% !important;">Invoice Sent</th>
													<th style="width: 7% !important;">Currency</th>
													<th style="width: 7% !important;">Doctor</th> -->
													<th style="width: 15% !important;">Case Id</th>
													<th style="width: 15% !important;">Serial No.</th>
													<th style="width: 40% !important;">Name</th>
													<th style="width: 15% !important;">Invoice Date</th>
													<th style="width: 15%;text-align: right;">Amount</th>
												</tr>
											</thead>
											<tbody *ngIf="CandBillingReport?.length != 0">
												<tr class="table_td_style"
													*ngFor="let a of CandBillingReport;let i = index;">
													<!-- <td>{{a.ref_no}}</td>
													<td>{{a.company}}</td>
													<td>{{a.cand_name}}</td>
													<td>{{a.rank}}</td>
													<td>{{a.vessel}}</td>
													<td>{{a.invoice_date}}</td>
													<td>{{a.test}}</td>
													<td>{{a.cost}}</td>
													<td>{{a.total_amount}}</td>
													<td>{{a.paid_amount}}</td>
													<td>{{a.net_amount}}</td>
													<td>{{a.invoice_sent}}</td>
													<td>{{a.currency_type}}</td>
													<td>{{a.doctor}}</td> -->
													<td>{{ a.case_id }}</td>
													<td>{{ a.ref_no }}</td>
													<td>{{ a.cand_name }}</td>
													<td>{{ a.invoice_date }}</td>
													<td style="text-align: right;">
														<span>
															<strong *ngIf="a.currency_type=='SGD'">S</strong>
															<fa-icon [icon]="['fas', 'rupee-sign']"
																*ngIf="a.currency_type=='INR'" class="me-1"></fa-icon>
															<fa-icon [icon]="['fas', 'dollar-sign']"
																*ngIf="a.currency_type=='SGD' || a.currency_type=='USD'"
																class="me-1"></fa-icon>
														</span>
														<span class="mr-sm">{{ a.total_amount }}</span>
													</td>
												</tr>
											</tbody>
										</table>
										<p *ngIf="CandBillingReport?.length == 0 && !CandBillingReportLoader"
											class="text-center">
											No data found!
										</p>
										<p *ngIf="CandBillingReportLoader" class="text-center text-bold">
											<fa-icon [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
											Loading...
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</mat-expansion-panel>
				<mat-expansion-panel (opened)="accordionOpened('StatementReport')">
					<mat-expansion-panel-header class="bg-acc_style_3">
						<mat-panel-title>
							<b>{{StatementReportHeader}}</b>
						</mat-panel-title>
						<mat-panel-description>
							<button class="btn btn-sm btn-light" matTooltip="Excel" [matTooltipPosition]="'below'"
								(click)="getStatementReportExcel($event)">
								<fa-icon *ngIf="!StatementReportExcelLoader"
									[icon]="['fas', 'cloud-download-alt']"></fa-icon>
								<fa-icon *ngIf="StatementReportExcelLoader" [icon]="['fas', 'spinner']"
									animation="spin"></fa-icon>
							</button>
						</mat-panel-description>
					</mat-expansion-panel-header>
					<div class="row">
						<div class="col-sm-12">
							<div class="row">
								<div class="col-sm-12">
									<div class="table-responsive" style="height: 450px;overflow-y: auto;">
										<table class="myTable table table-bordered table-hover mt-2"
											style="width: 100%;">
											<thead>
												<tr>
													<th style="width: 10% !important;">Ref. Case Id</th>
													<th style="width: 15% !important;">Vessel Name</th>
													<th style="width: 10% !important;">Rank</th>
													<th style="width: 10% !important;">Case Start Date</th>
													<th style="width: 10% !important;">Closure Date</th>
													<th style="width: 10% !important;">Days To Recover</th>
													<th style="width: 10% !important;">Case Status</th>
													<th style="width: 10% !important;">Case Category</th>
													<th style="width: 15% !important;">Was Crew Hospitalised</th>
												</tr>
											</thead>
											<tbody *ngIf="StatementReport?.length != 0">
												<tr class="table_td_style"
													*ngFor="let a of StatementReport;let i = index;">
													<td>{{a.case_id}}</td>
													<td>{{a.vessel_name}}</td>
													<td>{{a.rank}}</td>
													<td>{{a.case_start_date}}</td>
													<td>{{a.closure_date}}</td>
													<td>{{a.days_to_recover}}</td>
													<td>{{a.case_status}}</td>
													<td>{{a.case_category}}</td>
													<td>{{a.hospital}}</td>
												</tr>
											</tbody>
										</table>
										<p *ngIf="StatementReport?.length == 0 && !StatementReportLoader"
											class="text-center">
											No data found!
										</p>
										<p *ngIf="StatementReportLoader" class="text-center text-bold">
											<fa-icon [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
											Loading...
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</mat-expansion-panel>
			</mat-accordion>
		</div>
	</div>
	<div class="card mt-1">
		<div class="card-header bg-primary">
			Shore Care Statistics
		</div>
		<div class="card-body">
			<div class="row">
				<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
					<div class="card">
						<div class="card-header">
							<h4 class="dark-color">Active/Closed</h4>
						</div>
						<div class="card-body scroll">
							<p class="text-center" *ngIf="statisticsLoader1">
								<fa-icon [icon]="['fas', 'spinner']" animation="spin" size="3x"></fa-icon>
							</p>
							<div>
								<ngx-charts-bar-vertical-2d *ngIf="!statisticsLoader1" [view]="[400,300]"
									[scheme]="colorSchemeOpenClosed" [results]="statisticsData1" [gradient]="false"
									[xAxis]="true" [yAxis]="true" [legend]="true" [showXAxisLabel]="false"
									[showYAxisLabel]="false" [legendTitle]="''" [legendPosition]="legendPosition">
								</ngx-charts-bar-vertical-2d>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
					<div class="card">
						<div class="card-header">
							<h4 class="dark-color">Hospitalised/OPD</h4>
						</div>
						<div class="card-body scroll">
							<p class="text-center" *ngIf="statisticsLoader2">
								<fa-icon [icon]="['fas', 'spinner']" animation="spin" size="3x"></fa-icon>
							</p>
							<div>
								<ngx-charts-bar-vertical-2d *ngIf="!statisticsLoader2" [view]="[400,300]"
									[scheme]="colorScheme" [results]="statisticsData2" [gradient]="false" [xAxis]="true"
									[yAxis]="true" [legend]="true" [showXAxisLabel]="false" [showYAxisLabel]="false"
									[legendTitle]="''" [legendPosition]="legendPosition">
								</ngx-charts-bar-vertical-2d>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
					<div class="card">
						<div class="card-header">
							<h4 class="dark-color">Group Wise Closed Cases</h4>
						</div>
						<div class="card-body scroll">
							<p class="text-center" *ngIf="statisticsLoader3">
								<fa-icon [icon]="['fas', 'spinner']" animation="spin" size="3x"></fa-icon>
							</p>
							<div>
								<h5 *ngIf="totalCount3!=null"><strong>Total Cases: {{totalCount3}}</strong></h5>
								<ngx-charts-pie-chart [results]="statisticsData3" [view]="[400,300]" [gradient]="false"
									[scheme]="colorScheme" [legend]="true" [legendPosition]="legendPosition"
									[legendTitle]="''" [labels]="false" [doughnut]="true" [labels]="true"
									style="fill: grey;" [arcWidth]="arcWidth" [gradient]="true"
									*ngIf="!statisticsLoader3">
									<ng-template #tooltipTemplate let-model="model">
										<h4 class="my-0">{{model.name}}</h4>
										<h5 class="my-0">{{model.value}}</h5>
									</ng-template>
								</ngx-charts-pie-chart>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row mt-1">
				<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
					<div class="card">
						<div class="card-header">
							<h4 class="dark-color">Group Wise Hospitalised Cases - Illness</h4>
						</div>
						<div class="card-body scroll">
							<p class="text-center" *ngIf="ClosedCasesIllnessLoader">
								<fa-icon [icon]="['fas', 'spinner']" animation="spin" size="3x"></fa-icon>
							</p>
							<div>
								<ngx-charts-bar-vertical-2d *ngIf="!ClosedCasesIllnessLoader" [view]="[400,300]"
									[scheme]="colorScheme" [results]="ClosedCasesIllnessData" [gradient]="false"
									[xAxis]="true" [yAxis]="true" [legend]="true" [showXAxisLabel]="false"
									[showYAxisLabel]="false" [legendTitle]="''" [legendPosition]="legendPosition">
								</ngx-charts-bar-vertical-2d>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
					<div class="card">
						<div class="card-header">
							<h4 class="dark-color">Group Wise Hospitalised Cases - Injury</h4>
						</div>
						<div class="card-body scroll">
							<p class="text-center" *ngIf="ClosedCasesInjuryLoader">
								<fa-icon [icon]="['fas', 'spinner']" animation="spin" size="3x"></fa-icon>
							</p>
							<div>
								<ngx-charts-bar-vertical-2d *ngIf="!ClosedCasesInjuryLoader" [view]="[400,300]"
									[scheme]="colorScheme" [results]="ClosedCasesInjuryData" [gradient]="false"
									[xAxis]="true" [yAxis]="true" [legend]="true" [showXAxisLabel]="false"
									[showYAxisLabel]="false" [legendTitle]="''" [legendPosition]="legendPosition">
								</ngx-charts-bar-vertical-2d>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
					<div class="card">
						<div class="card-header">
							<h4 class="dark-color">Group Wise Hospitalised Cases - Psychology</h4>
						</div>
						<div class="card-body scroll">
							<p class="text-center" *ngIf="ClosedCasesPsychologyLoader">
								<fa-icon [icon]="['fas', 'spinner']" animation="spin" size="3x"></fa-icon>
							</p>
							<div>
								<ngx-charts-bar-vertical-2d *ngIf="!ClosedCasesPsychologyLoader" [view]="[400,300]"
									[scheme]="colorScheme" [results]="ClosedCasesPsychologyData" [gradient]="false"
									[xAxis]="true" [yAxis]="true" [legend]="true" [showXAxisLabel]="false"
									[showYAxisLabel]="false" [legendTitle]="''" [legendPosition]="legendPosition">
								</ngx-charts-bar-vertical-2d>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>
@if (user_type=='cdm') {
@if(UserModuleAccess!='Full'){
<h3 class="text-center">You don't have access to this section.</h3>
}
} @else if(user_type=='company') {
@if(UserModuleAccess!='Full' || isCompanyIdNull){
<h3 class="text-center">Service not subscribed.</h3>
}
} @else if(user_type=='fleet') {
<h3 class="text-center">Service not subscribed.</h3>
}