<breadcrumb [breadcrumbList]="breadcrumbList"></breadcrumb>
<div class="card" *ngIf="UserModuleAccess=='Full'">
	<div class="card-header bg-primary">
		{{pagetitle}}
	</div>
	<div class="card-body">
		<form class="form-horizontal" [formGroup]="moduleForm" (ngSubmit)="saveData()" #modform="ngForm">
			<div class="row mb-3">
				<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
					<custom-input formControlName="name" [id]="'name'" [label]="'Vessel Type'"
						[required]="f.name.hasValidator(requiredValidator)" [hasError]="showError('name')"
						[errors]="f.name.errors" [customError]="customError?.name"></custom-input>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col text-center">
					<button type="submit" [disabled]="showLoader || !modform.dirty"
						class="btn btn-sm btn-outline-success">
						<span class="me-1">
							<fa-icon *ngIf="showLoader" [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
							<fa-icon *ngIf="!showLoader" [icon]="['fas', 'save']"></fa-icon>
						</span>
						<span>Save</span>
					</button>
					<a class="btn btn-sm btn-outline-dark ms-1" [routerLink]="['/app/vessel-type']">Cancel</a>
				</div>
			</div>
		</form>
	</div>
</div>
<div *ngIf="UserModuleAccess!='Full'">
	<h3 class="text-center">You don't have access to this section.</h3>
</div>