import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RefreshComponent } from './refresh/refresh.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppInterceptorService } from './app-interceptor.service';
import { Router } from '@angular/router';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatetimepickerModule } from '@mat-datetimepicker/core';
import { MatNativeDatetimeModule } from '@mat-datetimepicker/core';
import { TraceService, createErrorHandler } from '@sentry/angular-ivy';
import { ResetPasswordNewComponent } from './reset-password-new/reset-password-new.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardInventoryComponent } from './dashboard-inventory/dashboard-inventory.component';
import { DashboardMhsComponent } from './dashboard-mhs/dashboard-mhs.component';
import { DashboardHcComponent } from './dashboard-hc/dashboard-hc.component';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DashboardClipComponent } from './dashboard-clip/dashboard-clip.component';
import { DateFilterComponent } from './date-filter/date-filter.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { UserCreateComponent } from './users/user-create/user-create.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DataTablesModule } from 'angular-datatables';
import { NgToggleModule } from 'ng-toggle-button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { NavbarComponent } from './navbar/navbar.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatChipsModule } from '@angular/material/chips';
import { faStackOverflow, faGithub, faMedium } from '@fortawesome/free-brands-svg-icons';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgbCalendar, NgbDatepickerModule, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { MatPaginatorModule } from '@angular/material/paginator';
import { DatePipe } from '@angular/common';
import {
	faSync,
	faMale,
	faFemale,
	faSquare,
	faCheckSquare,
	faMedkit,
	faAmbulance,
	faBrain,
	faShip,
	faHome,
	faPowerOff,
	faLock,
	faCloudDownloadAlt,
	faSpinner,
	faTh,
	faAngleDown,
	faAngleUp,
	faTimes,
	faUser,
	faUsers,
	faEnvelope,
	faBookMedical,
	faFileMedical,
	faFileMedicalAlt,
	faPumpMedical,
	faNotesMedical,
	faClinicMedical,
	faLaptopMedical,
	faCommentMedical,
	faBriefcaseMedical,
	faHandHoldingMedical,
	faCheck,
	faMinus,
	faCalendar,
	faCalendarAlt,
	faCalendarWeek,
	faFilter,
	faPlus,
	faPencilAlt,
	faTrash,
	faKey,
	faUserAlt,
	faUserPlus,
	faUserCircle,
	faCapsules,
	faTablet,
	faTablets,
	faTabletAlt,
	faSave,
	faSignOutAlt,
	faHelicopter,
	faBuilding,
	faFlag,
	faDatabase,
	faCity,
	faEye,
	faEyeSlash,
	faStar,
	faInfo,
	faInfoCircle,
	faChalkboard,
	faBars,
	faSearch,
	faChartArea,
	faDownload,
	faRupeeSign,
	faDollarSign,
	faPaperPlane,
	faPhoneAlt,
	faPhone,
	faPhoneSquareAlt
} from '@fortawesome/free-solid-svg-icons';
import { MdmUserListComponent } from './users-mdm/mdm-user-list/mdm-user-list.component';
import { MdmUserCreateComponent } from './users-mdm/mdm-user-create/mdm-user-create.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { NgxFileDropModule } from 'ngx-file-drop';
import { QuillModule } from 'ngx-quill';
import { MatMenuModule } from '@angular/material/menu';
import { CompanyListComponent } from './company/company-list/company-list.component';
import { CompanyCreateComponent } from './company/company-create/company-create.component';
import { CountryListComponent } from './country/country-list/country-list.component';
import { CountryCreateComponent } from './country/country-create/country-create.component';
import { DashboardMdmComponent } from './dashboard-mdm/dashboard-mdm.component';
import { FleetListComponent } from './company/fleet-list/fleet-list.component';
import { FleetCreateComponent } from './company/fleet-create/fleet-create.component';
import { RankListComponent } from './rank/rank-list/rank-list.component';
import { RankCreateComponent } from './rank/rank-create/rank-create.component';
import { VesselListComponent } from './vessel/vessel-list/vessel-list.component';
import { VesselCreateComponent } from './vessel/vessel-create/vessel-create.component';
import { PhoneInputComponent } from './shared/phone-input/phone-input.component';
import { CustomInputComponent } from './shared/custom-input/custom-input.component';
import { PocListComponent } from './poc/poc-list/poc-list.component';
import { PocCreateComponent } from './poc/poc-create/poc-create.component';
import { DashboardHealthcubeComponent } from './dashboard-healthcube/dashboard-healthcube.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TrainingListComponent } from './training-master/training-list/training-list.component';
import { TrainingCreateComponent } from './training-master/training-create/training-create.component';
import { OverheadListComponent } from './overhead-master/overhead-list/overhead-list.component';
import { OverheadCreateComponent } from './overhead-master/overhead-create/overhead-create.component';
import { VesselTypeListComponent } from './vessel-type/vessel-type-list/vessel-type-list.component';
import { VesselTypeCreateComponent } from './vessel-type/vessel-type-create/vessel-type-create.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { OwnerListComponent } from './owner/owner-list/owner-list.component';
import { OwnerCreateComponent } from './owner/owner-create/owner-create.component';
import { RMAMasterComponent } from './rma-master/rma-master.component';

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		RefreshComponent,
		ResetPasswordNewComponent,
		DashboardInventoryComponent,
		DashboardMhsComponent,
		DashboardHcComponent,
		DashboardComponent,
		DashboardClipComponent,
		DateFilterComponent,
		UserListComponent,
		UserCreateComponent,
		NavbarComponent,
		MdmUserListComponent,
		MdmUserCreateComponent,
		BreadcrumbComponent,
		CompanyListComponent,
		CompanyCreateComponent,
		CountryListComponent,
		CountryCreateComponent,
		DashboardMdmComponent,
		FleetListComponent,
		FleetCreateComponent,
		RankListComponent,
		RankCreateComponent,
		VesselListComponent,
		VesselCreateComponent,
		PhoneInputComponent,
		CustomInputComponent,
		PocListComponent,
		PocCreateComponent,
		DashboardHealthcubeComponent,
		TrainingListComponent,
		TrainingCreateComponent,
		OverheadListComponent,
		OverheadCreateComponent,
		VesselTypeListComponent,
		VesselTypeCreateComponent,
		OwnerListComponent,
		OwnerCreateComponent,
  RMAMasterComponent
	],
	imports: [
		BrowserModule,
		MatProgressBarModule,
		AppRoutingModule,
		ReactiveFormsModule,
		FormsModule,
		HttpClientModule,
		ToastrModule.forRoot(),
		BrowserAnimationsModule,
		MatRadioModule,
		MatAutocompleteModule,
		AutocompleteLibModule,
		NgbModule,
		MatDatepickerModule,
		MatDatetimepickerModule,
		MatNativeDateModule,
		MatNativeDatetimeModule,
		FontAwesomeModule,
		MatTooltipModule,
		MatExpansionModule,
		MatFormFieldModule,
		MatInputModule,
		MatCardModule,
		MatIconModule,
		MatDividerModule,
		MatButtonModule,
		MatSlideToggleModule,
		MatSelectModule,
		MatTableModule,
		MatProgressSpinnerModule,
		DataTablesModule,
		NgToggleModule,
		MatButtonToggleModule,
		MatToolbarModule,
		NgSelectModule,
		MatCheckboxModule,
		AngularSignaturePadModule,
		NgxFileDropModule,
		QuillModule.forRoot(),
		MatMenuModule,
		NgxChartsModule,
		MatPaginatorModule,
		MatGridListModule,
		NgbDatepickerModule,
		FormsModule,
		MatChipsModule
	],
	providers: [
		{
			provide: ErrorHandler,
			useValue: createErrorHandler({
				showDialog: false,
			}),
		},
		{
			provide: TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => { },
			deps: [TraceService],
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AppInterceptorService,
			multi: true
		},
		MatNativeDatetimeModule,
		DatePipe
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(library: FaIconLibrary) {
		library.addIcons(
			faUserPlus,
			faUserCircle,
			faSync,
			faMale,
			faFemale,
			faStackOverflow,
			faGithub,
			faMedium,
			faSquare,
			faCheckSquare,
			faMedkit,
			faAmbulance,
			faBrain,
			faShip,
			faHome,
			faPowerOff,
			faLock,
			faCloudDownloadAlt,
			faSpinner,
			faTh,
			faAngleDown,
			faAngleUp,
			faTimes,
			faUser,
			faUsers,
			faUserAlt,
			faEnvelope,
			faBookMedical,
			faFileMedical,
			faMedkit,
			faFileMedicalAlt,
			faPumpMedical,
			faNotesMedical,
			faClinicMedical,
			faLaptopMedical,
			faCommentMedical,
			faBriefcaseMedical,
			faHandHoldingMedical,
			faCheck,
			faMinus,
			faCalendar,
			faCalendarAlt,
			faCalendarWeek,
			faFilter,
			faPlus,
			faPencilAlt,
			faTrash,
			faLock,
			faKey,
			faCapsules,
			faTablet,
			faTablets,
			faTabletAlt,
			faSave,
			faSignOutAlt,
			faHelicopter,
			faBuilding,
			faFlag,
			faDatabase,
			faCity,
			faEye,
			faEyeSlash,
			faStar,
			faCalendar,
			faInfo,
			faInfoCircle,
			faChalkboard,
			faBars,
			faSearch,
			faDownload,
			faChartArea,
			faRupeeSign,
			faDollarSign,
			faPaperPlane,
			faPhoneAlt,
			faPhone,
			faPhoneSquareAlt
		);
	}
}
