
import { Subscription, ReplaySubject, Subject, Observable, fromEvent, of } from 'rxjs';
import { Component, OnInit, Input, OnDestroy, ViewChild, AfterViewInit, Inject, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from '@angular/common';
import { ApiService, HttpParamsOptions } from '../../api.service';
import { ToastrService } from 'ngx-toastr';
import { EventEmitter, Output } from '@angular/core';
declare var $: any;
import { debounceTime } from "rxjs/operators";
import { DataTableDirective } from 'angular-datatables';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import * as FileSaver from 'file-saver';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl, ValidatorFn, AbstractControl, ValidationErrors, AsyncValidatorFn } from '@angular/forms';
import { Config } from 'datatables.net';
import Swal from 'sweetalert2';
import { password_validation } from '../../shared/constants';

class DataTablesResponse {
	data!: any[];
	draw!: number;
	recordsFiltered!: number;
	recordsTotal!: number;
}

@Component({
	selector: 'app-user-list',
	templateUrl: './user-list.component.html',
	styleUrl: './user-list.component.css'
})
export class UserListComponent implements OnInit, OnDestroy, AfterViewInit {
	breadcrumbList: any[] = [
		{
			path: this.apiService.getHomeURL(),
			name: "Home"
		},
		{
			path: "/app/users",
			name: "Users"
		}
	];

	@ViewChild(DataTableDirective, { static: false }) datatableElement!: DataTableDirective;
	baseURL = environment.API_URL + "api/";
	dtOptions: Config = {};
	dataList: any = [];
	deleteId: any;
	deleteIndex: any;
	showDeleteLoader: any = null;
	pagetitle = "Users List";
	pageLoading = false;
	allFlagStates: any[] = [];
	flagState: any = null;
	UserModuleAccess: any = "Full";
	passwordId: any;
	resetPasswordForm!: FormGroup;
	showReset2FALoader: any = null;
	user_type: any = "";
	accessLoader = false;

	constructor(private formBuilder: FormBuilder, private apiService: ApiService, private activeRoute: ActivatedRoute, private toastr: ToastrService, private http: HttpClient) {
		this.accessLoader = true;
		this.apiService.getUserAccess().subscribe((data: any) => {
			this.accessLoader = false;
			try {
				this.user_type = data.res[0].user_type;
				let accessObj: any = JSON.parse(data.res[0].UserModuleAccess);
				this.UserModuleAccess = accessObj["USER_Module"];
			} catch (error) { }
		}, (error: any) => {
			this.accessLoader = false;
			this.UserModuleAccess = "NoAccess";
		});
	}

	ngOnInit(): void {
		this.activeRoute.params.subscribe(routeParams => {
			this.initDatatable();
			this.resetPasswordForm = this.formBuilder.group({
				password: ['', [Validators.required, password_validation]],
				confirmPassword: ['', [Validators.required]],
			}, {
				validators: this.passwordMatchValidator.bind(this)
			});
		});
	}

	ngOnDestroy(): void {

	}

	ngAfterViewInit(): void {

	}

	initDatatable() {
		this.dtOptions = {
			pagingType: 'full_numbers',
			serverSide: true,
			processing: true,
			ordering: false,
			ajax: (dataTablesParameters: any, callback) => {
				let data: any = {
					draw: dataTablesParameters.draw,
					start: dataTablesParameters.start,
					length: dataTablesParameters.length
				}
				if (dataTablesParameters.search.value != "") {
					data.searchVal = dataTablesParameters.search.value;
				}
				const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
				this.http.get<DataTablesResponse>(this.baseURL + "CdmUsersApi", { params: new HttpParams(httpParams) }).subscribe((response: any) => {
					this.dataList = response.data;
					callback({
						recordsTotal: response.recordsTotal,
						recordsFiltered: response.recordsFiltered,
						data: []
					});
				}, (error: any) => {
					if (error.status == 401) { return }
					this.toastr.error("Something went wrong.", 'Error!!!', {
						timeOut: 3000,
						tapToDismiss: true,
						closeButton: true
					});
				});
			}
		}
	}

	deleteConfirm(uId: any, index: number) {
		Swal.fire({
			title: 'Warning !!!',
			html: 'Are you sure you want to delete user <p style="text-align:center;font-size: 15px;"><strong>' + this.dataList[index].FirstName + " " + this.dataList[index].LastName + '</strong>?</p>',
			icon: 'warning',
			showCancelButton: true,
			showConfirmButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#dc3545'
		}).then((result) => {
			if (result.value) {
				this.showDeleteLoader = index;
				this.apiService.deleteUser({ id: uId }).subscribe(data => {
					this.showDeleteLoader = null;
					Swal.fire('Success', 'User Deleted Successfully.', 'success');
					this.refreshTable();
				}, (error) => {
					this.showDeleteLoader = null;
					if (error.status == 401) { return }
					if (error.status == 404) {
						Swal.fire('Error', error.error, 'error');
					} else {
						Swal.fire('Error', 'Something went wrong.', 'error');
					}
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}

	refreshTable() {
		this.datatableElement.dtInstance.then((dtInstance) => {
			dtInstance.draw();
		});
	}

	passwordMatchValidator(formGroup: FormGroup) {
		const password = formGroup.get('password');
		const confirmPassword = formGroup.get('confirmPassword');
		return password!.value === confirmPassword!.value ? null : { passwordNotMatch: true };
	}

	get f() { return this.resetPasswordForm.controls; }

	resetPasswordModal(id: any) {
		this.passwordId = id;
		$("#changeUserPasswordModal").modal("show");
	}

	resetPassword() {
		if (this.resetPasswordForm.invalid) {
			return;
		}
		let email: any = "";
		for (let i = 0; i < this.dataList.length; i++) {
			if (this.dataList[i].id == this.passwordId) {
				email = this.dataList[i].Email;
			}
		}
		let data = {
			username: email,
			password: this.f.password.value
		}
		this.apiService.resetPassword(data).subscribe(data => {
			this.clearResetPassword();
			this.toastr.info("Password changed successfully.", 'Success!!!', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
		}, (error) => {
			this.toastr.error(error.error.error, 'Error!', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
		});
	}

	clearResetPassword() {
		$("#changeUserPasswordModal").modal("hide");
		this.f.password.setValue("");
		this.f.confirmPassword.setValue("");
		this.f.password.updateValueAndValidity();
		this.f.confirmPassword.updateValueAndValidity();
		this.resetPasswordForm.markAsUntouched();
		this.resetPasswordForm.reset();
	}

	reset2FA(id: any) {
		Swal.fire({
			title: 'Warning !!!',
			text: 'Are you sure you want to reset 2 factor authentication?',
			icon: 'warning',
			showCancelButton: true,
			showConfirmButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#dc3545'
		}).then((result) => {
			if (result.value) {
				let data = {
					id: id,
				}
				this.showReset2FALoader = id;
				this.apiService.reset2fa(data).subscribe(data => {
					this.showReset2FALoader = null;
					this.toastr.info("Reset Successful.", 'Success!!!', {
						timeOut: 3000,
						tapToDismiss: true,
						closeButton: true
					});
				}, (error) => {
					this.showReset2FALoader = null;
					this.toastr.error(error.error.error, 'Error!', {
						timeOut: 3000,
						tapToDismiss: true,
						closeButton: true
					});
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}
}
