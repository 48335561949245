<breadcrumb [breadcrumbList]="breadcrumbList"></breadcrumb>
<div *ngIf="pageLoading || accessLoader" class="loader text-center">
	<img src="../assets/img/cc_loader.gif" />
</div>
<div class="card" *ngIf="user_type=='cdm'">
	<div class="card-header bg-primary">
		Welcome Mail
	</div>
	<div class="card-body">
		<form class="form-horizontal" [formGroup]="moduleForm" (ngSubmit)="sendMail()" #modform="ngForm">
			<div class="row">
				<div class="col-lg-1 col-md-1 col-sm-12"></div>
				<div class="col-lg-11 col-md-11 col-sm-12">
					<label class="me-5">Vessel: <strong>{{vesselDetails?.name}}</strong></label>
					<label>Email-Id: <strong>{{vesselDetails?.email}}</strong></label>
				</div>
			</div>
			<div class="row mt-3">
				<div class="col-lg-1 col-md-1 col-sm-1">
					<label class="mt-3">CC:</label>
				</div>
				<div class="col-lg-11 col-md-11 col-sm-11">
					<phone-email-input formControlName="cc_emails" [type]="'email'" [id]="'cc_emails'"
						[required]="f1.cc_emails.hasValidator(requiredValidator)" [hasError]="false"
						[errors]="f1.cc_emails.errors"></phone-email-input>
				</div>
			</div>
			<div class="row mt-3">
				<div class="col-lg-1 col-md-1 col-sm-1">
					<label class="mt-3">BCC:</label>
				</div>
				<div class="col-lg-11 col-md-11 col-sm-11">
					<phone-email-input formControlName="bcc_emails" [type]="'email'" [id]="'bcc_emails'"
						[required]="f1.bcc_emails.hasValidator(requiredValidator)" [hasError]="false"
						[errors]="f1.bcc_emails.errors"></phone-email-input>
				</div>
			</div>
			<div class="row mt-3">
				<div class="col-lg-1 col-md-1 col-sm-1">
					<label class="mt-1">
						<span class="text-danger">*</span>
						Subject:
					</label>
				</div>
				<div class="col-lg-11 col-md-11 col-sm-11">
					<input class="form-control" formControlName="subject" id="subject" />
					<p *ngIf="showError('subject')" class="text-danger">
						<span *ngIf="f1.subject.errors?.required">
							Required
						</span>
					</p>
				</div>
			</div>
			<div class="row mt-3">
				<div class="col-lg-1 col-md-1 col-sm-1">
					<label class="mt-1">
						<span class="text-danger">*</span>
						Body:
					</label>
				</div>
				<div class="col-lg-11 col-md-11 col-sm-11">
					<textarea class="form-control" formControlName="mail_content" id="mail_content"
						style="min-height: 200px;"></textarea>
					<p *ngIf="showError('mail_content')" class="text-danger">
						<span *ngIf="f1.mail_content.errors?.required">
							Required
						</span>
					</p>
				</div>
			</div>
			<div class="row mt-3">
				<div class="col-lg-1 col-md-1 col-sm-1">
					<label class="mt-1">
						Attachments:
					</label>
				</div>
				<div class="col-lg-4 col-md-4 col-sm-4">
					<ngx-file-drop dropZoneLabel="Drop file here" browseBtnLabel="Browse file" [multiple]="true"
						(onFileDrop)="uploadFile($event)">
						<ng-template multiple="false" ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
							<div style="text-align: center;">
								<button type="button" class="btn btn-outline-primary btn-sm"
									(click)="openFileSelector()">
									Drop Or Browse file
								</button>
							</div>
						</ng-template>
					</ngx-file-drop>
				</div>
				<div class="col-lg-7 col-md-7 col-sm-7">
					<table class="table table-bordered">
						<tbody>
							<tr>
								<th colspan="3">
									{{files.length}} File{{files.length>1?'s':''}} selected
								</th>
							</tr>
							<tr *ngFor="let att of files;let i = index;">
								<td>{{i+1}}</td>
								<td>{{att.name}}</td>
								<td>
									<fa-icon role="button" [icon]="['fas','times']" class="text-danger"
										(click)="removeFile(i)" matTooltip="Remove"></fa-icon>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<!-- <div class="row mt-3">
				<div class="col-lg-1 col-md-1 col-sm-1"></div>
				<div class="col-lg-11 col-md-11 col-sm-11">
					<ng-select [items]="languages" bindLabel="name" bindValue="val" [clearable]="false"
						id="language" formControlName="language" placeholder="Language*"
						[appearance]="'outline'">
					</ng-select>
					<p *ngIf="showError('language')" class="text-danger">
						<span *ngIf="f1.language.errors?.required">
							Required
						</span>
					</p>
				</div>
			</div> -->
			<div class="row mt-3">
				<div class="col-lg-1 col-md-1 col-sm-1"></div>
				<div class="col-lg-11 col-md-11 col-sm-11">
					<button type="submit" [disabled]="showMailLoader || !modform.dirty"
						class="btn btn-sm btn-outline-success">
						<span class="me-1">
							<fa-icon *ngIf="showMailLoader" [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
							<fa-icon *ngIf="!showMailLoader" [icon]="['fas', 'paper-plane']"></fa-icon>
						</span>
						<span>Send</span>
					</button>
					<a class="btn btn-sm btn-outline-dark ms-1" [routerLink]="['/app/vessel']">Cancel</a>
				</div>
			</div>
		</form>
	</div>
</div>
<div *ngIf="user_type!='cdm'">
	<h3 class="text-center">You don't have access to this section.</h3>
</div>