<breadcrumb [breadcrumbList]="breadcrumbList"></breadcrumb>
<div class="card" *ngIf="UserModuleAccess=='Full'">
	<div class="card-body">
		<h2 class="card-title">
			{{pagetitle}}
			<a class="btn btn-sm btn-outline-success float-end" [routerLink]="['/app/create-owner']">
				<fa-icon [icon]="['fas', 'plus']"></fa-icon>
				Create Owner
			</a>
		</h2>
		<div class="row">
			<div class="col-md-12">
				<div class="table-responsive">
					<table datatable [dtOptions]="dtOptions" class="table table-striped table-bordered table-hover"
						style="width: 100%;">
						<thead>
							<tr class="table_head">
								<th style="width: 25% !important;">Name</th>
								<th style="width: 20% !important;">Email</th>
								<th style="width: 25% !important;">Contact Person</th>
								<th style="width: 20% !important;">Contact Number</th>
								<th style="width: 10% !important;">Action</th>
							</tr>
						</thead>
						<tbody *ngIf="dataList?.length != 0">
							<tr *ngFor="let v of dataList;let i = index;">
								<td>{{ v.name }}</td>
								<td>{{ v.email }}</td>
								<td>{{ v.contact_person }}</td>
								<td>{{ v.contact_no }}</td>
								<td>
									<a class="btn btn-sm btn-outline-primary" matTooltip="Edit" id="btnEdit{{v.id1}}"
										[routerLink]="['/app/edit-owner',v.id1]">
										<fa-icon [icon]="['fas', 'pencil-alt']"></fa-icon>
									</a>
									<button class="btn btn-sm btn-outline-danger ms-1" matTooltip="Delete"
										id="btnDelete{{v.id1}}" (click)="deleteConfirm(v.id1,i)">
										<fa-icon *ngIf="showDeleteLoader != i" [icon]="['fas', 'times']"></fa-icon>
										<fa-icon *ngIf="showDeleteLoader == i" [icon]="['fas', 'spinner']"
											animation="spin"></fa-icon>
									</button>
								</td>
							</tr>
						</tbody>
						<tbody *ngIf="dataList?.length == 0">
							<tr>
								<td colspan="6" class="text-center">No data found!</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</div>
<div *ngIf="UserModuleAccess!='Full'">
	<h3 class="text-center">You don't have access to this section.</h3>
</div>