<div *ngIf="accessLoader" class="loader text-center">
	<img src="../assets/img/cc_loader.gif" />
</div>
<!-- <div class="row">
	<div [routerLink]="[inventoryDashboardUrl]" class="col-lg-3 col-md-3 col-sm-12 mt-1" style="cursor: pointer;">
		<div class="card1 card-shadow bg-success">
			<div class="text-center">
				<fa-icon style="color: #006a39;font-size: 18px;" [icon]="['fas', 'capsules']" size="3x"></fa-icon>
				<h3><strong>PHARMA</strong></h3>
			</div>
		</div>
	</div>
	<div [routerLink]="[mhsDashboardUrl]" class="col-lg-3 col-md-3 col-sm-12 mt-1" style="cursor: pointer;">
		<div class="card1 card-shadow bg-warning">
			<div class="text-center">
				<fa-icon style="font-size: 18px;color: #856300;" [icon]="['fas', 'brain']" size="3x"></fa-icon>
				<h3><strong>MHS</strong></h3>
			</div>
		</div>
	</div>
	<div [routerLink]="[hcDashboardUrl]" class="col-lg-3 col-md-3 col-sm-12 mt-1" style="cursor: pointer;">
		<div class="card1 card-shadow bg-primary">
			<div class="text-center">
				<fa-icon style="font-size: 18px;color: #003685;" [icon]="['fas', 'ship']" size="3x"></fa-icon>
				<h3><strong>HEALTH CUBE</strong></h3>
			</div>
		</div>
	</div>
	<div [routerLink]="[clipDashboardUrl]" class="col-lg-3 col-md-3 col-sm-12 mt-1" style="cursor: pointer;">
		<div class="card1 card-shadow bg-info">
			<div class="text-center">
				<fa-icon style="font-size: 18px;color: #006376;" [icon]="['fas', 'laptop-medical']" size="3x"></fa-icon>
				<h3><strong>CLIP</strong></h3>
			</div>
		</div>
	</div>
</div> -->
<div class="row">
	<div class="col-lg-12 col-md-12 col-sm-12 mt-1">
		<div class="card">
			<div class="card-body">
				<div class="row">
					<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
						<mat-form-field>
							<mat-label>Start Date</mat-label>
							<input matInput [matDatepicker]="start_date_picker_1" [(ngModel)]="cardsFilter.start_date">
							<mat-hint>MM/DD/YYYY</mat-hint>
							<mat-datepicker-toggle matIconSuffix [for]="start_date_picker_1"></mat-datepicker-toggle>
							<mat-datepicker #start_date_picker_1></mat-datepicker>
						</mat-form-field>
					</div>
					<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
						<mat-form-field>
							<mat-label>End Date</mat-label>
							<input matInput [matDatepicker]="end_date_picker_1" [(ngModel)]="cardsFilter.end_date">
							<mat-hint>MM/DD/YYYY</mat-hint>
							<mat-datepicker-toggle matIconSuffix [for]="end_date_picker_1"></mat-datepicker-toggle>
							<mat-datepicker #end_date_picker_1></mat-datepicker>
						</mat-form-field>
					</div>
					<div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 mt-1" *ngIf="user_type=='cdm'">
						<ng-select appearance="outline" appendTo="body" [items]="companyList_dropdown" bindLabel="name"
							[clearable]="true" [multiple]="false" [(ngModel)]="cardsFilter.company_id"
							placeholder="Company" (change)="setCompany(cardsFilter.company_id)">
						</ng-select>
					</div>
					<div class="col-lg-1 col-md-1 col-sm-6 col-xs-6">
						<button type="button" class="btn btn-md btn-success mt-2 radius-50" (click)="getAllCountData();"
							matTooltip="Search">
							<fa-icon [icon]="['fas', 'search']"></fa-icon>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="row">
	<div class="col-lg-3 col-md-3 col-sm-12 mt-1">
		<div class="card">
			<div class="card-header bg-success text-center" [routerLink]="[inventoryDashboardUrl]"
				style="cursor: pointer;" matTooltip="Pharma Dashboard">
				<fa-icon style="color: #006a39;" [icon]="['fas', 'capsules']"></fa-icon>
				Pharma
			</div>
			<div class="card-body">
				@if (user_type=='cdm') {
				@if(UserModuleAccessPharma=='Full'){
				<ng-container [ngTemplateOutlet]="pharmaCardTemplate"></ng-container>
				}
				} @else if(user_type=='company' || user_type=='fleet') {
				@if(UserModuleAccessPharma=='Full' && !isCompanyIdNullPharma){
				<ng-container [ngTemplateOutlet]="pharmaCardTemplate"></ng-container>
				}
				}
				<ng-template #pharmaCardTemplate>
					<table class="table table-striped table-bordered table-hover">
						<tr>
							<td>
								<p class="mb-1 p-0">Total Shipments</p>
								<strong>
									<span *ngIf="!PharmaCountsLoader">{{PharmaCounts?.total_shipment}}</span>
									<span *ngIf="PharmaCountsLoader">
										<fa-icon [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
									</span>
								</strong>
							</td>
						</tr>
						<tr>
							<td>
								<p class="mb-1 p-0">Shipments Delivered</p>
								<strong>
									<span *ngIf="!PharmaCountsLoader">{{PharmaCounts?.shipment_delivered}}</span>
									<span *ngIf="PharmaCountsLoader">
										<fa-icon [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
									</span>
								</strong>
							</td>
						</tr>
						<tr>
							<td>
								<p class="mb-1 p-0">Upcoming Shipments</p>
								<strong>
									<span *ngIf="!PharmaCountsLoader">{{PharmaCounts?.upcoming_shipment}}</span>
									<span *ngIf="PharmaCountsLoader">
										<fa-icon [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
									</span>
								</strong>
							</td>
						</tr>
						<tr>
							<td>
								<p class="mb-1 p-0">&nbsp;</p>
								<strong>&nbsp;</strong>
							</td>
						</tr>
					</table>
				</ng-template>
				@if (user_type=='cdm') {
				@if(UserModuleAccessPharma!='Full'){
				<div style="height: 292px;">
					<h3 class="text-center">You don't have access to this section.</h3>
				</div>
				}
				} @else if(user_type=='company' || user_type=='fleet') {
				@if(UserModuleAccessPharma!='Full' || isCompanyIdNullPharma){
				<div style="height: 292px;">
					<h3 class="text-center">Service not subscribed.</h3>
				</div>
				}
				}
			</div>
		</div>
	</div>
	<div class="col-lg-3 col-md-3 col-sm-12 mt-1">
		<div class="card">
			<div class="card-header bg-warning text-center" [routerLink]="[mhsDashboardUrl]" style="cursor: pointer;"
				matTooltip="Psychometry Dashboard">
				<fa-icon style="color: #856300;" [icon]="['fas', 'brain']"></fa-icon>
				Psychometry
			</div>
			<div class="card-body">
				@if (user_type=='cdm') {
				@if(UserModuleAccessMHS=='Full'){
				<ng-container [ngTemplateOutlet]="mhsCardTemplate"></ng-container>
				}
				} @else if(user_type=='company') {
				@if(UserModuleAccessMHS=='Full' && !isCompanyIdNullMHS){
				<ng-container [ngTemplateOutlet]="mhsCardTemplate"></ng-container>
				}
				}
				<ng-template #mhsCardTemplate>
					<table *ngIf="UserModuleAccessMHS=='Full'" class="table table-striped table-bordered table-hover">
						<tr>
							<td>
								<p class="mb-1 p-0">Total Appointments</p>
								<strong>
									<span *ngIf="!MHSCountsLoader">{{MHSCounts?.total_appointment}}</span>
									<span *ngIf="MHSCountsLoader">
										<fa-icon [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
									</span>
								</strong>
							</td>
						</tr>
						<tr>
							<td>
								<p class="mb-1 p-0">Fit without Restrictions</p>
								<strong>
									<span *ngIf="!MHSCountsLoader">{{MHSCounts?.fit_without_rest}}</span>
									<span *ngIf="MHSCountsLoader">
										<fa-icon [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
									</span>
								</strong>
							</td>
						</tr>
						<tr>
							<td>
								<p class="mb-1 p-0">MSE</p>
								<strong>
									<span *ngIf="!MHSCountsLoader">{{MHSCounts?.mse}}</span>
									<span *ngIf="MHSCountsLoader">
										<fa-icon [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
									</span>
								</strong>
							</td>
						</tr>
						<tr>
							<td>
								<p class="mb-1 p-0">Unfit</p>
								<strong>
									<span *ngIf="!MHSCountsLoader">{{MHSCounts?.unfit}}</span>
									<span *ngIf="MHSCountsLoader">
										<fa-icon [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
									</span>
								</strong>
							</td>
						</tr>
					</table>
				</ng-template>
				@if (user_type=='cdm') {
				@if(UserModuleAccessMHS!='Full'){
				<div style="height: 292px;">
					<h3 class="text-center">You don't have access to this section.</h3>
				</div>
				}
				} @else if(user_type=='company') {
				@if(UserModuleAccessMHS!='Full' || isCompanyIdNullMHS){
				<div style="height: 292px;">
					<h3 class="text-center">Service not subscribed.</h3>
				</div>
				}
				} @else if(user_type=='fleet') {
				<div style="height: 292px;">
					<h3 class="text-center">Service not subscribed.</h3>
				</div>
				}
			</div>
		</div>
	</div>
	<div class="col-lg-3 col-md-3 col-sm-12 mt-1">
		<div class="card">
			<div class="card-header bg-primary text-center" [routerLink]="[hcDashboardUrl]" style="cursor: pointer;"
				matTooltip="RMA Dashboard">
				<fa-icon style="color: #003685;" [icon]="['fas', 'ship']"></fa-icon>
				RMA
			</div>
			<div class="card-body">
				@if (user_type=='cdm') {
				@if(UserModuleAccessHC=='Full'){
				<ng-container [ngTemplateOutlet]="hcCardTemplate"></ng-container>
				}
				} @else if(user_type=='company' || user_type=='fleet') {
				@if(UserModuleAccessHC=='Full' && !isCompanyIdNullHC){
				<ng-container [ngTemplateOutlet]="hcCardTemplate"></ng-container>
				}
				}
				<ng-template #hcCardTemplate>
					<table *ngIf="UserModuleAccessHC=='Full'" class="table table-striped table-bordered table-hover">
						<tr>
							<td>
								<p class="mb-1 p-0">Total Cases</p>
								<strong>
									<span *ngIf="!HCCountsLoader">{{HCCounts?.total_cases}}</span>
									<span *ngIf="HCCountsLoader">
										<fa-icon [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
									</span>
								</strong>
							</td>
						</tr>
						<tr>
							<td>
								<p class="mb-1 p-0">Active</p>
								<strong>
									<span *ngIf="!HCCountsLoader">{{HCCounts?.open_cases}}</span>
									<span *ngIf="HCCountsLoader">
										<fa-icon [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
									</span>
								</strong>
							</td>
						</tr>
						<tr>
							<td>
								<p class="mb-1 p-0">Med. Sign-off</p>
								<strong>
									<span *ngIf="!HCCountsLoader">
										{{HCCounts?.medical_signoff_per}}%({{HCCounts?.medical_signoff}})
									</span>
									<span *ngIf="HCCountsLoader">
										<fa-icon [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
									</span>
								</strong>
							</td>
						</tr>
						<tr>
							<td>
								<p class="mb-1 p-0">Code Blue(Active)</p>
								<strong>
									<span *ngIf="!HCCountsLoader">{{HCCounts?.blue_code}}</span>
									<span *ngIf="HCCountsLoader">
										<fa-icon [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
									</span>
								</strong>
							</td>
						</tr>
					</table>
				</ng-template>
				@if (user_type=='cdm') {
				@if(UserModuleAccessHC!='Full'){
				<div style="height: 292px;">
					<h3 class="text-center">You don't have access to this section.</h3>
				</div>
				}
				} @else if(user_type=='company' || user_type=='fleet') {
				@if(UserModuleAccessHC!='Full' || isCompanyIdNullHC){
				<div style="height: 292px;">
					<h3 class="text-center">Service not subscribed.</h3>
				</div>
				}
				}
			</div>
		</div>
	</div>
	<div class="col-lg-3 col-md-3 col-sm-12 mt-1">
		<div class="card">
			<div class="card-header bg-info text-center" [routerLink]="[clipDashboardUrl]" style="cursor: pointer;"
				matTooltip="Shore Care Dashboard">
				<fa-icon style="color: #006376;" [icon]="['fas', 'laptop-medical']"></fa-icon>
				Shore Care
			</div>
			<div class="card-body">
				@if (user_type=='cdm') {
				@if(UserModuleAccessClip=='Full'){
				<ng-container [ngTemplateOutlet]="clipCardTemplate"></ng-container>
				}
				} @else if(user_type=='company') {
				@if(UserModuleAccessClip=='Full' && !isCompanyIdNullClip){
				<ng-container [ngTemplateOutlet]="clipCardTemplate"></ng-container>
				}
				}
				<ng-template #clipCardTemplate>
					<table *ngIf="UserModuleAccessClip=='Full'" class="table table-striped table-bordered table-hover">
						<tr>
							<td>
								<p class="mb-1 p-0">Total Cases</p>
								<strong>
									<span *ngIf="!CLIPCountsLoader">{{CLIPCounts?.total_cases}}</span>
									<span *ngIf="CLIPCountsLoader">
										<fa-icon [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
									</span>
								</strong>
							</td>
						</tr>
						<!-- <tr>
								<td>
									<p class="mb-1 p-0">Active</p>
									<strong>
										<span *ngIf="!CLIPCountsLoader">{{CLIPCounts?.open_cases}}</span>
										<span *ngIf="CLIPCountsLoader">
											<fa-icon [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
										</span>
									</strong>
								</td>
							</tr> -->
						<tr>
							<td>
								<p class="mb-1 p-0">Fit</p>
								<strong>
									<span *ngIf="!CLIPCountsLoader">{{CLIPCounts?.fit}}</span>
									<span *ngIf="CLIPCountsLoader">
										<fa-icon [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
									</span>
								</strong>
							</td>
						</tr>
						<tr>
							<td>
								<p class="mb-1 p-0">Unfit</p>
								<strong>
									<span *ngIf="!CLIPCountsLoader">{{CLIPCounts?.unfit}}</span>
									<span *ngIf="CLIPCountsLoader">
										<fa-icon [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
									</span>
								</strong>
							</td>
						</tr>
						<tr>
							<td>
								<p class="mb-1 p-0">&nbsp;</p>
								<strong>&nbsp;</strong>
							</td>
						</tr>
					</table>
				</ng-template>
				@if (user_type=='cdm') {
				@if(UserModuleAccessClip!='Full'){
				<div style="height: 292px;">
					<h3 class="text-center">You don't have access to this section.</h3>
				</div>
				}
				} @else if(user_type=='company') {
				@if(UserModuleAccessClip!='Full' || isCompanyIdNullClip){
				<div style="height: 292px;">
					<h3 class="text-center">Service not subscribed.</h3>
				</div>
				}
				} @else if(user_type=='fleet') {
				<div style="height: 292px;">
					<h3 class="text-center">Service not subscribed.</h3>
				</div>
				}
			</div>
		</div>
	</div>
</div>
<div class="row my-3" *ngIf="UserModuleAccess=='Full' && user_type=='cdm'">
	<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
		<div class="card card-default">
			<div class="card-body">
				<div class="row">
					<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
						<mat-form-field class=" me-2" appearance="outline">
							<mat-label>Year</mat-label>
							<mat-select [(value)]="filter.year" (selectionChange)="getAllData()">
								@for (year of yearList; track year) {
								<mat-option [value]="year.value">{{year.name}}</mat-option>
								}
							</mat-select>
						</mat-form-field>
						<mat-form-field class="me-2" appearance="outline">
							<mat-label>Currency</mat-label>
							<mat-select [(value)]="filter.currency" (selectionChange)="getAllData()">
								<mat-option [value]="null">--Select--</mat-option>
								@for (curr of currencyList; track curr) {
								<mat-option [value]="curr.name">{{curr.name}}</mat-option>
								}
							</mat-select>
						</mat-form-field>
						<mat-form-field class="me-2" appearance="outline">
							<mat-label>Payment</mat-label>
							<mat-select [(value)]="filter.is_paid" (selectionChange)="getAllData()">
								<mat-option [value]="null">--Select--</mat-option>
								@for (x of paidUnpaidList; track x) {
								<mat-option [value]="x.id">{{x.name}}</mat-option>
								}
							</mat-select>
						</mat-form-field>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-2 px-1">
						<div class="card card-default">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
										<h3>
											<img src="../assets/img/expenses.png" alt="Expenses icon" class="my-icon" />
											EXPENSES
											<span class="float-end">
												Total: {{formatAmount(totals.total_expense)}}
											</span>
											<button class="btn btn-sm btn-info me-1 float-end" matTooltip="Excel"
												[matTooltipPosition]="'below'" (click)="getExpenseExcel()">
												<fa-icon *ngIf="!expenseExcelLoader"
													[icon]="['fas', 'cloud-download-alt']"></fa-icon>
												<fa-icon *ngIf="expenseExcelLoader" [icon]="['fas', 'spinner']"
													animation="spin"></fa-icon>
											</button>
										</h3>
										<hr
											style="margin-top: 10px;margin-bottom: 10px;border: 0;border-top: 1px solid #8394a97d;" />
										<div class="table-responsive">
											<table *ngIf="TotalExpenseAppWise?.length && !TotalExpenseAppWiseLoader"
												mat-table [dataSource]="TotalExpenseAppWise">
												<ng-container matColumnDef="month">
													<th mat-header-cell *matHeaderCellDef style="width: 20%;"> Month
													</th>
													<td mat-cell *matCellDef="let element"> {{element.month}} </td>
													<th mat-footer-cell *matFooterCellDef> Total </th>
												</ng-container>
												<ng-container matColumnDef="clip_amount">
													<th mat-header-cell *matHeaderCellDef class="text-end"
														style="width: 20%;">
														Shore Care
														<fa-icon [icon]="['fas', 'info']"
															[matTooltip]="'Last updated: '+expense_last_updated.clip_shorecare"
															class="ms-1"></fa-icon>
													</th>
													<td mat-cell *matCellDef="let element" class="text-end">
														{{formatAmount(element.clip_amount)}}
													</td>
													<th mat-footer-cell *matFooterCellDef class="text-end">
														{{formatAmount(totals.total_clip_expense)}} </th>
												</ng-container>
												<ng-container matColumnDef="overhead_amount">
													<th mat-header-cell *matHeaderCellDef class="text-end"
														style="width: 20%;">
														Overheads
													</th>
													<td mat-cell *matCellDef="let element" class="text-end">
														{{formatAmount(element.overhead_amount)}}
													</td>
													<th mat-footer-cell *matFooterCellDef class="text-end">
														{{formatAmount(totals.total_overhead_expense)}} </th>
												</ng-container>
												<ng-container matColumnDef="pharma_amount">
													<th mat-header-cell *matHeaderCellDef class="text-end"
														style="width: 20%;">
														Pharma (Purchase)
														<fa-icon [icon]="['fas', 'info']"
															[matTooltip]="'Last updated: '+expense_last_updated.pharma_purchase"
															class="ms-1"></fa-icon>
													</th>
													<td mat-cell *matCellDef="let element" class="text-end">
														{{formatAmount(element.pharma_amount)}}
														<ng-container *ngIf="element.pharma_amount">
															({{element.pharma_count}})
														</ng-container>
													</td>
													<th mat-footer-cell *matFooterCellDef class="text-end">
														{{formatAmount(totals.total_pharma_expense)}} </th>
												</ng-container>
												<ng-container matColumnDef="freight_amount">
													<th mat-header-cell *matHeaderCellDef class="text-end"
														style="width: 20%;">
														Pharma (Vendor)
														<fa-icon [icon]="['fas', 'info']"
															[matTooltip]="'Last updated: '+expense_last_updated.pharma_freight"
															class="ms-1"></fa-icon>
													</th>
													<td mat-cell *matCellDef="let element" class="text-end">
														{{formatAmount(element.freight_amount)}}
														<ng-container *ngIf="element.freight_amount">
															({{element.freight_count}})
														</ng-container>
													</td>
													<th mat-footer-cell *matFooterCellDef class="text-end">
														{{formatAmount(totals.total_freight_expense)}} </th>
												</ng-container>
												<tr mat-header-row *matHeaderRowDef="columnsTotalExpenseAppWise"></tr>
												<tr mat-row *matRowDef="let row; columns: columnsTotalExpenseAppWise;">
												</tr>
												<tr mat-footer-row *matFooterRowDef="columnsTotalExpenseAppWise"></tr>
											</table>
											<p *ngIf="TotalExpenseAppWise?.length == 0 && !TotalExpenseAppWiseLoader"
												class="text-center">
												No data found!
											</p>
											<p *ngIf="TotalExpenseAppWiseLoader" class="text-center text-bold">
												<mat-spinner style="margin: 0 auto;"></mat-spinner>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-2 px-1">
						<div class="card card-default">
							<div class="card-body">
								<div class="row">
									<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
										<h3>
											<img src="../assets/img/sales.png" alt="Sales icon" class="my-icon" />
											SALES
											<span class="float-end">
												<button class="btn btn-sm btn-info me-1" matTooltip="Excel"
													[matTooltipPosition]="'below'" (click)="getSalesExcel()">
													<fa-icon *ngIf="!salesExcelLoader"
														[icon]="['fas', 'cloud-download-alt']"></fa-icon>
													<fa-icon *ngIf="salesExcelLoader" [icon]="['fas', 'spinner']"
														animation="spin"></fa-icon>
												</button>
												Total: {{formatAmount(totals.total_sales)}}
											</span>
										</h3>
										<hr
											style="margin-top: 10px;margin-bottom: 10px;border: 0;border-top: 1px solid #8394a97d;" />
										<div class="table-responsive">
											<table *ngIf="TotalSalesAppWise?.length && !TotalSalesAppWiseLoader"
												mat-table [dataSource]="TotalSalesAppWise">
												<ng-container matColumnDef="month">
													<th mat-header-cell *matHeaderCellDef style="width: 10%;"> Month
													</th>
													<td mat-cell *matCellDef="let element"> {{element.month}} </td>
													<th mat-footer-cell *matFooterCellDef> Total </th>
												</ng-container>
												<ng-container matColumnDef="shorecare_amount">
													<th mat-header-cell *matHeaderCellDef style="width: 13%;"
														class="text-end">
														Shore Care
														<fa-icon [icon]="['fas', 'info']"
															[matTooltip]="'Last updated: '+sales_last_updated.clip_shorecare"
															class="ms-1"></fa-icon>
													</th>
													<td mat-cell *matCellDef="let element" class="text-end">
														{{formatAmount(element.shorecare_amount)}}
													</td>
													<th mat-footer-cell *matFooterCellDef class="text-end">
														{{formatAmount(totals.total_clip_shorecare)}} </th>
												</ng-container>
												<ng-container matColumnDef="training_amount">
													<th mat-header-cell *matHeaderCellDef style="width: 13%;"
														class="text-end">
														Training
													</th>
													<td mat-cell *matCellDef="let element" class="text-end">
														{{formatAmount(element.training_amount)}}
													</td>
													<th mat-footer-cell *matFooterCellDef class="text-end">
														{{formatAmount(totals.total_training_sales)}} </th>
												</ng-container>
												<ng-container matColumnDef="clip_amount">
													<th mat-header-cell *matHeaderCellDef style="width: 13%;"
														class="text-end">
														CLIP
														<fa-icon [icon]="['fas', 'info']"
															[matTooltip]="'Last updated: '+sales_last_updated.clip_subscribe"
															class="ms-1"></fa-icon>
													</th>
													<td mat-cell *matCellDef="let element" class="text-end">
														{{formatAmount(element.clip_amount)}}
													</td>
													<th mat-footer-cell *matFooterCellDef class="text-end">
														{{formatAmount(totals.total_clip_sales)}} </th>
												</ng-container>
												<ng-container matColumnDef="pharma_amount">
													<th mat-header-cell *matHeaderCellDef style="width: 13%;"
														class="text-end">
														Pharma
														<fa-icon [icon]="['fas', 'info']"
															[matTooltip]="'Last updated: '+sales_last_updated.pharma_shipment"
															class="ms-1"></fa-icon>
													</th>
													<td mat-cell *matCellDef="let element" class="text-end">
														{{formatAmount(element.pharma_amount)}}
														<ng-container *ngIf="element.pharma_amount">
															({{element.pharma_count}})
														</ng-container>
													</td>
													<th mat-footer-cell *matFooterCellDef class="text-end">
														{{formatAmount(totals.total_pharma_sales)}} </th>
												</ng-container>
												<ng-container matColumnDef="mhs_amount">
													<th mat-header-cell *matHeaderCellDef style="width: 13%;"
														class="text-end">
														MHS
														<fa-icon [icon]="['fas', 'info']"
															[matTooltip]="'Last updated: '+sales_last_updated.mhs_bill"
															class="ms-1"></fa-icon>
													</th>
													<td mat-cell *matCellDef="let element" class="text-end">
														{{formatAmount(element.mhs_amount)}}
														<ng-container *ngIf="element.mhs_amount">
															({{element.mhs_count}})
														</ng-container>
													</td>
													<th mat-footer-cell *matFooterCellDef class="text-end">
														{{formatAmount(totals.total_mhs_sales)}} </th>
												</ng-container>
												<ng-container matColumnDef="hc_amount">
													<th mat-header-cell *matHeaderCellDef style="width: 13%;"
														class="text-end">
														RMA
														<fa-icon [icon]="['fas', 'info']"
															[matTooltip]="'Last updated: '+sales_last_updated.hc_bill"
															class="ms-1"></fa-icon>
													</th>
													<td mat-cell *matCellDef="let element" class="text-end">
														{{formatAmount(element.hc_amount)}}
													</td>
													<th mat-footer-cell *matFooterCellDef class="text-end">
														{{formatAmount(totals.total_hc_sales)}} </th>
												</ng-container>
												<ng-container matColumnDef="tally_amount">
													<th mat-header-cell *matHeaderCellDef style="width: 12%;"
														class="text-end">
														Tally
													</th>
													<td mat-cell *matCellDef="let element" class="text-end">
														{{formatAmount(element.tally_amount)}}
													</td>
													<th mat-footer-cell *matFooterCellDef class="text-end">
														{{formatAmount(totals.total_tally_sales)}} </th>
												</ng-container>
												<tr mat-header-row *matHeaderRowDef="columnsTotalSalesAppWise"></tr>
												<tr mat-row *matRowDef="let row; columns: columnsTotalSalesAppWise;">
												</tr>
												<tr mat-footer-row *matFooterRowDef="columnsTotalSalesAppWise"></tr>
											</table>
											<p *ngIf="TotalSalesAppWise?.length == 0 && !TotalSalesAppWiseLoader"
												class="text-center">
												No data found!
											</p>
											<p *ngIf="TotalSalesAppWiseLoader" class="text-center text-bold">
												<mat-spinner style="margin: 0 auto;"></mat-spinner>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="row my-3" *ngIf="user_type=='company' || user_type=='fleet'">
	<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
		<div class="card">
			<div class="card-header bg-secondary">
				Vessels
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-lg-3 col-md-3 col-sm-6 col-xs-6 mt-1">
						<ng-select appearance="outline" appendTo="body" [items]="platform_dropdown" bindLabel="name"
							bindValue="val" [clearable]="false" [multiple]="false" [(ngModel)]="cardsFilter.platform"
							placeholder="Platform" (change)="refreshTable();">
						</ng-select>
					</div>
					<div class="col-lg-3 col-md-3 col-sm-6 col-xs-6 mt-1"></div>
					<div class="col-lg-3 col-md-3 col-sm-6 col-xs-6 mt-1"></div>
					<div class="col-lg-3 col-md-3 col-sm-6 col-xs-6 mt-1">
						<button class="btn btn-sm btn-info float-end me-1" (click)="downloadExcel()">
							<fa-icon [icon]="['fas', 'spinner']" [animation]="'spin'" *ngIf="excelLoader"></fa-icon>
							<fa-icon [icon]="['fas', 'cloud-download-alt']" *ngIf="!excelLoader"></fa-icon>
							Download Excel
						</button>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
						<div class="table-responsive">
							<table datatable [dtOptions]="dtOptions"
								class="vessel-table table table-striped table-bordered table-hover"
								style="width: 100%;">
								<thead>
									<tr class="table_head">
										<th style="width: 20% !important;">Company</th>
										<th style="width: 20% !important;">Fleet</th>
										<th style="width: 20% !important;">Vessel</th>
										<th style="width: 15% !important;">Type</th>
										<th style="width: 25% !important;">Email</th>
									</tr>
								</thead>
								<tbody *ngIf="dataList?.length != 0">
									<tr *ngFor="let v of dataList;let i = index;">
										<td>{{ v.company?v.company.name:"-" }}</td>
										<td>{{ v.fleet?v.fleet.name:"-" }}</td>
										<td>{{ v.name }}</td>
										<td>{{ v.type }}</td>
										<td>
											<span [matTooltip]="v.email">
												{{ clipText(v.email,40) }}
											</span>
										</td>
									</tr>
								</tbody>
								<tbody *ngIf="dataList?.length == 0">
									<tr>
										<td colspan="5">
											<p style="text-align: center !important;">No data found!</p>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- <div *ngIf="UserModuleAccess!='Full'">
	<h3 class="text-center">You don't have access to this section.</h3>
</div> -->