<breadcrumb [breadcrumbList]="breadcrumbList"></breadcrumb>
<div *ngIf="accessLoader" class="loader text-center">
	<img src="../assets/img/cc_loader.gif" />
</div>
<div class="card" *ngIf="UserModuleAccess=='Full' && user_type=='cdm'">
	<div class="card-header bg-primary">
		{{pagetitle}}
	</div>
	<div class="card-body">
		<form class="form-horizontal" [formGroup]="moduleForm" (ngSubmit)="saveData()" #modform="ngForm">
			<div class="row mb-3">
				<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
					<div class="form-floating">
						<input class="form-control" [ngClass]="{'is-invalid':showError('name')}" id="name"
							formControlName="name" placeholder="Name*">
						<label for="name">Name*</label>
					</div>
					<p *ngIf="showError('name')" class="text-danger">
						<span *ngIf="f.name.errors?.required">
							Required
						</span>
						<span *ngIf="f.name.errors?.incorrect">
							{{customError.name}}
						</span>
					</p>
				</div>
				<div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
					<div class="form-floating">
						<input class="form-control" [ngClass]="{'is-invalid':showError('description')}" id="description"
							formControlName="description" placeholder="Description*">
						<label for="description">Description*</label>
					</div>
					<p *ngIf="showError('description')" class="text-danger">
						<span *ngIf="f.description.errors?.required">
							Required
						</span>
						<span *ngIf="f.description.errors?.incorrect">
							{{customError.description}}
						</span>
					</p>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col text-center">
					<button type="submit" [disabled]="showLoader || !modform.dirty"
						class="btn btn-sm btn-outline-success">
						<span class="me-1">
							<fa-icon *ngIf="showLoader" [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
							<fa-icon *ngIf="!showLoader" [icon]="['fas', 'save']"></fa-icon>
						</span>
						<span>Save</span>
					</button>
					<a class="btn btn-sm btn-outline-dark ms-1" [routerLink]="['/app/rank']">Cancel</a>
				</div>
			</div>
		</form>
	</div>
</div>
<div *ngIf="UserModuleAccess!='Full' || user_type!='cdm'">
	<h3 class="text-center">You don't have access to this section.</h3>
</div>