import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { NavbarComponent } from './navbar/navbar.component';
import { childTokenGuard } from './Guards/child-token.guard';
import { RefreshComponent } from './refresh/refresh.component';
import { ResetPasswordNewComponent } from './reset-password-new/reset-password-new.component';
import { DashboardInventoryComponent } from './dashboard-inventory/dashboard-inventory.component';
import { DashboardMhsComponent } from './dashboard-mhs/dashboard-mhs.component';
import { DashboardHcComponent } from './dashboard-hc/dashboard-hc.component';
import { DashboardHealthcubeComponent } from './dashboard-healthcube/dashboard-healthcube.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardClipComponent } from './dashboard-clip/dashboard-clip.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { UserCreateComponent } from './users/user-create/user-create.component';
import { MdmUserListComponent } from './users-mdm/mdm-user-list/mdm-user-list.component';
import { MdmUserCreateComponent } from './users-mdm/mdm-user-create/mdm-user-create.component';
import { CompanyListComponent } from './company/company-list/company-list.component';
import { CompanyCreateComponent } from './company/company-create/company-create.component';
import { CountryListComponent } from './country/country-list/country-list.component';
import { CountryCreateComponent } from './country/country-create/country-create.component';
import { DashboardMdmComponent } from './dashboard-mdm/dashboard-mdm.component';
import { FleetListComponent } from './company/fleet-list/fleet-list.component';
import { FleetCreateComponent } from './company/fleet-create/fleet-create.component';
import { RankListComponent } from './rank/rank-list/rank-list.component';
import { RankCreateComponent } from './rank/rank-create/rank-create.component';
import { VesselListComponent } from './vessel/vessel-list/vessel-list.component';
import { VesselCreateComponent } from './vessel/vessel-create/vessel-create.component';
import { PocListComponent } from './poc/poc-list/poc-list.component';
import { PocCreateComponent } from './poc/poc-create/poc-create.component';
import { TrainingListComponent } from './training-master/training-list/training-list.component';
import { TrainingCreateComponent } from './training-master/training-create/training-create.component';
import { OverheadListComponent } from './overhead-master/overhead-list/overhead-list.component';
import { OverheadCreateComponent } from './overhead-master/overhead-create/overhead-create.component';
import { VesselTypeListComponent } from './vessel-type/vessel-type-list/vessel-type-list.component';
import { VesselTypeCreateComponent } from './vessel-type/vessel-type-create/vessel-type-create.component';
import { OwnerListComponent } from './owner/owner-list/owner-list.component';
import { OwnerCreateComponent } from './owner/owner-create/owner-create.component';
import { RMAMasterComponent } from './rma-master/rma-master.component';
import { InvoiceListComponent } from './invoice/invoice-list/invoice-list.component';
import { InvoiceCreateComponent } from './invoice/invoice-create/invoice-create.component';
import { WelcomeMailComponent } from './vessel/welcome-mail/welcome-mail.component';

const routes: Routes = [
	{ path: '', redirectTo: '/login', pathMatch: 'full' },
	{ path: 'refresh', component: RefreshComponent },
	{ path: 'login', component: LoginComponent },
	{ path: 'forgot-password', component: ResetPasswordNewComponent },
	{
		path: 'app', component: NavbarComponent, canActivate: [childTokenGuard], children: [
			{ path: 'dashboard', component: DashboardComponent, data: { animation: 2 } },
			{ path: 'dashboard-mdm', component: DashboardMdmComponent, data: { animation: 3 } },
			{ path: 'dashboard-inventory', component: DashboardInventoryComponent, data: { animation: 4 } },
			{ path: 'dashboard-mhs', component: DashboardMhsComponent, data: { animation: 5 } },
			{ path: 'dashboard-hc', component: DashboardHcComponent, data: { animation: 6 } },

			{ path: 'dashboard-clip', component: DashboardClipComponent, data: { animation: 7 } },

			{ path: 'users', component: UserListComponent, data: { animation: 8 } },
			{ path: 'create-user', component: UserCreateComponent, data: { animation: 9 } },
			{ path: 'edit-user/:uId', component: UserCreateComponent, data: { animation: 10 } },

			{ path: 'mdm-users', component: MdmUserListComponent, data: { animation: 11 } },
			{ path: 'mdm-create-user', component: MdmUserCreateComponent, data: { animation: 12 } },
			{ path: 'mdm-edit-user/:uId', component: MdmUserCreateComponent, data: { animation: 13 } },

			{ path: 'company', component: CompanyListComponent, data: { animation: 14 } },
			{ path: 'create-company', component: CompanyCreateComponent, data: { animation: 15 } },
			{ path: 'edit-company/:uId', component: CompanyCreateComponent, data: { animation: 16 } },

			{ path: 'fleet', component: FleetListComponent, data: { animation: 17 } },
			{ path: 'create-fleet', component: FleetCreateComponent, data: { animation: 18 } },
			{ path: 'edit-fleet/:uId', component: FleetCreateComponent, data: { animation: 19 } },

			{ path: 'country', component: CountryListComponent, data: { animation: 20 } },
			{ path: 'create-country', component: CountryCreateComponent, data: { animation: 21 } },
			{ path: 'edit-country/:uId', component: CountryCreateComponent, data: { animation: 22 } },

			{ path: 'rank', component: RankListComponent, data: { animation: 23 } },
			{ path: 'create-rank', component: RankCreateComponent, data: { animation: 24 } },
			{ path: 'edit-rank/:uId', component: RankCreateComponent, data: { animation: 25 } },

			{ path: 'vessel', component: VesselListComponent, data: { animation: 26 } },
			{ path: 'create-vessel', component: VesselCreateComponent, data: { animation: 27 } },
			{ path: 'edit-vessel/:uId', component: VesselCreateComponent, data: { animation: 28 } },

			{ path: 'poc', component: PocListComponent, data: { animation: 29 } },
			{ path: 'create-poc', component: PocCreateComponent, data: { animation: 30 } },
			{ path: 'edit-poc/:uId', component: PocCreateComponent, data: { animation: 31 } },

			{ path: 'dashboard-healthcube', component: DashboardHealthcubeComponent, data: { animation: 32 } },

			{ path: 'training', component: TrainingListComponent, data: { animation: 33 } },
			{ path: 'create-training', component: TrainingCreateComponent, data: { animation: 34 } },
			{ path: 'edit-training/:uId', component: TrainingCreateComponent, data: { animation: 35 } },

			{ path: 'overhead', component: OverheadListComponent, data: { animation: 36 } },
			{ path: 'create-overhead', component: OverheadCreateComponent, data: { animation: 37 } },
			{ path: 'edit-overhead/:uId', component: OverheadCreateComponent, data: { animation: 38 } },

			{ path: 'vessel-type', component: VesselTypeListComponent, data: { animation: 39 } },
			{ path: 'create-vessel-type', component: VesselTypeCreateComponent, data: { animation: 40 } },
			{ path: 'edit-vessel-type/:uId', component: VesselTypeCreateComponent, data: { animation: 41 } },

			{ path: 'owners', component: OwnerListComponent, data: { animation: 42 } },
			{ path: 'create-owner', component: OwnerCreateComponent, data: { animation: 43 } },
			{ path: 'edit-owner/:uId', component: OwnerCreateComponent, data: { animation: 44 } },

			{ path: 'rma-master', component: RMAMasterComponent, data: { animation: 45 } },

			{ path: 'invoices', component: InvoiceListComponent, data: { animation: 46 } },
			{ path: 'create-invoice', component: InvoiceCreateComponent, data: { animation: 47 } },
			{ path: 'edit-invoice/:uId', component: InvoiceCreateComponent, data: { animation: 48 } },

			{ path: 'welcome-mail/:vesselId', component: WelcomeMailComponent, data: { animation: 49 } },
		]
	},
	{ path: '**', redirectTo: '/login', pathMatch: 'full' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
