import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import * as FileSaver from 'file-saver';
import { LegendPosition } from '@swimlane/ngx-charts';

@Component({
	selector: 'app-dashboard-clip',
	templateUrl: './dashboard-clip.component.html',
	styleUrls: ['./dashboard-clip.component.scss', '../../assets/styles/dashboard-styles.scss']
})
export class DashboardClipComponent implements OnInit, AfterViewInit, OnDestroy {
	UserModuleAccess: any = "Full";
	user_type: any = "";
	isCompanyIdNull = false;
	accessLoader = false;
	constructor(private apiService: ApiService, private activeRoute: ActivatedRoute, private toastr: ToastrService) { }
	ngOnInit(): void {
		this.activeRoute.params.subscribe(routeParams => {
			this.accessLoader = true;
			this.apiService.getUserAccess().subscribe((data: any) => {
				this.accessLoader = false;
				try {
					this.user_type = data.res[0].user_type;
					let accessObj: any = JSON.parse(data.res[0].UserModuleAccess);
					this.UserModuleAccess = accessObj["CLIP_dashboard"];
					if (this.user_type != 'cdm') {
						let company = data.res[0].company_id[0];
						this.cardsFilter.company_id = company;
						this.isCompanyIdNull = company.clip_id ? false : true;
						this.getStatisticsData();
					} else {
						if (sessionStorage.getItem("selectedCompanyFilter")) {
							try {
								let obj = JSON.parse(sessionStorage.getItem("selectedCompanyFilter")!);
								if (obj.clip_id) {
									this.cardsFilter.company_id = obj;
								}
							} catch (error) { }
						}
						this.get_company_dropdown();
						this.getStatisticsData();
					}
				} catch (error) { }
			}, (error: any) => {
				this.accessLoader = false;
				this.UserModuleAccess = "NoAccess";
			});
		});
	}
	ngAfterViewInit(): void { }
	ngOnDestroy(): void { }
	pagetitle = "Shore Care";
	breadcrumbList: any[] = [
		{
			path: this.apiService.getHomeURL(),
			name: "Home"
		},
		{
			path: this.apiService.getDashboardURL("clip"),
			name: this.pagetitle
		}
	];
	cardsFilter: any = {
		company_id: null,
		start_date: this.apiService.getDefaultDateRange1Month(false).start_date,
		end_date: this.apiService.getDefaultDateRange1Month(false).end_date
	}
	currentAccordion = "InvoiceModuleAlerts";
	filterChanged() {
		this.accordionOpened(this.currentAccordion);
		this.getStatisticsData();
	}
	accordionOpened(id: any) {
		this.currentAccordion = id;
		if (id == 'CubeInvoicesReport') {
			/* this.getCubeInvoicesReport(); */
		} else if (id == 'CandBillingReport') {
			this.getCandBillingReport();
		} else if (id == 'StatementReport') {
			this.getStatementReport();
		}
	}

	/* CubeInvoicesReport: any[] = [];
	CubeInvoicesReportLoader = false;
	CubeInvoicesReportExcelLoader = false;
	CubeInvoicesReportExcelName = "Subscribe Report.xlsx";
	CubeInvoicesReportHeader = "Subscribe Report";
	getCubeInvoicesReport() {
		this.CubeInvoicesReportLoader = true;
		this.apiService.simpleGetAPICall("CubeInvoicesReport", { start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date), end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date) }).subscribe((data: any) => {
			this.CubeInvoicesReportLoader = false;
			this.CubeInvoicesReport = data;
		}, (error) => {
			this.CubeInvoicesReportLoader = false;
		});
	}
	getCubeInvoicesReportExcel(e: any) {
		e.stopPropagation();
		this.CubeInvoicesReportExcelLoader = true;
		this.apiService.simpleGetAPICallArrayBuffer("CubeInvoicesReportExcelDownload", { start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date), end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date) }).subscribe(res => {
			let excelData: any = res.body;
			this.CubeInvoicesReportExcelLoader = false;
			let blob = new Blob([excelData], { type: "application/ms-excel" });
			let fileName = this.CubeInvoicesReportExcelName;
			FileSaver.saveAs(blob, fileName);
		}, (error) => {
			this.CubeInvoicesReportExcelLoader = false;
			if (error.status == 401) { return }
			this.toastr.error("Unable to download excel", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
		});
	} */

	CandBillingReport: any[] = [];
	CandBillingReportLoader = false;
	CandBillingReportExcelLoader = false;
	CandBillingReportExcelName = "Shore Care Report.xlsx";
	CandBillingReportHeader = "Shore Care Report";
	getCandBillingReport() {
		let payload: any = {
			company_id: this.cardsFilter.company_id ? this.cardsFilter.company_id.clip_id : null,
			start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date),
			end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date)
		}
		this.CandBillingReportLoader = true;
		this.apiService.simpleGetAPICall("CandBillingReport", payload).subscribe((data: any) => {
			this.CandBillingReportLoader = false;
			this.CandBillingReport = data;
		}, (error) => {
			this.CandBillingReportLoader = false;
		});
	}
	getCandBillingReportExcel(e: any) {
		e.stopPropagation();
		let payload: any = {
			company_id: this.cardsFilter.company_id ? this.cardsFilter.company_id.clip_id : null,
			start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date),
			end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date)
		}
		this.CandBillingReportExcelLoader = true;
		this.apiService.simpleGetAPICallArrayBuffer("CandBillingReportExcelDownload", payload).subscribe(res => {
			let excelData: any = res.body;
			this.CandBillingReportExcelLoader = false;
			let blob = new Blob([excelData], { type: "application/ms-excel" });
			let fileName = this.CandBillingReportExcelName;
			FileSaver.saveAs(blob, fileName);
		}, (error) => {
			this.CandBillingReportExcelLoader = false;
			if (error.status == 401) { return }
			this.toastr.error("Unable to download excel", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
		});
	}

	StatementReport: any[] = [];
	StatementReportLoader = false;
	StatementReportExcelLoader = false;
	StatementReportExcelName = "Statement Report.xlsx";
	StatementReportHeader = "Statement Report";
	getStatementReport() {
		let payload: any = {
			company_id: this.cardsFilter.company_id ? this.cardsFilter.company_id.clip_id : null,
			start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date),
			end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date)
		}
		this.StatementReportLoader = true;
		this.apiService.simpleGetAPICall("StatementReport", payload).subscribe((data: any) => {
			this.StatementReportLoader = false;
			this.StatementReport = data.data;
		}, (error) => {
			this.StatementReportLoader = false;
		});
	}
	getStatementReportExcel(e: any) {
		e.stopPropagation();
		let payload: any = {
			company_id: this.cardsFilter.company_id ? this.cardsFilter.company_id.clip_id : null,
			start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date),
			end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date)
		}
		this.StatementReportExcelLoader = true;
		this.apiService.simpleGetAPICallArrayBuffer("StatementReportExcelDownload", payload).subscribe(res => {
			let excelData: any = res.body;
			this.StatementReportExcelLoader = false;
			let blob = new Blob([excelData], { type: "application/ms-excel" });
			let fileName = this.StatementReportExcelName;
			FileSaver.saveAs(blob, fileName);
		}, (error) => {
			this.StatementReportExcelLoader = false;
			if (error.status == 401) { return }
			this.toastr.error("Unable to download excel", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
		});
	}

	companyList_dropdown: any = [];
	get_company_dropdown() {
		this.apiService.getCompanyDetails({}).subscribe((data: any) => {
			data.data = data.data.filter((comp: any) => comp.clip_id)
			this.companyList_dropdown = data.data;
		}, (error) => {
			this.companyList_dropdown = [];
		});
	}

	setCompany(comp: any) {
		if (comp) {
			sessionStorage.setItem("selectedCompanyFilter", JSON.stringify(comp));
		} else {
			sessionStorage.removeItem("selectedCompanyFilter");
		}
	}

	getStatisticsData() {
		this.getStatistics1();
		this.getStatistics2();
		this.getStatistics3();
		this.getClosedCasesIllnessData();
		this.getClosedCasesInjuryData();
		this.getClosedCasesPsychologyData();
	}

	colorScheme: any = {
		domain: ['#DC3545', '#177B4C', '#f72119', '#ffa01b', '#690000']
	};
	colorSchemeOpenClosed: any = {
		domain: ['#ffa01b', '#0D6EFD']
	};
	legendPosition: LegendPosition = LegendPosition.Below;
	arcWidth = 0.25;

	statisticsLoader1 = false;
	statisticsData1: any = [];
	getStatistics1() {
		let payload: any = {
			company_id: this.cardsFilter.company_id ? this.cardsFilter.company_id.clip_id : null,
			start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date),
			end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date)
		}
		this.statisticsLoader1 = true;
		this.apiService.ShoreCareStatistics1(payload).subscribe((data: any) => {
			try {
				this.statisticsData1 = [
					{
						"name": "Illness",
						"series": [
							{
								"name": "Active",
								"value": data[0].ill_open
							},
							{
								"name": "Close",
								"value": data[0].ill_close
							}
						]
					},
					{
						"name": "Injury",
						"series": [
							{
								"name": "Active",
								"value": data[1].inj_open,
							},
							{
								"name": "Close",
								"value": data[1].inj_close
							}
						]
					},
					{
						"name": "Psychology",
						"series": [
							{
								"name": "Active",
								"value": data[2].psy_open
							},
							{
								"name": "Close",
								"value": data[2].psy_close
							}
						]
					},
					{
						"name": "Total",
						"series": [
							{
								"name": "Active",
								"value": data[3].total_open
							},
							{
								"name": "Close",
								"value": data[3].total_close
							}
						]
					}
				];
			} catch (error) {
				console.log(error);
				this.statisticsData1 = [];
			}
			this.statisticsLoader1 = false;
		}, (error) => {
			this.statisticsData1 = [];
			this.statisticsLoader1 = false;
		});
	}

	statisticsLoader2 = false;
	statisticsData2: any = [];
	getStatistics2() {
		let payload: any = {
			company_id: this.cardsFilter.company_id ? this.cardsFilter.company_id.clip_id : null,
			start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date),
			end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date)
		}
		this.statisticsLoader2 = true;
		this.apiService.ShoreCareStatistics2(payload).subscribe((data: any) => {
			try {
				this.statisticsData2 = [
					{
						"name": "Illness",
						"series": [
							{
								"name": "Hospitalised",
								"value": data[0].ill_hospital
							},
							{
								"name": "OPD",
								"value": data[0].ill_rest
							}
						]
					},
					{
						"name": "Injury",
						"series": [
							{
								"name": "Hospitalised",
								"value": data[1].inj_hospital,
							},
							{
								"name": "OPD",
								"value": data[1].inj_rest
							}
						]
					},
					{
						"name": "Psychology",
						"series": [
							{
								"name": "Hospitalised",
								"value": data[2].psy_hospital
							},
							{
								"name": "OPD",
								"value": data[2].psy_rest
							}
						]
					},
					{
						"name": "Total",
						"series": [
							{
								"name": "Hospitalised",
								"value": data[3].total_hospital
							},
							{
								"name": "OPD",
								"value": data[3].total_rest
							}
						]
					}
				];
			} catch (error) {
				this.statisticsData2 = [];
			}
			this.statisticsLoader2 = false;
		}, (error) => {
			this.statisticsData2 = [];
			this.statisticsLoader2 = false;
		});
	}

	statisticsLoader3 = false;
	statisticsData3: any = [];
	totalCount3: any = null;
	getStatistics3() {
		let payload: any = {
			company_id: this.cardsFilter.company_id ? this.cardsFilter.company_id.clip_id : null,
			start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date),
			end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date)
		}
		this.statisticsLoader3 = true;
		this.apiService.ShoreCareStatistics3(payload).subscribe((data: any) => {
			try {
				this.statisticsData3 = [
					{
						"name": "GROUP I (1-10 DAYS) "/*  + data[0].grp1_per + "%" */,
						"value": data[0].grp1
					},
					{
						"name": "GROUP II (11-30 DAYS) "/*  + data[1].grp2_per + "%" */,
						"value": data[1].grp2
					},
					{
						"name": "GROUP III (31-60 DAYS) "/*  + data[2].grp3_per + "%" */,
						"value": data[2].grp3
					},
					{
						"name": "GROUP IV (61-100 DAYS) "/*  + data[3].grp4_per + "%" */,
						"value": data[3].grp4
					},
					{
						"name": "GROUP V (101+ DAYS) "/*  + data[4].grp5_per + "%" */,
						"value": data[4].grp5
					}
				];
				this.totalCount3 = data[5].total_cases;
			} catch (error) {
				this.statisticsData3 = [];
				this.totalCount3 = null;
			}
			this.statisticsLoader3 = false;
		}, (error) => {
			this.statisticsData3 = [];
			this.totalCount3 = null;
			this.statisticsLoader3 = false;
		});
	}

	ClosedCasesIllnessLoader = false;
	ClosedCasesIllnessData: any = [];
	getClosedCasesIllnessData() {
		let payload: any = {
			company_id: this.cardsFilter.company_id ? this.cardsFilter.company_id.clip_id : null,
			start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date),
			end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date)
		}
		this.ClosedCasesIllnessLoader = true;
		this.apiService.ClosedCasesIllness(payload).subscribe((data: any) => {
			try {
				this.ClosedCasesIllnessData = [
					{
						"name": "GROUP I",
						"series": [
							{
								"name": "Hospitalised",
								"value": data[0].grp1_hosp
							},
							{
								"name": "All",
								"value": data[0].grp1
							}
						]
					},
					{
						"name": "GROUP II",
						"series": [
							{
								"name": "Hospitalised",
								"value": data[1].grp2_hosp
							},
							{
								"name": "All",
								"value": data[1].grp2,
							}
						]
					},
					{
						"name": "GROUP III",
						"series": [
							{
								"name": "Hospitalised",
								"value": data[2].grp3_hosp
							},
							{
								"name": "All",
								"value": data[2].grp3
							}
						]
					},
					{
						"name": "GROUP IV",
						"series": [
							{
								"name": "Hospitalised",
								"value": data[3].grp4_hosp
							},
							{
								"name": "All",
								"value": data[3].grp4
							}
						]
					},
					{
						"name": "GROUP V",
						"series": [
							{
								"name": "Hospitalised",
								"value": data[4].grp5_hosp
							},
							{
								"name": "All",
								"value": data[4].grp5
							}
						]
					}
				];
			} catch (error) {
				this.ClosedCasesIllnessData = [];
			}
			this.ClosedCasesIllnessLoader = false;
		}, (error) => {
			this.ClosedCasesIllnessData = [];
			this.ClosedCasesIllnessLoader = false;
		});
	}

	ClosedCasesInjuryLoader = false;
	ClosedCasesInjuryData: any = [];
	getClosedCasesInjuryData() {
		let payload: any = {
			company_id: this.cardsFilter.company_id ? this.cardsFilter.company_id.clip_id : null,
			start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date),
			end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date)
		}
		this.ClosedCasesInjuryLoader = true;
		this.apiService.ClosedCasesInjury(payload).subscribe((data: any) => {
			try {
				this.ClosedCasesInjuryData = [
					{
						"name": "GROUP I",
						"series": [
							{
								"name": "Hospitalised",
								"value": data[0].grp1_hosp
							},
							{
								"name": "All",
								"value": data[0].grp1
							}
						]
					},
					{
						"name": "GROUP II",
						"series": [
							{
								"name": "Hospitalised",
								"value": data[1].grp2_hosp
							},
							{
								"name": "All",
								"value": data[1].grp2,
							}
						]
					},
					{
						"name": "GROUP III",
						"series": [
							{
								"name": "Hospitalised",
								"value": data[2].grp3_hosp
							},
							{
								"name": "All",
								"value": data[2].grp3
							}
						]
					},
					{
						"name": "GROUP IV",
						"series": [
							{
								"name": "Hospitalised",
								"value": data[3].grp4_hosp
							},
							{
								"name": "All",
								"value": data[3].grp4
							}
						]
					},
					{
						"name": "GROUP V",
						"series": [
							{
								"name": "Hospitalised",
								"value": data[4].grp5_hosp
							},
							{
								"name": "All",
								"value": data[4].grp5
							}
						]
					}
				];
			} catch (error) {
				this.ClosedCasesInjuryData = [];
			}
			this.ClosedCasesInjuryLoader = false;
		}, (error) => {
			this.ClosedCasesInjuryData = [];
			this.ClosedCasesInjuryLoader = false;
		});
	}

	ClosedCasesPsychologyLoader = false;
	ClosedCasesPsychologyData: any = [];
	getClosedCasesPsychologyData() {
		let payload: any = {
			company_id: this.cardsFilter.company_id ? this.cardsFilter.company_id.clip_id : null,
			start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date),
			end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date)
		}
		this.ClosedCasesPsychologyLoader = true;
		this.apiService.ClosedCasesPsychology(payload).subscribe((data: any) => {
			try {
				this.ClosedCasesPsychologyData = [
					{
						"name": "GROUP I",
						"series": [
							{
								"name": "Hospitalised",
								"value": data[0].grp1_hosp
							},
							{
								"name": "All",
								"value": data[0].grp1
							}
						]
					},
					{
						"name": "GROUP II",
						"series": [
							{
								"name": "Hospitalised",
								"value": data[1].grp2_hosp
							},
							{
								"name": "All",
								"value": data[1].grp2,
							}
						]
					},
					{
						"name": "GROUP III",
						"series": [
							{
								"name": "Hospitalised",
								"value": data[2].grp3_hosp
							},
							{
								"name": "All",
								"value": data[2].grp3
							}
						]
					},
					{
						"name": "GROUP IV",
						"series": [
							{
								"name": "Hospitalised",
								"value": data[3].grp4_hosp
							},
							{
								"name": "All",
								"value": data[3].grp4
							}
						]
					},
					{
						"name": "GROUP V",
						"series": [
							{
								"name": "Hospitalised",
								"value": data[4].grp5_hosp
							},
							{
								"name": "All",
								"value": data[4].grp5
							}
						]
					}
				];
			} catch (error) {
				this.ClosedCasesPsychologyData = [];
			}
			this.ClosedCasesPsychologyLoader = false;
		}, (error) => {
			this.ClosedCasesPsychologyData = [];
			this.ClosedCasesPsychologyLoader = false;
		});
	}
}
