import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { ApiService, HttpParamsOptions } from '../../api.service';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Config } from 'datatables.net';
import Swal from 'sweetalert2';
import { password_validation } from '../../shared/constants';

class DataTablesResponse {
	data!: any[];
	draw!: number;
	recordsFiltered!: number;
	recordsTotal!: number;
}

@Component({
	selector: 'app-country-list',
	templateUrl: './country-list.component.html',
	styleUrl: './country-list.component.css'
})
export class CountryListComponent implements OnInit, OnDestroy, AfterViewInit {
	breadcrumbList: any[] = [
		{
			path: this.apiService.getHomeURL(),
			name: "Home"
		},
		/* {
			path: '/app/dashboard-mdm',
			name: "MDM"
		}, */
		{
			path: "/app/country",
			name: "Countries"
		}
	];

	@ViewChild(DataTableDirective, { static: false }) datatableElement!: DataTableDirective;
	baseURL = environment.API_URL + "mdm/";
	dtOptions: Config = {};
	dataList: any = [];
	showDeleteLoader: any = null;
	showDeleteLoaderHC: any = null;
	showCreateLoaderHC: any = null;
	showDeleteLoaderCLIP: any = null;
	showCreateLoaderCLIP: any = null;
	showDeleteLoaderMHS: any = null;
	showCreateLoaderMHS: any = null;
	showDeleteLoaderPharma: any = null;
	showCreateLoaderPharma: any = null;
	pagetitle = "Country List";
	pageLoading = false;
	allFlagStates: any[] = [];
	flagState: any = null;
	UserModuleAccess: any = "Full";
	passwordId: any;
	resetPasswordForm!: FormGroup;
	showReset2FALoader: any = null;
	user_type: any = "";
	accessLoader = false;

	constructor(private formBuilder: FormBuilder, private apiService: ApiService, private activeRoute: ActivatedRoute, private toastr: ToastrService, private http: HttpClient) {
		this.accessLoader = true;
		this.apiService.getUserAccess().subscribe((data: any) => {
			this.accessLoader = false;
			try {
				this.user_type = data.res[0].user_type;
				/* let accessObj: any = JSON.parse(data.res[0].UserModuleAccess);
				this.UserModuleAccess = accessObj["USER_Module"]; */
			} catch (error) { }
		}, (error: any) => {
			this.accessLoader = false;
			/* this.UserModuleAccess = "NoAccess"; */
		});
	}

	ngOnInit(): void {
		this.activeRoute.params.subscribe(routeParams => {
			this.initDatatable();
			this.resetPasswordForm = this.formBuilder.group({
				password: ['', [Validators.required, password_validation]],
				confirmPassword: ['', [Validators.required]],
			});
		});
	}

	ngOnDestroy(): void {

	}

	ngAfterViewInit(): void {

	}

	initDatatable() {
		this.dtOptions = {
			pagingType: 'full_numbers',
			serverSide: true,
			processing: true,
			ordering: false,
			ajax: (dataTablesParameters: any, callback) => {
				let data: any = {
					draw: dataTablesParameters.draw,
					start: dataTablesParameters.start,
					length: dataTablesParameters.length,
					searchVal: dataTablesParameters.search.value
				}
				const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
				this.http.get<DataTablesResponse>(this.baseURL + "mdm_country", { params: new HttpParams(httpParams) }).subscribe((response: any) => {
					this.dataList = response.data;
					callback({
						recordsTotal: response.recordsTotal,
						recordsFiltered: response.recordsFiltered,
						data: []
					});
				}, (error: any) => {
					if (error.status == 401) { return }
					this.toastr.error("Something went wrong.", 'Error!!!', {
						timeOut: 3000,
						tapToDismiss: true,
						closeButton: true
					});
				});
			}
		}
	}

	deleteConfirm(uId: any, index: number) {
		Swal.fire({
			title: 'Warning !!!',
			html: 'Are you sure you want to delete country <p style="text-align:center;font-size: 15px;"><strong>' + this.dataList[index].name + '</strong>?</p>',
			icon: 'warning',
			showCancelButton: true,
			showConfirmButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#dc3545'
		}).then((result) => {
			if (result.value) {
				this.showDeleteLoader = index;
				this.apiService.deleteCountry({ id: uId }).subscribe(data => {
					this.showDeleteLoader = null;
					Swal.fire('Success', 'Country deleted successfully.', 'success');
					this.refreshTable();
				}, (error) => {
					this.showDeleteLoader = null;
					if (error.status == 401) { return }
					if (error.status == 404) {
						Swal.fire('Error', error.error, 'error');
					} else {
						Swal.fire('Error', 'Something went wrong.', 'error');
					}
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}

	deleteConfirmHC(uId: any, index: number) {
		let platform = "Health Cube";
		Swal.fire({
			title: 'Warning !!!',
			html: 'Are you sure you want to delete country <p style="text-align:center;font-size: 15px;"><strong>' + this.dataList[index].name + '</strong> from ' + platform + '?</p>',
			icon: 'warning',
			showCancelButton: true,
			showConfirmButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#dc3545'
		}).then((result) => {
			if (result.value) {
				this.showDeleteLoaderHC = index;
				this.apiService.deleteHCCountry({ id: uId }).subscribe(data => {
					this.showDeleteLoaderHC = null;
					Swal.fire('Success', 'Country deleted successfully from ' + platform + '.', 'success');
					this.refreshTable();
				}, (error) => {
					this.showDeleteLoaderHC = null;
					if (error.status == 401) { return }
					if (error.status == 404) {
						Swal.fire('Error', error.error, 'error');
					} else {
						Swal.fire('Error', 'Something went wrong.', 'error');
					}
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}

	createConfirmHC(uId: any, index: number) {
		let platform = "Health Cube";
		Swal.fire({
			title: 'Warning !!!',
			html: 'Are you sure you want to create country <p style="text-align:center;font-size: 15px;"><strong>' + this.dataList[index].name + '</strong> in ' + platform + '?</p>',
			icon: 'warning',
			showConfirmButton: true,
			showCancelButton: true,
			confirmButtonText: 'Yes, create!',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#198754'
		}).then((result) => {
			if (result.value) {
				this.showCreateLoaderHC = index;
				this.apiService.createUpdateCountryInHC({ id: uId }).subscribe(data => {
					this.showCreateLoaderHC = null;
					Swal.fire('Success', 'Country created successfully in ' + platform + '.', 'success');
					this.refreshTable();
				}, (error) => {
					this.showCreateLoaderHC = null;
					if (error.status == 401) { return }
					if (error.status == 404 || error.status == 400) {
						Swal.fire('Error', error.error.error ? error.error.error : 'Something went wrong.', 'error');
					} else {
						Swal.fire('Error', 'Something went wrong.', 'error');
					}
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}

	deleteConfirmCLIP(uId: any, index: number) {
		let platform = "Shore Care";
		Swal.fire({
			title: 'Warning !!!',
			html: 'Are you sure you want to delete country <p style="text-align:center;font-size: 15px;"><strong>' + this.dataList[index].name + '</strong> from ' + platform + '?</p>',
			icon: 'warning',
			showCancelButton: true,
			showConfirmButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#dc3545'
		}).then((result) => {
			if (result.value) {
				this.showDeleteLoaderCLIP = index;
				this.apiService.deleteCLIPCountry({ id: uId }).subscribe(data => {
					this.showDeleteLoaderCLIP = null;
					Swal.fire('Success', 'Country deleted successfully from ' + platform + '.', 'success');
					this.refreshTable();
				}, (error) => {
					this.showDeleteLoaderCLIP = null;
					if (error.status == 401) { return }
					if (error.status == 404) {
						Swal.fire('Error', error.error, 'error');
					} else {
						Swal.fire('Error', 'Something went wrong.', 'error');
					}
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}

	createConfirmCLIP(uId: any, index: number) {
		let platform = "Shore Care";
		Swal.fire({
			title: 'Warning !!!',
			html: 'Are you sure you want to create country <p style="text-align:center;font-size: 15px;"><strong>' + this.dataList[index].name + '</strong> in ' + platform + '?</p>',
			icon: 'warning',
			showConfirmButton: true,
			showCancelButton: true,
			confirmButtonText: 'Yes, create!',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#198754'
		}).then((result) => {
			if (result.value) {
				this.showCreateLoaderCLIP = index;
				this.apiService.createUpdateCountryInClip({ id: uId }).subscribe(data => {
					this.showCreateLoaderCLIP = null;
					Swal.fire('Success', 'Country created successfully in ' + platform + '.', 'success');
					this.refreshTable();
				}, (error) => {
					this.showCreateLoaderCLIP = null;
					if (error.status == 401) { return }
					if (error.status == 404 || error.status == 400) {
						Swal.fire('Error', error.error.error ? error.error.error : 'Something went wrong.', 'error');
					} else {
						Swal.fire('Error', 'Something went wrong.', 'error');
					}
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}

	deleteConfirmMHS(uId: any, index: number) {
		let platform = "MHS";
		Swal.fire({
			title: 'Warning !!!',
			html: 'Are you sure you want to delete country <p style="text-align:center;font-size: 15px;"><strong>' + this.dataList[index].name + '</strong> from ' + platform + '?</p>',
			icon: 'warning',
			showCancelButton: true,
			showConfirmButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#dc3545'
		}).then((result) => {
			if (result.value) {
				this.showDeleteLoaderMHS = index;
				this.apiService.deleteMHSCountry({ id: uId }).subscribe(data => {
					this.showDeleteLoaderMHS = null;
					Swal.fire('Success', 'Country deleted successfully from ' + platform + '.', 'success');
					this.refreshTable();
				}, (error) => {
					this.showDeleteLoaderMHS = null;
					if (error.status == 401) { return }
					if (error.status == 404) {
						Swal.fire('Error', error.error, 'error');
					} else {
						Swal.fire('Error', 'Something went wrong.', 'error');
					}
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}

	createConfirmMHS(uId: any, index: number) {
		let platform = "MHS";
		Swal.fire({
			title: 'Warning !!!',
			html: 'Are you sure you want to create country <p style="text-align:center;font-size: 15px;"><strong>' + this.dataList[index].name + '</strong> in ' + platform + '?</p>',
			icon: 'warning',
			showConfirmButton: true,
			showCancelButton: true,
			confirmButtonText: 'Yes, create!',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#198754'
		}).then((result) => {
			if (result.value) {
				this.showCreateLoaderMHS = index;
				this.apiService.createUpdateCountryInMHS({ id: uId }).subscribe(data => {
					this.showCreateLoaderMHS = null;
					Swal.fire('Success', 'Country created successfully in ' + platform + '.', 'success');
					this.refreshTable();
				}, (error) => {
					this.showCreateLoaderMHS = null;
					if (error.status == 401) { return }
					if (error.status == 404 || error.status == 400) {
						Swal.fire('Error', error.error.error ? error.error.error : 'Something went wrong.', 'error');
					} else {
						Swal.fire('Error', 'Something went wrong.', 'error');
					}
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}

	deleteConfirmPharma(uId: any, index: number) {
		let platform = "Pharma";
		Swal.fire({
			title: 'Warning !!!',
			html: 'Are you sure you want to delete country <p style="text-align:center;font-size: 15px;"><strong>' + this.dataList[index].name + '</strong> from ' + platform + '?</p>',
			icon: 'warning',
			showCancelButton: true,
			showConfirmButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#dc3545'
		}).then((result) => {
			if (result.value) {
				this.showDeleteLoaderPharma = index;
				this.apiService.deletePharmaCountry({ id: uId }).subscribe(data => {
					this.showDeleteLoaderPharma = null;
					Swal.fire('Success', 'Country deleted successfully from ' + platform + '.', 'success');
					this.refreshTable();
				}, (error) => {
					this.showDeleteLoaderPharma = null;
					if (error.status == 401) { return }
					if (error.status == 404) {
						Swal.fire('Error', error.error, 'error');
					} else {
						Swal.fire('Error', 'Something went wrong.', 'error');
					}
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}

	createConfirmPharma(uId: any, index: number) {
		let platform = "Pharma";
		Swal.fire({
			title: 'Warning !!!',
			html: 'Are you sure you want to create country <p style="text-align:center;font-size: 15px;"><strong>' + this.dataList[index].name + '</strong> in ' + platform + '?</p>',
			icon: 'warning',
			showConfirmButton: true,
			showCancelButton: true,
			confirmButtonText: 'Yes, create!',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#198754'
		}).then((result) => {
			if (result.value) {
				this.showCreateLoaderPharma = index;
				this.apiService.createUpdateCountryInPharma({ id: uId }).subscribe(data => {
					this.showCreateLoaderPharma = null;
					Swal.fire('Success', 'Country created successfully in ' + platform + '.', 'success');
					this.refreshTable();
				}, (error) => {
					this.showCreateLoaderPharma = null;
					if (error.status == 401) { return }
					if (error.status == 404 || error.status == 400) {
						Swal.fire('Error', error.error.error ? error.error.error : 'Something went wrong.', 'error');
					} else {
						Swal.fire('Error', 'Something went wrong.', 'error');
					}
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}

	refreshTable() {
		this.datatableElement.dtInstance.then((dtInstance) => {
			dtInstance.draw();
		});
	}

	get f() { return this.resetPasswordForm.controls; }
}
