<breadcrumb [breadcrumbList]="breadcrumbList"></breadcrumb>
<div *ngIf="accessLoader" class="loader text-center">
	<img src="../assets/img/cc_loader.gif" />
</div>
@if (user_type=='cdm') {
@if(UserModuleAccess=='Full'){
<ng-container [ngTemplateOutlet]="dashboardtemplate"></ng-container>
}
} @else if(user_type=='company' || user_type=='fleet') {
@if(UserModuleAccess=='Full' && !isCompanyIdNull){
<ng-container [ngTemplateOutlet]="dashboardtemplate"></ng-container>
}
}
<ng-template #dashboardtemplate>
	<div class="card">
		<div class="card-header bg-primary">
			{{pagetitle}}
		</div>
		<div class="card-body">
			<div class="row">
				<div class="col-lg-3 col-md-3 col-sm-6 col-xs-6">
					<mat-form-field>
						<mat-label>Start Date</mat-label>
						<input matInput [matDatepicker]="start_date_picker_1" [(ngModel)]="cardsFilter.start_date">
						<mat-hint>MM/DD/YYYY</mat-hint>
						<mat-datepicker-toggle matIconSuffix [for]="start_date_picker_1"></mat-datepicker-toggle>
						<mat-datepicker #start_date_picker_1></mat-datepicker>
					</mat-form-field>
				</div>
				<div class="col-lg-3 col-md-3 col-sm-6 col-xs-6">
					<mat-form-field>
						<mat-label>End Date</mat-label>
						<input matInput [matDatepicker]="end_date_picker_1" [(ngModel)]="cardsFilter.end_date">
						<mat-hint>MM/DD/YYYY</mat-hint>
						<mat-datepicker-toggle matIconSuffix [for]="end_date_picker_1"></mat-datepicker-toggle>
						<mat-datepicker #end_date_picker_1></mat-datepicker>
					</mat-form-field>
				</div>
				<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12" *ngIf="user_type=='cdm'">
					<ng-select appearance="outline" appendTo="body" [items]="companyList_dropdown" bindLabel="name"
						[clearable]="true" [multiple]="false" [(ngModel)]="cardsFilter.company_id" placeholder="Company"
						(change)="setCompany(cardsFilter.company_id)">
					</ng-select>
				</div>
				<div class="col-lg-1 col-md-1 col-sm-6 col-xs-6">
					<button type="button" class="btn btn-md btn-success mt-2 radius-50" (click)="filterChanged();">
						<fa-icon [icon]="['fas', 'search']"></fa-icon>
					</button>
				</div>
			</div>
			<mat-accordion [hideToggle]="false">
				<mat-expansion-panel [expanded]="false" (opened)="accordionOpened('InvoiceModuleAlerts')" *ngIf="user_type=='cdm'">
					<mat-expansion-panel-header class="bg-acc_style_1">
						<mat-panel-title>
							<b>{{invoiceHeaderName}}</b>
						</mat-panel-title>
						<mat-panel-description>
							<button class="btn btn-sm btn-light" matTooltip="Excel" [matTooltipPosition]="'below'"
								(click)="getInvoiceModuleAlertsExcel($event)">
								<fa-icon *ngIf="!invoiceAlertsExcelLoader"
									[icon]="['fas', 'cloud-download-alt']"></fa-icon>
								<fa-icon *ngIf="invoiceAlertsExcelLoader" [icon]="['fas', 'spinner']"
									animation="spin"></fa-icon>
							</button>
						</mat-panel-description>
					</mat-expansion-panel-header>
					<div class="row">
						<div class="col-sm-12">
							<div class="row">
								<div class="col-sm-12">
									<div class="table-responsive" style="height: 450px;overflow-y: auto;">
										<table class="myTable table table-bordered table-hover mt-2"
											style="width: 100%;">
											<thead>
												<tr>
													<th style="width: 10% !important;">Invoice No.</th>
													<th style="width: 10% !important;">Purchase Date</th>
													<th style="width: 10% !important;">Created By</th>
													<th style="width: 10% !important;">Created Date</th>
													<th style="width: 15% !important;">Updated By</th>
													<th style="width: 10% !important;">Updated Date</th>
													<th style="width: 15% !important;">Deleted By</th>
													<th style="width: 10% !important;">Paid/Unpaid</th>
													<th style="width: 10% !important;text-align: right;">Amount</th>
												</tr>
											</thead>
											<tbody *ngIf="invoiceAlerts?.length != 0">
												<tr class="table_td_style"
													*ngFor="let a of invoiceAlerts;let i = index;">
													<td>{{a.inv_no}}</td>
													<td>{{a.purchase_date}}</td>
													<td>{{a.created_by}}</td>
													<td>{{a.created_date}}</td>
													<td class="text-danger">{{a.updated_by}}</td>
													<td>{{a.updated_date}}</td>
													<td>{{a.deleted_by}}</td>
													<td>{{a.is_paid}}</td>
													<td style="text-align: right;">{{a.amount}}</td>
												</tr>
											</tbody>
										</table>
										<p *ngIf="invoiceAlerts?.length == 0 && !loadingInvoiceAlerts"
											class="text-center">
											No data found!
										</p>
										<p *ngIf="loadingInvoiceAlerts" class="text-center text-bold">
											<fa-icon [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
											Loading...
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</mat-expansion-panel>
				<mat-expansion-panel (opened)="accordionOpened('CompletedOrdersReport')" *ngIf="user_type=='cdm'">
					<mat-expansion-panel-header class="bg-acc_style_3">
						<mat-panel-title>
							<b>{{CompletedOrdersReportHeader}}</b>
						</mat-panel-title>
						<mat-panel-description>
							<button class="btn btn-sm btn-light" matTooltip="Excel" [matTooltipPosition]="'below'"
								(click)="getCompletedOrdersReportExcel($event)">
								<fa-icon *ngIf="!CompletedOrdersReportExcelLoader"
									[icon]="['fas', 'cloud-download-alt']"></fa-icon>
								<fa-icon *ngIf="CompletedOrdersReportExcelLoader" [icon]="['fas', 'spinner']"
									animation="spin"></fa-icon>
							</button>
						</mat-panel-description>
					</mat-expansion-panel-header>
					<div class="row">
						<div class="col-sm-12">
							<div class="row">
								<div class="col-sm-12">
									<div class="table-responsive" style="height: 450px;overflow-y: auto;">
										<table class="myTable table table-bordered table-hover mt-2"
											style="width: 100%;">
											<thead>
												<tr>
													<th style="width: 10% !important;">Company</th>
													<th style="width: 10% !important;">Vessel</th>
													<th style="width: 10% !important;">Invoice No(INR)</th>
													<th style="width: 10% !important;">Invoice Date(INR)</th>
													<th style="width: 10% !important;text-align: right;">Total Bill(INR)
													</th>
													<th style="width: 10% !important;">Invoice No(USD)</th>
													<th style="width: 10% !important;">Invoice Date(USD)</th>
													<th style="width: 10% !important;">Total Bill(USD)</th>
													<th style="width: 10% !important;">AWB Number</th>
													<th style="width: 10% !important;">Consignee</th>
												</tr>
											</thead>
											<tbody *ngIf="CompletedOrdersReport?.length != 0">
												<tr class="table_td_style"
													*ngFor="let a of CompletedOrdersReport;let i = index;">
													<td>{{a.company_name}}</td>
													<td>{{a.vessel_name}}</td>
													<td>{{a.invoice_no}}</td>
													<td>{{a.invoice_date}}</td>
													<td>
														<div class="amount-div">{{a.total_amount}}</div>
														<div class="paid-unpaid-div" *ngIf="a.total_amount">
															<fa-icon [icon]="['fas', 'check']" class="text-success"
																*ngIf="a.payment_done" matTooltip="Paid"
																[matTooltipPosition]="'below'"></fa-icon>
															<fa-icon [icon]="['fas', 'times']" class="text-danger"
																*ngIf="!a.payment_done" matTooltip="Unpaid"
																[matTooltipPosition]="'below'"></fa-icon>
														</div>
													</td>
													<td>{{a.invoice_no2}}</td>
													<td>{{a.invoice_date2}}</td>
													<td>
														<div class="amount-div">{{a.total_amount2}}</div>
														<div class="paid-unpaid-div" *ngIf="a.total_amount2">
															<fa-icon [icon]="['fas', 'check']" class="text-success"
																*ngIf="a.is_paid" matTooltip="Paid"
																[matTooltipPosition]="'below'"></fa-icon>
															<fa-icon [icon]="['fas', 'times']" class="text-danger"
																*ngIf="!a.is_paid" matTooltip="Unpaid"
																[matTooltipPosition]="'below'"></fa-icon>
														</div>
													</td>
													<td>{{a.awb_no}}</td>
													<td>{{a.consignee_name}}</td>
												</tr>
											</tbody>
										</table>
										<p *ngIf="CompletedOrdersReport?.length == 0 && !CompletedOrdersReportLoader"
											class="text-center">
											No data found!
										</p>
										<p *ngIf="CompletedOrdersReportLoader" class="text-center text-bold">
											<fa-icon [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
											Loading...
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</mat-expansion-panel>
				<mat-expansion-panel (opened)="accordionOpened('FreightBillingReport')" *ngIf="user_type=='cdm'">
					<mat-expansion-panel-header class="bg-acc_style_4">
						<mat-panel-title>
							<b>{{FreightBillingReportHeader}}</b>
						</mat-panel-title>
						<mat-panel-description>
							<button class="btn btn-sm btn-light" matTooltip="Excel" [matTooltipPosition]="'below'"
								(click)="getFreightBillingReportExcel($event)">
								<fa-icon *ngIf="!FreightBillingReportExcelLoader"
									[icon]="['fas', 'cloud-download-alt']"></fa-icon>
								<fa-icon *ngIf="FreightBillingReportExcelLoader" [icon]="['fas', 'spinner']"
									animation="spin"></fa-icon>
							</button>
						</mat-panel-description>
					</mat-expansion-panel-header>
					<div class="row">
						<div class="col-sm-12">
							<div class="row">
								<div class="col-sm-12">
									<div class="table-responsive" style="height: 450px;overflow-y: auto;">
										<table class="myTable table table-bordered table-hover mt-2"
											style="width: 100%;">
											<thead>
												<tr>
													<th style="width: 15% !important;">Invoice No</th>
													<th style="width: 10% !important;">Invoice Date</th>
													<th style="width: 60% !important;">Provider</th>
													<th style="width: 15% !important;text-align: right;">Amount</th>
												</tr>
											</thead>
											<tbody *ngIf="FreightBillingReport?.length != 0">
												<tr class="table_td_style"
													*ngFor="let a of FreightBillingReport;let i = index;">
													<td>{{a.invoice_no}}</td>
													<td>{{a.invoice_date}}</td>
													<td>{{a.provider_name}}</td>
													<td>
														<div class="amount-div">{{a.total_amount}}</div>
														<div class="paid-unpaid-div" *ngIf="a.total_amount">
															<fa-icon [icon]="['fas', 'check']" class="text-success"
																*ngIf="a.is_paid!='Unpaid'" matTooltip="Paid"
																[matTooltipPosition]="'below'"></fa-icon>
															<fa-icon [icon]="['fas', 'times']" class="text-danger"
																*ngIf="a.is_paid=='Unpaid'" matTooltip="Unpaid"
																[matTooltipPosition]="'below'"></fa-icon>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
										<p *ngIf="FreightBillingReport?.length == 0 && !FreightBillingReportLoader"
											class="text-center">
											No data found!
										</p>
										<p *ngIf="FreightBillingReportLoader" class="text-center text-bold">
											<fa-icon [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
											Loading...
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</mat-expansion-panel>
				<mat-expansion-panel (opened)="accordionOpened('CompanyReport')">
					<mat-expansion-panel-header class="bg-acc_style_5">
						<mat-panel-title>
							<b>{{CompanyReportHeader}}</b>
						</mat-panel-title>
						<mat-panel-description>
							<button class="btn btn-sm btn-light" matTooltip="Excel" [matTooltipPosition]="'below'"
								(click)="getCompanyReportExcel($event)">
								<fa-icon *ngIf="!CompanyReportExcelLoader"
									[icon]="['fas', 'cloud-download-alt']"></fa-icon>
								<fa-icon *ngIf="CompanyReportExcelLoader" [icon]="['fas', 'spinner']"
									animation="spin"></fa-icon>
							</button>
						</mat-panel-description>
					</mat-expansion-panel-header>
					<div class="row">
						<div class="col-sm-12">
							<div class="row">
								<div class="col-sm-12">
									<div class="table-responsive" style="height: 450px;overflow-y: auto;">
										<table class="myTable table table-bordered table-hover mt-2"
											style="width: 100%;">
											<thead>
												<tr>
													<th style="width: 28% !important;">Vessel Name</th>
													<th style="width: 12% !important;">Certificate Validity Date</th>
													<th style="width: 12% !important;">Last Reviewed Date</th>
													<th style="width: 12% !important;">Last Delivery Date</th>
													<th style="width: 12% !important;text-align: right;">
														Medicine Rate
													</th>
													<th style="width: 12% !important;text-align: right;">
														Packaging & Logistics
													</th>
													<th style="width: 12% !important;text-align: right;">
														Total Freight
													</th>
												</tr>
											</thead>
											<tbody *ngIf="CompanyReport?.length != 0">
												<tr class="table_td_style"
													*ngFor="let a of CompanyReport;let i = index;">
													<td>{{a.vessel_name}}</td>
													<td>{{a.mcc_exp_date}}</td>
													<td>{{a.inv_rev_date}}</td>
													<td>{{a.shipping_date}}</td>
													<td style="text-align: right;">{{a.invoice_value}}</td>
													<td style="text-align: right;">{{a.pack_log}}</td>
													<td style="text-align: right;">{{a.total_freight}}</td>
												</tr>
											</tbody>
										</table>
										<p *ngIf="CompanyReport?.length == 0 && !CompanyReportLoader"
											class="text-center">
											No data found!
										</p>
										<p *ngIf="CompanyReportLoader" class="text-center text-bold">
											<fa-icon [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
											Loading...
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</mat-expansion-panel>
			</mat-accordion>
		</div>
	</div>
	<div class="card mt-2">
		<div class="card-header bg-success">
			Shipments
		</div>
		<div class="card-body">
			<div class="row">
				<div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
					<div class="row">
						<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
							<div class="card1 card-shadow bg-success mt-190">
								<div class="text-center">
									<h2 class="mb-0">
										<ng-container *ngIf="!pharmaShipmentLoader">{{shipment.count}}</ng-container>
										<fa-icon [icon]="['fas', 'spinner']" animation="spin"
											*ngIf="pharmaShipmentLoader"></fa-icon>
									</h2>
									<h3 class="mb-0">Total Shipments</h3>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-10 col-md-10 col-sm-12 col-xs-12">
					<p class="text-center" *ngIf="pharmaShipmentLoader">
						<fa-icon [icon]="['fas', 'spinner']" animation="spin" size="3x"></fa-icon>
					</p>
					<div id="worldmap" *ngIf="!pharmaShipmentLoader"></div>
				</div>
			</div>
		</div>
	</div>
	<div class="row"  *ngIf="user_type=='cdm'">
		<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
			<div class="card mt-2">
				<div class="card-header bg-info">
					{{Top10PurchaseMedicinesHeader}}
					<fa-icon [icon]="['fas', 'spinner']" animation="spin"
						*ngIf="Top10PurchaseMedicinesLoader"></fa-icon>
				</div>
				<div class="card-body">
					<div class="row">
						<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div class="table-responsive">
								<table class="myTable table table-bordered table-hover mt-2" style="width: 100%;">
									<thead>
										<tr>
											<th style="width: 20% !important;">3C Id</th>
											<th style="width: 60% !important;">Medicines / Equipments</th>
											<th style="width: 20% !important;">Quantity</th>
										</tr>
									</thead>
									<tbody *ngIf="Top10PurchaseMedicines?.length != 0">
										<tr class="table_td_style"
											*ngFor="let a of Top10PurchaseMedicines;let i = index;">
											<td>{{a.med_id__med_id}}</td>
											<td>{{a.med_id__med_name}}</td>
											<td>{{a.total}}</td>
										</tr>
									</tbody>
								</table>
								<p *ngIf="Top10PurchaseMedicines?.length == 0 && !Top10PurchaseMedicinesLoader"
									class="text-center">
									No data found!
								</p>
								<p *ngIf="Top10PurchaseMedicinesLoader" class="text-center text-bold">
									<fa-icon [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
									Loading...
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
			<div class="card mt-2">
				<div class="card-header bg-primary">
					{{Top10SaleMedicinesHeader}}
					<fa-icon [icon]="['fas', 'spinner']" animation="spin" *ngIf="Top10SaleMedicinesLoader"></fa-icon>
				</div>
				<div class="card-body">
					<div class="row">
						<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div class="table-responsive">
								<table class="myTable table table-bordered table-hover mt-2" style="width: 100%;">
									<thead>
										<tr>
											<th style="width: 20% !important;">3C Id</th>
											<th style="width: 60% !important;">Medicines / Equipments</th>
											<th style="width: 20% !important;">Quantity</th>
										</tr>
									</thead>
									<tbody *ngIf="Top10SaleMedicines?.length != 0">
										<tr class="table_td_style" *ngFor="let a of Top10SaleMedicines;let i = index;">
											<td>{{a.med_id__med_id}}</td>
											<td>{{a.med_id__med_name}}</td>
											<td>{{a.total}}</td>
										</tr>
									</tbody>
								</table>
								<p *ngIf="Top10SaleMedicines?.length == 0 && !Top10SaleMedicinesLoader"
									class="text-center">
									No data found!
								</p>
								<p *ngIf="Top10SaleMedicinesLoader" class="text-center text-bold">
									<fa-icon [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
									Loading...
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
			<div class="card mt-2">
				<div class="card-header bg-warning">
					{{CountryListHeader}}
					<fa-icon [icon]="['fas', 'spinner']" animation="spin" *ngIf="CountryListLoader"></fa-icon>
				</div>
				<div class="card-body">
					<div class="row">
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<input type="text" class="form-control" placeholder="Search Country"
								[formControl]="countrySearchControl" />
						</div>
					</div>
					<div class="row">
						<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div class="table-responsive" style="height: 450px;overflow-y: auto;">
								<table class="myTable table table-bordered table-hover mt-2" style="width: 100%;">
									<thead>
										<tr>
											<th style="width: 20% !important;">Country</th>
											<th style="width: 20% !important;">Total DHL</th>
											<th style="width: 20% !important;">Gross Weight</th>
											<th style="width: 20% !important;">Shipment Count</th>
											<th style="width: 20% !important;">Average Cost / Kg</th>
										</tr>
									</thead>
									<tbody *ngIf="CountryList?.length != 0">
										<ng-container *ngFor="let a of CountryList;let i = index;">
											<tr class="table_td_style" *ngIf="searchCountry(i)">
												<td>{{a.country_id__country_name}}</td>
												<td>{{a.total_dhl}}</td>
												<td>{{a.total_gross_weight}}</td>
												<td>{{a.total_count}}</td>
												<td>{{a.total_avg}}</td>
											</tr>
										</ng-container>
									</tbody>
								</table>
								<p *ngIf="CountryList?.length == 0 && !CountryListLoader" class="text-center">
									No data found!
								</p>
								<p *ngIf="CountryListLoader" class="text-center text-bold">
									<fa-icon [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
									Loading...
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>
@if (user_type=='cdm') {
@if(UserModuleAccess!='Full'){
<h3 class="text-center">You don't have access to this section.</h3>
}
} @else if(user_type=='company' || user_type=='fleet') {
@if(UserModuleAccess!='Full' || isCompanyIdNull){
<h3 class="text-center">Service not subscribed.</h3>
}
}