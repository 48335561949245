import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from '../../api.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
import Swal from 'sweetalert2';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { emailPattern, mobilePattern, noWhitespaceInRequiredValidator } from '../../shared/constants';
import { map, Observable, startWith } from 'rxjs';
import { NgxFileDropEntry } from 'ngx-file-drop';

@Component({
	selector: 'app-welcome-mail',
	templateUrl: './welcome-mail.component.html',
	styleUrl: './welcome-mail.component.css'
})
export class WelcomeMailComponent implements OnInit {
	breadcrumbList = [
		{
			path: this.apiService.getHomeURL(),
			name: "Home"
		},
		{
			path: "/app/vessel",
			name: "Vessels"
		}
	];
	vesselId: any = null;
	defaultSubject = "3Cube Medicare - Duty of Care Services";
	defaultBody = "Good Day Captain,\n\nGreetings from the Duty of Care (DOC) team at 3Cube Medicare.\n\n\
As part of your companies dedicated efforts towards health, wellbeing and safety of crew onboard 3Cube Medicare is appointed as your Vessel Doctor providing 24x7 Remote Medical Assistance (RMA).\n\
The RMA station is manned 24x7 by a team of Doctors, Surgeons, Specialists, Psychologists, etc - all available to assist the ship command with prompt and suitable remedy/treatment. \n\
We strongly recommend to reach out to us for ALL MEDICAL CONCERNS, HOWEVER SMALL OR BIG. You are requested, NOT TO SELF-DIAGNOSE AND TREAT.\n\
The service also includes Wellness program wherein Health/Wellness/Wellbeing leaflets will be sent to your good vessel periodically to share with the crew and discuss during toolbox meetings.\n\
There will be campaigns and health surveys conducted periodically. Your vessels participation is crucial to the success and positive impact of such initiatives. \n\n\
We look forward to interacting with you soon.\nOur team is here for your 24x7! \n\
KEY CONTACTS \n\
1. For Medical Assistance, please note our Primary Email Id - rma@dutyofcare365.com and Alternate Email Id - rma@3cubemedicare.com \n\
2. For any queries related to Wellness, our Email Id is wellness@3cubemedicare.com \n\
We take this opportunity to welcome you to the Duty of Care 365 Services.\nWe request you to send us Updated Medical Chest Inventory and Medical Chest Certificate for us to configure it with our system.\n\n\
With this email the following documents are attached:\n\
1. SMR Form & Contact Card (to send along with medical queries) \n\
2. 3Cube Posters to put on common areas \n\n\
We request you to please confirm the receipt of this email.\n\n\
Best Regards,\nDOC Team\n3Cube Medicare\nPHONE - +91 80-6912-3333\nEmail Id  - rma@dutyofcare365.com";
	requiredValidator = Validators.required;
	moduleForm: FormGroup = this.formBuilder.group({
		cc_emails: [null],
		bcc_emails: [null],
		subject: [this.defaultSubject, [this.requiredValidator]],
		mail_content: [this.defaultBody, [this.requiredValidator]],
		/* language: ["English", [Validators.required]] */
	});
	/* languages: any = [
		{ name: "English", val: "English" },
		{ name: "Chinese", val: "Chinese" }
	]; */
	vesselDetails: any = null;
	showMailLoader = false;
	pageLoading = false;
	accessLoader = false;
	user_type: any = "";
	files: File[] = [];

	constructor(private toastr: ToastrService, private apiService: ApiService, private formBuilder: FormBuilder, private router: Router, private activeRoute: ActivatedRoute) {
		this.accessLoader = true;
		this.apiService.getUserAccess().subscribe((data: any) => {
			this.accessLoader = false;
			try {
				this.user_type = data.res[0].user_type;
			} catch (error) { }
		}, (error: any) => {
			this.accessLoader = false;
		});
	}

	ngOnInit(): void {
		this.activeRoute.params.subscribe(routeParams => {
			this.vesselId = routeParams["vesselId"];
			this.breadcrumbList.push({ path: "/app/welcome-mail/" + this.vesselId, name: "Welcome Mail" });
			this.getDetails();
		});
	}

	getDetails() {
		this.apiService.getVesselDetails({ "id": this.vesselId }).subscribe({
			next: (data: any) => {
				this.vesselDetails = data.data;
			}, error: (error) => {
				this.pageLoading = false;
				if (error.status == 401) { return }
				this.toastr.error("Unable to fetch details.", 'Error', {
					timeOut: 3000,
					tapToDismiss: true,
					closeButton: true
				});
			}
		});
	}

	public uploadFile(files: NgxFileDropEntry[]) {
		for (let i = 0; i < files.length; i++) {
			if (files[i].fileEntry.isFile) {
				const fileEntry = files[i].fileEntry as FileSystemFileEntry;
				fileEntry.file((file: File) => {
					this.files.push(file);
				});
				this.moduleForm.markAsDirty();
			} else {
				this.toastr.error("Invalid File Format.", 'Error', {
					timeOut: 3000,
					tapToDismiss: true,
					closeButton: true
				});
			}
		}
	}

	removeFile(index: number) {
		this.files.splice(index, 1);
	}

	get f1() { return this.moduleForm.controls; }

	showError(field: string) {
		if (this.f1[field].invalid && (this.f1[field].touched || this.f1[field].dirty)) {
			return true;
		}
		return false;
	}

	sendMail() {
		if (this.vesselDetails && !this.vesselDetails.email) {
			this.toastr.error("No email id in vessel", 'Error', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
			return;
		}
		if (this.moduleForm.invalid) {
			this.toastr.error("Please check required parameters", 'Error', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
			return;
		} let cc_emails = this.f1.cc_emails.value ? this.f1.cc_emails.value : [];
		let bcc_emails = this.f1.bcc_emails.value ? this.f1.bcc_emails.value : [];
		let formData = new FormData();
		formData.append("id", this.vesselDetails.id);
		formData.append("cc_emails", JSON.stringify(cc_emails));
		formData.append("bcc_emails", JSON.stringify(bcc_emails));
		formData.append("subject", this.f1.subject.value);
		formData.append("mail_content", this.f1.mail_content.value);
		formData.append("count", this.files.length.toString());
		for (let i = 0; i < this.files.length; i++) {
			formData.append("attachments_" + i, this.files[i]);
		}
		/* formData.append("language",this.f1.language.value); */
		this.showMailLoader = true;
		this.apiService.sendWelcomeMail(formData).subscribe({
			next: data => {
				this.showMailLoader = false;
				this.toastr.success("Mail sent successfully", 'Success', {
					timeOut: 3000,
					tapToDismiss: true,
					closeButton: true
				});
				this.router.navigate(['/app/vessel']);
			}, error: (error) => {
				this.showMailLoader = false;
				if (error.status == 401) { return }
				this.toastr.error("Something went wrong", 'Error', {
					timeOut: 3000,
					tapToDismiss: true,
					closeButton: true
				});
			}
		});
	}
}
