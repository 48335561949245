<breadcrumb [breadcrumbList]="breadcrumbList"></breadcrumb>
<div *ngIf="accessLoader" class="loader text-center">
	<img src="../assets/img/cc_loader.gif" />
</div>
@if (user_type=='cdm') {
@if(UserModuleAccess=='Full'){
<ng-container [ngTemplateOutlet]="dashboardtemplate"></ng-container>
}
} @else if(user_type=='company' || user_type=='fleet') {
@if(UserModuleAccess=='Full' && !isCompanyIdNull){
<ng-container [ngTemplateOutlet]="dashboardtemplate"></ng-container>
}
}
<ng-template #dashboardtemplate>
	<div class="row">
		<div class="col-lg-12 col-md-12">
			<div class="card mb-3">
				<div class="card-header bg-primary">
					<h4 class="text-light">Blue Code</h4>
				</div>
				<div class="card-body">
					<div>
						<p class="text-center" *ngIf="top5CasesLoader">
							<!-- <fa-icon [icon]="['fas', 'spinner']" animation="spin" size="3x"></fa-icon> -->
							<svg width="44" height="44" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><style>.spinner_9y7u{animation:spinner_fUkk 2.4s linear infinite;animation-delay:-2.4s}.spinner_DF2s{animation-delay:-1.6s}.spinner_q27e{animation-delay:-.8s}@keyframes spinner_fUkk{8.33%{x:13px;y:1px}25%{x:13px;y:1px}33.3%{x:13px;y:13px}50%{x:13px;y:13px}58.33%{x:1px;y:13px}75%{x:1px;y:13px}83.33%{x:1px;y:1px}}</style><rect class="spinner_9y7u" x="1" y="1" rx="1" width="10" height="10"/><rect class="spinner_9y7u spinner_DF2s" x="1" y="1" rx="1" width="10" height="10"/><rect class="spinner_9y7u spinner_q27e" x="1" y="1" rx="1" width="10" height="10"/></svg>
						</p>
						<table class="table table-bordered table-hover fix-header" *ngIf="!top5CasesLoader">
							<thead>
								<tr>
									<th>Case ID</th>
									<th>Company Name</th>
									<th>Vessel</th>
									<th>Rank</th>
									<th>Symptoms</th>
								</tr>
							</thead>
							<tbody>
								@for (case of RCodeBlueData; track $index) {
								  <tr>
										<td>{{case.case_id}}</td>
										<td>{{case.company_name}}</td>
										<td>{{case.vessel}}</td>
										<td>{{case.rank}}</td>
										<td>{{case.symptoms}}</td>
									</tr>
								}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="card mb-3">
		<div class="card-header bg-primary">
			<div class="row">
				<div class="col-lg-6 col-md-4 col-sm-4 align-self-center">
					<h4 class="text-light">Open cases</h4>
				</div>
			</div>
		</div>
		<div class="card-body">
			<div class="row">
				<div class="col-lg-8 col-md-8 col-sm-8 col-xs-6">
				<div class="btn-group w-100" role="group" aria-label="Basic example">
					<button type="button"  [ngClass]="{'selected': selectedDay === 7}"  class="btn btn-primary" (click)="MarkedForClosure(7);selectDay(7)" ><fa-icon [icon]="['fas', 'calendar']"></fa-icon> 7 Days</button>
					<button type="button"  [ngClass]="{'selected': selectedDay === 14}"  class="btn btn-primary" (click)="MarkedForClosure(14);selectDay(14)" ><fa-icon [icon]="['fas', 'calendar']"></fa-icon> 14 Days</button>
					<button type="button"  [ngClass]="{'selected': selectedDay === 21}"  class="btn btn-primary" (click)="MarkedForClosure(21);selectDay(21)" ><fa-icon [icon]="['fas', 'calendar']"></fa-icon> 21 Days</button>
					<button type="button"  [ngClass]="{'selected': selectedDay === 28}"  class="btn btn-primary" (click)="MarkedForClosure(28);selectDay(28)" ><fa-icon [icon]="['fas', 'calendar']"></fa-icon> 28 Days</button>
				  </div>
				</div>
				<div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
					<h4 style="padding: 5px 0px;" class="float-end">Marked For Closure ({{mark_for_closure_count}})</h4>
				</div>
			</div>
			<!-- <div class="row">
				<div class="col-lg-2 col-md-2 col-sm-6 col-xs-6 btn-group">
					<button [ngClass]="{'selected': selectedDay === 7}" type="button" class="btn btn-md rounded  btn-sm btn-success mt-2" (click)="MarkedForClosure(7);selectDay(7)" >
						<fa-icon [icon]="['fas', 'calendar']"></fa-icon> 7 Days
					</button>
				</div>
				<div class="col-lg-2 col-md-2 col-sm-6 col-xs-6 btn-group">
					<button [ngClass]="{'selected': selectedDay === 14}" type="button" class="btn btn-md rounded  btn-sm btn-success mt-2" (click)="MarkedForClosure(14);selectDay(14)" >
						<fa-icon [icon]="['fas', 'calendar']"></fa-icon> 14 Days
					</button>
				</div>
				<div class="col-lg-2 col-md-2 col-sm-6 col-xs-6 btn-group">
					<button [ngClass]="{'selected': selectedDay === 21}" type="button" class="btn btn-md rounded  btn-sm btn-success mt-2" (click)="MarkedForClosure(21);selectDay(21)" >
						<fa-icon [icon]="['fas', 'calendar']"></fa-icon> 21 Days
					</button>
				</div>
				<div class="col-lg-2 col-md-2 col-sm-6 col-xs-6 btn-group">
					<button [ngClass]="{'selected': selectedDay === 28}" type="button" class="btn btn-md rounded  btn-sm btn-success mt-2" (click)="MarkedForClosure(28);selectDay(28)" >
						<fa-icon [icon]="['fas', 'calendar']"></fa-icon> 28 Days
					</button>
				</div>
				<div class="col-lg-3 col-md-3 col-sm-6 col-xs-6 btn-group">
					<h4 style="padding: 15px 0px;">Marked For Closure ({{mark_for_closure_count}})</h4>
				</div> -->
				<!-- <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6  m-auto" > -->
					<!-- <mat-checkbox  color="primary" (change)="MarkedForClosure($event)" class="example-margin">Marked For Closure (<b>{{mark_for_closure_count}}</b>)</mat-checkbox> -->
					<!-- Marked For Closure  <b>{{mark_for_closure_count}}</b> -->
					<!-- <button type="button" class="btn btn-sm btn-outline-primary">Marked For Closure ({{mark_for_closure_count}})</button>
				</div> -->
			<!-- </div> -->
			<!-- <div class="row">
				<div class="col-lg-3 col-md-3 col-sm-6 col-xs-6">
					<mat-form-field>
						<mat-label>Start Date</mat-label>
						<input matInput [matDatepicker]="start_date_picker_1" [(ngModel)]="cardsFilter.start_date">
						<mat-hint>MM/DD/YYYY</mat-hint>
						<mat-datepicker-toggle matIconSuffix [for]="start_date_picker_1"></mat-datepicker-toggle>
						<mat-datepicker #start_date_picker_1></mat-datepicker>
					</mat-form-field>
				</div>
				<div class="col-lg-3 col-md-3 col-sm-6 col-xs-6">
					<mat-form-field>
						<mat-label>End Date</mat-label>
						<input matInput [matDatepicker]="end_date_picker_1" [max]="min_apptDate"	[(ngModel)]="cardsFilter.end_date">
						<mat-hint>MM/DD/YYYY</mat-hint>
						<mat-datepicker-toggle matIconSuffix [for]="end_date_picker_1"></mat-datepicker-toggle>
						<mat-datepicker #end_date_picker_1></mat-datepicker>
					</mat-form-field>
				</div>
				<div class="col-lg-2 col-md-2 col-sm-6 col-xs-6">
					<button type="button" class="btn btn-md btn-success mt-2" (click)="refreshTable();getEventFlagDistributionByDoctors();">
						<fa-icon [icon]="['fas', 'search']"></fa-icon> Search
					</button>
				</div>
				
			</div> -->
			<div class="row">
				<div class="table-responsive">
					<table datatable [dtOptions]="dtOptions" class="table table-striped table-bordered table-hover"
						style="width: 100%;">
						<thead>
							<tr class="table_head">
								<th>Case ID</th>
								<th>Case Flag</th>
								<th>Company</th>
								<th>Vessel</th>
								<th>Rank</th>
								<th>Symptoms</th>
								<th>Initial Complaint</th>
								<th>Doctor</th>
								<th>Last Follow Up</th>
							</tr>
						</thead>
						<tbody *ngIf="dataList?.length != 0">
							<tr *ngFor="let v of dataList;let i = index;">

								<td>{{v.case_id}}</td>
								<td>{{v.case_flag}}</td>
								<td>{{v.company_name}}</td>
								<td>{{v.vessel}}</td>
								<td>{{v.rank}}</td>
								<td>{{v.symptoms}}</td>
								<td>{{v.initial_complaint}}</td>
								<td>{{v.doctor_name}}</td>
								<td>{{v.last_follow_up}}</td>
							</tr>
						</tbody>
						<tbody *ngIf="dataList?.length == 0">
							<tr>
								<td colspan="12" class="text-center">No data found!</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-lg-12 col-md-12">
			<div class="card mb-3">
				<div class="card-header bg-primary">
					<h4 class="text-light">Case Count / Doctor</h4>
				</div>
				<div class="card-body">
					<div>
						<p class="text-center" *ngIf="top5CasesLoader">
							<!-- <fa-icon [icon]="['fas', 'spinner']" animation="spin" size="3x"></fa-icon> -->
							<svg width="44" height="44" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><style>.spinner_9y7u{animation:spinner_fUkk 2.4s linear infinite;animation-delay:-2.4s}.spinner_DF2s{animation-delay:-1.6s}.spinner_q27e{animation-delay:-.8s}@keyframes spinner_fUkk{8.33%{x:13px;y:1px}25%{x:13px;y:1px}33.3%{x:13px;y:13px}50%{x:13px;y:13px}58.33%{x:1px;y:13px}75%{x:1px;y:13px}83.33%{x:1px;y:1px}}</style><rect class="spinner_9y7u" x="1" y="1" rx="1" width="10" height="10"/><rect class="spinner_9y7u spinner_DF2s" x="1" y="1" rx="1" width="10" height="10"/><rect class="spinner_9y7u spinner_q27e" x="1" y="1" rx="1" width="10" height="10"/></svg>
						</p>
						<table class="table table-bordered table-hover fix-header" *ngIf="!top5CasesLoader">
							<thead>
								<tr>
									<th>Name</th>
									<th>Code Blue</th>
									<th>Code Red</th>
									<th>Improving</th>
									<th>Routine</th>
									<th>Not Assigned</th>
								</tr>
							</thead>
							<tbody>
								@for (v of EventFlagDistribution; track $index) {
								  <tr>
										<td>{{v.first_name}} {{v.last_name}}</td>
										<td>{{v.code_blue}}</td>
										<td>{{v.code_red}}</td>
										<td>{{v.improving}}</td>
										<td>{{v.routine}}</td>
										<td>{{v.not_assigned}}</td>
									</tr>
								}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>
@if (user_type=='cdm') {
@if(UserModuleAccess!='Full'){
<h3 class="text-center">You don't have access to this section.</h3>
}
} @else if(user_type=='company' || user_type=='fleet') {
@if(UserModuleAccess!='Full' || isCompanyIdNull){
<h3 class="text-center">Service not subscribed.</h3>
}
}