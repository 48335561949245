import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from '../../api.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
import Swal from 'sweetalert2';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { emailPattern, mobilePattern, noWhitespaceInRequiredValidator } from '../../shared/constants';
import { map, Observable, startWith } from 'rxjs';

@Component({
	selector: 'app-vessel-create',
	templateUrl: './vessel-create.component.html',
	styleUrl: './vessel-create.component.css'
})
export class VesselCreateComponent implements OnInit, AfterViewInit {
	breadcrumbList = [
		{
			path: this.apiService.getHomeURL(),
			name: "Home"
		},
		/* {
			path: '/app/dashboard-mdm',
			name: "MDM"
		}, */
		{
			path: "/app/vessel",
			name: "Vessels"
		}
	];
	uId: any = null;
	pagetitle = "Create Vessel";
	moduleForm!: FormGroup;
	showLoader: boolean = false;
	showDeleteLoader: boolean = false;
	pageLoading: boolean = false;
	formSubmitted: boolean = false;
	details: any = null;
	customError: any = {};
	UserModuleAccess: any = "Full";
	hideP = true;
	vesselTypeList: any = [];
	filteredVesselTypes!: Observable<any[]>;
	companyList: any = [];
	fleetList: any = [];
	ownerList: any = [];
	pocList: any = [];
	requiredValidator = Validators.required;
	user_type: any = "";
	accessLoader = false;

	constructor(private toastr: ToastrService, private apiService: ApiService, private formBuilder: FormBuilder, private router: Router, private activeRoute: ActivatedRoute) {
		this.accessLoader = true;
		this.apiService.getUserAccess().subscribe((data: any) => {
			this.accessLoader = false;
			try {
				this.user_type = data.res[0].user_type;
				/* let accessObj: any = JSON.parse(data.res[0].UserModuleAccess);
				this.UserModuleAccess = accessObj["USER_Module"]; */
			} catch (error) { }
		}, (error: any) => {
			this.accessLoader = false;
			/* this.UserModuleAccess = "NoAccess"; */
		});
	}
	ngAfterViewInit(): void { }

	ngOnInit(): void {
		this.activeRoute.params.subscribe(routeParams => {
			this.uId = routeParams["uId"] == undefined ? null : routeParams["uId"];
			this.moduleForm = this.formBuilder.group({
				name: [null, [this.requiredValidator, noWhitespaceInRequiredValidator]],
				type: [null, [this.requiredValidator, noWhitespaceInRequiredValidator]],
				route: [null],
				email: [null, [this.requiredValidator, noWhitespaceInRequiredValidator, Validators.pattern(emailPattern)]],
				phone_number: [null/* , [this.requiredValidator, noWhitespaceInRequiredValidator] */],
				callSign: [null/* , [this.requiredValidator, noWhitespaceInRequiredValidator] */],
				ImoNo: [null, [this.requiredValidator, noWhitespaceInRequiredValidator]],
				MMSI: [null, [this.requiredValidator, noWhitespaceInRequiredValidator]],
				company: [null, [this.requiredValidator, noWhitespaceInRequiredValidator]],
				fleet: [null, [this.requiredValidator, noWhitespaceInRequiredValidator]],
				vessel_owner: [null/* , [this.requiredValidator, noWhitespaceInRequiredValidator] */],
				contact_person: [null],
				contact_person_hc: [null],
			});
			this.f.contact_person.disable();
			this.f.contact_person_hc.disable();
			this.filteredVesselTypes = this.f.type.valueChanges.pipe(
				startWith(''),
				map(value => this._filter(value || '')),
			);
			this.getPOCList();
			this.getCompanyList();
			this.getOwnerList();
			this.getVesselTypes();
			if (this.uId) {
				this.breadcrumbList.push({ path: "/app/edit-vessel/" + this.uId, name: "Edit Vessel" });
				this.pagetitle = "Edit Vessel";
				this.pageLoading = true;
				this.getDetails();
			} else {
				this.breadcrumbList.push({ path: "/app/create-vessel", name: "Add Vessel" });
				this.pageLoading = true;
			}
		});
	}

	private _filter(value: string): string[] {
		const filterValue = value.toLowerCase();
		return this.vesselTypeList.filter(option => option.toLowerCase().includes(filterValue));
	}

	formChanged() {
		this.moduleForm.markAsDirty();
	}

	showError(field: string) {
		if (this.f[field].invalid && (this.formSubmitted || this.f[field].touched || this.f[field].dirty)) {
			return true;
		}
		return false;
	}

	getDetails() {
		this.apiService.getVesselDetails({ "id": this.uId }).subscribe((data: any) => {
			this.details = data.data;
			this.f.name.setValue(this.details.name);
			this.f.type.setValue(this.details.type);
			this.f.route.setValue(this.details.route);
			this.f.email.setValue(this.details.email);
			let phone_number: any;
			try {
				phone_number = JSON.parse(this.details.phone_number);
			} catch (error) {
				phone_number = [];
			}
			this.f.phone_number.setValue(phone_number);
			this.f.callSign.setValue(this.details.callSign);
			this.f.ImoNo.setValue(this.details.ImoNo);
			this.f.MMSI.setValue(this.details.MMSI);
			this.f.company.setValue(this.details.company ? this.details.company.id : null);
			this.f.fleet.setValue(this.details.fleet ? this.details.fleet : null);
			this.f.vessel_owner.setValue(this.details.vessel_owner ? this.details.vessel_owner.id : null);
			this.getFleetList(false);
			this.getFleetDetails();
			let contact_person_hc: any;
			try {
				contact_person_hc = this.details.hc_poc;
				for (let i = 0; i < contact_person_hc.length; i++) {
					contact_person_hc[i] = parseInt(contact_person_hc[i]);
				}
			} catch (error) {
				contact_person_hc = [];
			}
			this.f.contact_person_hc.setValue(contact_person_hc);
		}, (error) => {
			this.pageLoading = false;
			if (error.status == 401) { return }
			this.toastr.error("Unable to fetch details.", 'Error', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
		});
	}

	getPOCList() {
		this.apiService.getPocDetails({}).subscribe((data: any) => {
			data.data.map((person: any) => {
				try {
					person.full_name = person.f_name + " " + person.l_name;
				} catch (error) {
					person.full_name = person.f_name;
				}
				return person;
			})
			this.pocList = data.data;
		}, (error) => {
			this.pocList = [];
		});
	}

	getFleetDetails() {
		if (this.f.fleet.value) {
			this.apiService.getFleetDetails({ "id": this.f.fleet.value.id1 }).subscribe((data: any) => {
				let contact_person: any;
				try {
					contact_person = JSON.parse(data.data.contact_person);
				} catch (error) {
					contact_person = [];
				}
				this.f.contact_person.setValue(contact_person);
			}, (error) => {
				this.pageLoading = false;
				if (error.status == 401) { return }
				this.toastr.error("Unable to fetch details.", 'Error', {
					timeOut: 3000,
					tapToDismiss: true,
					closeButton: true
				});
			});
		} else {
			this.f.contact_person.setValue(null);
		}
	}

	phonePatternValidator(control: FormControl) {
		const regex = /(^(\+ *[0-9]+)([,0-9 ]*)([0-9 ])*$)|(^ *$)/;
		if (!control.value.match(regex)) {
			return {
				'phnerr': true
			};
		}
		return null;
	}

	getOwnerList() {
		this.apiService.getOwnerDetails({}).subscribe((data: any) => {
			this.ownerList = data.data;
		}, (error) => {
			this.ownerList = [];
		});
	}

	getCompanyList() {
		this.apiService.getCompanyDetails({}).subscribe((data: any) => {
			this.companyList = data.data;
		}, (error) => {
			this.companyList = [];
		});
	}

	getVesselTypes() {
		this.apiService.getVesselTypeDetails({}).subscribe((data: any) => {
			this.vesselTypeList = data.map((vt: any) => vt.name);
		}, (error) => {
			this.vesselTypeList = [];
		});
	}

	getFleetList(clearFleet: boolean) {
		if (clearFleet) {
			this.f.fleet.setValue(null);
		}
		if (this.f.company.value) {
			this.apiService.getFleetsByCompany({ id: this.f.company.value }).subscribe((data: any) => {
				this.fleetList = data.data;
			}, (error) => {
				this.fleetList = [];
			});
		} else {
			this.fleetList = [];
		}
	}

	get f() { return this.moduleForm.controls; }

	clearCustomError() {
		Object.keys(this.f).forEach(key => {
			if (this.f[key].hasError('incorrect')) {
				try {
					delete this.f[key].errors?.incorrect;
					this.f[key].updateValueAndValidity();
				} catch (error) {

				}

			}
		});
		this.customError = {};
	}

	saveData() {
		this.formSubmitted = true;
		this.clearCustomError();
		if (this.moduleForm.invalid) {
			this.toastr.info("Please check required parameters.", 'Warning', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
			return;
		}
		let phone_number: any;
		try {
			phone_number = JSON.stringify(this.f.phone_number.value);
		} catch (error) {
			phone_number = JSON.stringify([]);
		}
		let payload: any = {
			name: this.f.name.value,
			type: this.f.type.value,
			route: this.f.route.value,
			email: this.f.email.value,
			phone_number: phone_number,
			callSign: this.f.callSign.value,
			ImoNo: this.f.ImoNo.value,
			MMSI: this.f.MMSI.value,
			company: this.f.company.value,
			fleet: this.f.fleet.value ? this.f.fleet.value.id : null,
			vessel_owner: this.f.vessel_owner.value,
		};
		if (this.uId) {
			payload["id"] = this.uId;
		}
		this.showLoader = true;
		this.apiService.createUpdateVessel(payload).subscribe(data => {
			this.showLoader = false;
			this.toastr.success("Saved successfully.", 'Success', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
			this.gotoList();
		}, (error: any) => {
			this.showLoader = false;
			if (error.status == 401) { return }
			if (error.status == 409) {
				Swal.fire({
					title: 'Already Exists',
					html: 'Vessel already exists. Do you want to restore it?',
					icon: 'question',
					showCancelButton: true,
					showConfirmButton: true,
					confirmButtonText: 'Yes, Restore!',
					cancelButtonText: 'Cancel',
					confirmButtonColor: '#3e4fb1'
				}).then((result) => {
					if (result.value) {
						this.apiService.restoreVessel(error.error.id).subscribe((data: any) => {
							this.toastr.success("Vessel restored successfully", 'Success', {
								timeOut: 3000,
								tapToDismiss: true,
								closeButton: true
							});
							this.gotoList();
						}, (error) => {
							this.toastr.error("Something went wrong", 'Error', {
								timeOut: 3000,
								tapToDismiss: true,
								closeButton: true
							});
						});
					} else if (result.dismiss === Swal.DismissReason.cancel) { }
				})
			} else {
				let errorMsg: any = "Something went wrong.";
				if (error.status == 400) {
					errorMsg = error.error;
					if (typeof errorMsg == 'object') {
						for (const [key, value] of Object.entries(errorMsg)) {
							let val: any = value;
							if (val == "This field must be unique.") {
								val = "Already exists";
							}
							if (this.moduleForm.controls[key]) {
								this.moduleForm.controls[key].setErrors({ incorrect: true });
								this.customError[key] = val;
								let selector = "#" + key;
								try {
									$('html, body').animate({
										scrollTop: $(selector).offset().top - 60 + 'px'
									}, 'fast');
								} catch (error) { }
								$(selector).focus();
							} else {
								this.toastr.error(key + ":" + val, 'Error', {
									timeOut: 3000,
									tapToDismiss: true,
									closeButton: true
								});
							}

						}
						return;
					}
				} else {
					errorMsg = error.error;
				}
				this.toastr.error(errorMsg, 'Error', {
					timeOut: 3000,
					tapToDismiss: true,
					closeButton: true
				});
			}
		});
	}

	gotoList() {
		this.router.navigate(['/app/vessel']);
	}
}
