import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { ActivatedRoute } from '@angular/router';
import { formatAmount } from '../shared/constants';
import * as FileSaver from 'file-saver';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss', '../../assets/styles/dashboard-styles.scss']
})
export class DashboardComponent implements OnInit {
	inventoryDashboardUrl = this.apiService.getDashboardURL("inventory");
	mhsDashboardUrl = this.apiService.getDashboardURL("mhs");
	hcDashboardUrl = this.apiService.getDashboardURL("hcDash");
	clipDashboardUrl = this.apiService.getDashboardURL("clip");

	currencyList: any = [];
	yearList: any[] = [];
	paidUnpaidList = [
		{ id: true, name: "Paid / Received" },
		{ id: false, name: "Unpaid / Billed" }
	];
	filter: any = {
		is_paid: null,
		currency: "INR",
		year: new Date().getFullYear()
	}
	totals = {
		total_expense: 0,
		total_clip_expense: 0,
		total_pharma_expense: 0,
		total_freight_expense: 0,
		total_overhead_expense: 0,

		total_sales: 0,
		total_clip_shorecare: 0,
		total_clip_sales: 0,
		total_pharma_sales: 0,
		total_mhs_sales: 0,
		total_hc_sales: 0,
		total_training_sales: 0,
		total_tally_sales: 0,
	}
	formatAmount = formatAmount;

	getFilter() {
		let data: any = {};
		if (this.filter.is_paid != null) {
			data.is_paid = this.filter.is_paid;
		}
		if (this.filter.currency != null) {
			data.currency = this.filter.currency;
		}
		if (this.filter.year != null) {
			data.year = this.filter.year;
		} else {
			data.year = new Date().getFullYear();
		}
		return data;
	}
	UserModuleAccess: any = "Full";
	expense_last_updated: any = {}
	sales_last_updated: any = {}

	constructor(private apiService: ApiService, private activeRoute: ActivatedRoute, private toastr: ToastrService) {
		this.apiService.getUserAccess().subscribe((data: any) => {
			try {
				let accessObj: any = JSON.parse(data.res[0].UserModuleAccess);
				this.UserModuleAccess = accessObj["CDM_dashboard"];
			} catch (error) { }
		}, (error: any) => {
			this.UserModuleAccess = "NoAccess";
		});
	}

	ngOnInit(): void {
		this.activeRoute.params.subscribe(routeParams => {
			let yyyy = new Date().getFullYear();
			for (let i = 0; i < 10; i++) {
				let toYear: any = (yyyy + 1).toString().slice(2);
				this.yearList.push({ value: yyyy, name: yyyy + "-" + toYear });
				yyyy -= 1;
			}
			this.getCurrencyList();
			this.getAllData();
		});
	}

	getAllData() {
		this.getTotalExpenseAppWise();
		this.getTotalSalesAppWise();
	}

	getCurrencyList() {
		this.apiService.currencyList().subscribe((data: any) => {
			this.currencyList = data;
		}, (error) => {
		});
	}

	columnsTotalExpenseAppWise = ['month', 'overhead_amount', 'clip_amount', 'pharma_amount', 'freight_amount'];
	TotalExpenseAppWise: any[] = [];
	TotalExpenseAppWiseLoader = false;
	getTotalExpenseAppWise() {
		this.totals.total_expense = 0;
		this.totals.total_clip_expense = 0;
		this.totals.total_pharma_expense = 0;
		this.totals.total_freight_expense = 0;
		this.totals.total_overhead_expense = 0;
		this.TotalExpenseAppWiseLoader = true;
		this.apiService.TotalExpenseAppWise(this.getFilter()).subscribe((res: any) => {
			this.TotalExpenseAppWiseLoader = false;
			let data = res.data;
			this.expense_last_updated = res.last_updated;
			try {
				for (let i = 0; i < data.length; i++) {
					if (data[i].clip_amount) {
						this.totals.total_expense += data[i].clip_amount;
						this.totals.total_clip_expense += data[i].clip_amount;
					}
					if (data[i].pharma_amount) {
						this.totals.total_expense += data[i].pharma_amount;
						this.totals.total_pharma_expense += data[i].pharma_amount;
					}
					if (data[i].freight_amount) {
						this.totals.total_expense += data[i].freight_amount;
						this.totals.total_freight_expense += data[i].freight_amount;
					}
					if (data[i].overhead_amount) {
						this.totals.total_expense += data[i].overhead_amount;
						this.totals.total_overhead_expense += data[i].overhead_amount;
					}
				}
			} catch (error) { }
			this.TotalExpenseAppWise = data;
		}, (error) => {
			this.TotalExpenseAppWiseLoader = false;
		});
	}

	expenseExcelLoader = false;
	getExpenseExcel() {
		this.expenseExcelLoader = true;
		this.apiService.getExpenseExcel(this.getFilter()).subscribe(res => {
			let excelData: any = res.body;
			this.expenseExcelLoader = false;
			let blob = new Blob([excelData], { type: "application/ms-excel" });
			let fileName = "Expense.xlsx";
			FileSaver.saveAs(blob, fileName);
		}, (error) => {
			this.expenseExcelLoader = false;
			if (error.status == 401) { return }
			this.toastr.error("Something went wrong", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
		});
	}

	columnsTotalSalesAppWise = ['month', 'training_amount', 'shorecare_amount', 'clip_amount', 'pharma_amount', 'mhs_amount', 'hc_amount', 'tally_amount'];
	TotalSalesAppWise: any[] = [];
	TotalSalesAppWiseLoader = false;
	getTotalSalesAppWise() {
		this.totals.total_sales = 0;
		this.totals.total_clip_shorecare = 0;
		this.totals.total_clip_sales = 0;
		this.totals.total_pharma_sales = 0;
		this.totals.total_mhs_sales = 0;
		this.totals.total_hc_sales = 0;
		this.totals.total_tally_sales = 0;
		this.totals.total_training_sales = 0;
		this.TotalSalesAppWiseLoader = true;
		this.apiService.TotalSalesAppWise(this.getFilter()).subscribe((res: any) => {
			this.TotalSalesAppWiseLoader = false;
			let data = res.data;
			this.sales_last_updated = res.last_updated;
			try {
				for (let i = 0; i < data.length; i++) {
					if (data[i].shorecare_amount) {
						this.totals.total_sales += data[i].shorecare_amount;
						this.totals.total_clip_shorecare += data[i].shorecare_amount;
					}
					if (data[i].clip_amount) {
						this.totals.total_sales += data[i].clip_amount;
						this.totals.total_clip_sales += data[i].clip_amount;
					}
					if (data[i].pharma_amount) {
						this.totals.total_sales += data[i].pharma_amount;
						this.totals.total_pharma_sales += data[i].pharma_amount;
					}
					if (data[i].mhs_amount) {
						this.totals.total_sales += data[i].mhs_amount;
						this.totals.total_mhs_sales += data[i].mhs_amount;
					}
					if (data[i].hc_amount) {
						this.totals.total_sales += data[i].hc_amount;
						this.totals.total_hc_sales += data[i].hc_amount;
					}
					if (data[i].training_amount) {
						this.totals.total_sales += data[i].training_amount;
						this.totals.total_training_sales += data[i].training_amount;
					}
					if (data[i].tally_amount) {
						this.totals.total_sales += data[i].tally_amount;
						this.totals.total_tally_sales += data[i].tally_amount;
					}
				}
			} catch (error) { }
			this.TotalSalesAppWise = data;

		}, (error) => {
			this.TotalSalesAppWiseLoader = false;
		});
	}

	salesExcelLoader = false;
	getSalesExcel() {
		this.salesExcelLoader = true;
		this.apiService.getSalesExcel(this.getFilter()).subscribe(res => {
			let excelData: any = res.body;
			this.salesExcelLoader = false;
			let blob = new Blob([excelData], { type: "application/ms-excel" });
			let fileName = "Sales.xlsx";
			FileSaver.saveAs(blob, fileName);
		}, (error) => {
			this.salesExcelLoader = false;
			if (error.status == 401) { return }
			this.toastr.error("Something went wrong", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
		});
	}
}
