<breadcrumb [breadcrumbList]="breadcrumbList"></breadcrumb>
<div *ngIf="accessLoader" class="loader text-center">
	<img src="../assets/img/cc_loader.gif" />
</div>
<div class="card" *ngIf="UserModuleAccess=='Full' && user_type=='cdm'">
	<div class="card-header bg-primary">
		{{pagetitle}}
	</div>
	<div class="card-body">
		<form class="form-horizontal" [formGroup]="moduleForm" (ngSubmit)="saveData()" #modform="ngForm">
			<div class="row mb-3">
				<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
					<div class="form-floating">
						<input class="form-control" [ngClass]="{'is-invalid':showError('name')}" id="name"
							formControlName="name" placeholder="Name*">
						<label for="name">Name*</label>
					</div>
					<p *ngIf="showError('name')" class="text-danger">
						<span *ngIf="f.name.errors?.required">
							Required
						</span>
						<span *ngIf="f.name.errors?.incorrect">
							{{customError.name}}
						</span>
					</p>
				</div>
				<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
					<div class="form-floating">
						<input class="form-control" [ngClass]="{'is-invalid':showError('email')}" id="email"
							formControlName="email" placeholder="Email">
						<label for="email">Email</label>
					</div>
					<p *ngIf="showError('email')" class="text-danger">
						<span *ngIf="f.email.errors?.required">
							Required
						</span>
						<span *ngIf="f.email.errors?.pattern">
							Invalid
						</span>
						<span *ngIf="f.email.errors?.incorrect">
							{{customError.email}}
						</span>
					</p>
				</div>
				<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
					<div class="form-floating">
						<input class="form-control" [ngClass]="{'is-invalid':showError('contact_no')}" id="contact_no"
							formControlName="contact_no" placeholder="Contact No.">
						<label for="contact_no">Contact No.</label>
					</div>
					<p *ngIf="showError('contact_no')" class="text-danger">
						<span *ngIf="f.contact_no.errors?.required">
							Required
						</span>
						<span *ngIf="f.contact_no.errors?.pattern">
							Invalid
						</span>
						<span *ngIf="f.contact_no.errors?.incorrect">
							{{customError.contact_no}}
						</span>
					</p>
				</div>
				<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
					<div class="form-floating">
						<input class="form-control" [ngClass]="{'is-invalid':showError('contact_person')}"
							id="contact_person" formControlName="contact_person" placeholder="Contact Person">
						<label for="contact_person">Contact Person</label>
					</div>
					<p *ngIf="showError('contact_person')" class="text-danger">
						<span *ngIf="f.contact_person.errors?.required">
							Required
						</span>
						<span *ngIf="f.contact_person.errors?.incorrect">
							{{customError.contact_person}}
						</span>
					</p>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
					<div class="form-floating">
						<input class="form-control" [ngClass]="{'is-invalid':showError('address')}" id="address"
							formControlName="address" placeholder="Address">
						<label for="address">Address</label>
					</div>
					<p *ngIf="showError('address')" class="text-danger">
						<span *ngIf="f.address.errors?.required">
							Required
						</span>
						<span *ngIf="f.address.errors?.incorrect">
							{{customError.address}}
						</span>
					</p>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col text-center">
					<button type="submit" [disabled]="showLoader || !modform.dirty"
						class="btn btn-sm btn-outline-success">
						<span class="me-1">
							<fa-icon *ngIf="showLoader" [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
							<fa-icon *ngIf="!showLoader" [icon]="['fas', 'save']"></fa-icon>
						</span>
						<span>Save</span>
					</button>
					<a class="btn btn-sm btn-outline-dark ms-1" [routerLink]="['/app/owners']">Cancel</a>
				</div>
			</div>
		</form>
	</div>
</div>
<div *ngIf="UserModuleAccess!='Full' || user_type!='cdm'">
	<h3 class="text-center">You don't have access to this section.</h3>
</div>