import { Subscription, ReplaySubject, Subject, Observable, fromEvent, of } from 'rxjs';
import { Component, OnInit, Input, OnDestroy, ViewChild, AfterViewInit, Inject, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from '@angular/common';
import { ApiService, HttpParamsOptions } from '../../api.service';
import { ToastrService } from 'ngx-toastr';
import { EventEmitter, Output } from '@angular/core';
declare var $: any;
import { debounceTime } from "rxjs/operators";
import { DataTableDirective } from 'angular-datatables';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import * as FileSaver from 'file-saver';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl, ValidatorFn, AbstractControl, ValidationErrors, AsyncValidatorFn } from '@angular/forms';
import { Config } from 'datatables.net';
import Swal from 'sweetalert2';
import { password_validation } from '../../shared/constants';

class DataTablesResponse {
	data!: any[];
	draw!: number;
	recordsFiltered!: number;
	recordsTotal!: number;
}

@Component({
	selector: 'app-mdm-user-list',
	templateUrl: './mdm-user-list.component.html',
	styleUrl: './mdm-user-list.component.css'
})
export class MdmUserListComponent implements OnInit, OnDestroy, AfterViewInit {
	breadcrumbList: any[] = [
		{
			path: this.apiService.getHomeURL(),
			name: "Home"
		},
		/* {
			path: '/app/dashboard-mdm',
			name: "MDM"
		}, */
		{
			path: "/app/mdm-users",
			name: "MDM Users"
		}
	];

	@ViewChild(DataTableDirective, { static: false }) datatableElement!: DataTableDirective;
	baseURL = environment.API_URL + "mdm/";
	dtOptions: Config = {};
	dataList: any = [];
	showDeleteLoader: any = null;
	showDeleteLoaderHC: any = null;
	showCreateLoaderHC: any = null;
	showDeleteLoaderCLIP: any = null;
	showCreateLoaderCLIP: any = null;
	showDeleteLoaderMHS: any = null;
	showCreateLoaderMHS: any = null;
	showDeleteLoaderPharma: any = null;
	showCreateLoaderPharma: any = null;
	showDeleteLoaderTraining: any = null;
	showCreateLoaderTraining: any = null;
	pagetitle = "MDM Users List";
	pageLoading = false;
	allFlagStates: any[] = [];
	flagState: any = null;
	UserModuleAccess: any = "Full";
	passwordId: any;
	resetPasswordForm!: FormGroup;
	showReset2FALoader: any = null;
	user_type: any = "";
	accessLoader = false;

	constructor(private formBuilder: FormBuilder, private apiService: ApiService, private activeRoute: ActivatedRoute, private toastr: ToastrService, private http: HttpClient) {
		this.accessLoader = true;
		this.apiService.getUserAccess().subscribe((data: any) => {
			this.accessLoader = false;
			try {
				this.user_type = data.res[0].user_type;
				let accessObj: any = JSON.parse(data.res[0].UserModuleAccess);
				this.UserModuleAccess = accessObj["USER_Module"];
			} catch (error) { }
		}, (error: any) => {
			this.accessLoader = false;
			this.UserModuleAccess = "NoAccess";
		});
	}

	ngOnInit(): void {
		this.activeRoute.params.subscribe(routeParams => {
			this.initDatatable();
			this.resetPasswordForm = this.formBuilder.group({
				password: ['', [Validators.required, password_validation]],
				confirmPassword: ['', [Validators.required]],
			}, {
				validators: this.passwordMatchValidator.bind(this)
			});
		});
	}

	ngOnDestroy(): void {

	}

	ngAfterViewInit(): void {

	}

	initDatatable() {
		this.dtOptions = {
			pagingType: 'full_numbers',
			serverSide: true,
			processing: true,
			ordering: false,
			ajax: (dataTablesParameters: any, callback) => {
				let data: any = {
					draw: dataTablesParameters.draw,
					start: dataTablesParameters.start,
					length: dataTablesParameters.length,
					searchVal: dataTablesParameters.search.value
				}
				const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
				this.http.get<DataTablesResponse>(this.baseURL + "mdm_Users", { params: new HttpParams(httpParams) }).subscribe((response: any) => {
					this.dataList = response.data;
					callback({
						recordsTotal: response.recordsTotal,
						recordsFiltered: response.recordsFiltered,
						data: []
					});
				}, (error: any) => {
					if (error.status == 401) { return }
					this.toastr.error("Something went wrong.", 'Error!!!', {
						timeOut: 3000,
						tapToDismiss: true,
						closeButton: true
					});
				});
			}
		}
	}

	deleteConfirm(uId: any, index: number) {
		Swal.fire({
			title: 'Warning !!!',
			html: 'Are you sure you want to delete user <p style="text-align:center;font-size: 15px;"><strong>' + this.dataList[index].first_name + " " + this.dataList[index].last_name + '</strong>?</p>',
			icon: 'warning',
			showCancelButton: true,
			showConfirmButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#dc3545'
		}).then((result) => {
			if (result.value) {
				this.showDeleteLoader = index;
				this.apiService.deleteMDMUser({ id: uId }).subscribe(data => {
					this.showDeleteLoader = null;
					Swal.fire('Success', 'User deleted successfully.', 'success');
					this.refreshTable();
				}, (error) => {
					this.showDeleteLoader = null;
					if (error.status == 401) { return }
					if (error.status == 404) {
						Swal.fire('Error', error.error, 'error');
					} else {
						Swal.fire('Error', 'Something went wrong.', 'error');
					}
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}

	deleteConfirmHC(uId: any, index: number) {
		let platform = "Health Cube";
		Swal.fire({
			title: 'Warning !!!',
			html: 'Are you sure you want to delete user <p style="text-align:center;font-size: 15px;"><strong>' + this.dataList[index].first_name + " " + this.dataList[index].last_name + '</strong> from ' + platform + '?</p>',
			icon: 'warning',
			showCancelButton: true,
			showConfirmButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#dc3545'
		}).then((result) => {
			if (result.value) {
				this.showDeleteLoaderHC = index;
				this.apiService.deleteHCUser({ id: uId }).subscribe(data => {
					this.showDeleteLoaderHC = null;
					Swal.fire('Success', 'User deleted successfully from ' + platform + '.', 'success');
					this.refreshTable();
				}, (error) => {
					this.showDeleteLoaderHC = null;
					if (error.status == 401) { return }
					if (error.status == 404) {
						Swal.fire('Error', error.error, 'error');
					} else {
						Swal.fire('Error', 'Something went wrong.', 'error');
					}
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}

	createConfirmHC(uId: any, index: number) {
		let platform = "Health Cube";
		Swal.fire({
			title: 'Warning !!!',
			html: 'Are you sure you want to create user <p style="text-align:center;font-size: 15px;"><strong>' + this.dataList[index].first_name + " " + this.dataList[index].last_name + '</strong> in ' + platform + '?</p>',
			icon: 'warning',
			showConfirmButton: true,
			showCancelButton: true,
			confirmButtonText: 'Yes, create!',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#198754'
		}).then((result) => {
			if (result.value) {
				this.showCreateLoaderHC = index;
				this.apiService.createUpdateUserInHC({ id: uId, UserAccess: JSON.stringify({}) }).subscribe(data => {
					this.showCreateLoaderHC = null;
					Swal.fire('Success', 'User created successfully in ' + platform + '.', 'success');
					this.refreshTable();
				}, (error) => {
					this.showCreateLoaderHC = null;
					if (error.status == 401) { return }
					if (error.status == 404 || error.status == 400) {
						Swal.fire('Error', error.error.error ? error.error.error : 'Something went wrong.', 'error');
					} else {
						Swal.fire('Error', 'Something went wrong.', 'error');
					}
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}

	deleteConfirmCLIP(uId: any, index: number) {
		let platform = "CLIP";
		Swal.fire({
			title: 'Warning !!!',
			html: 'Are you sure you want to delete user <p style="text-align:center;font-size: 15px;"><strong>' + this.dataList[index].first_name + " " + this.dataList[index].last_name + '</strong> from ' + platform + '?</p>',
			icon: 'warning',
			showCancelButton: true,
			showConfirmButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#dc3545'
		}).then((result) => {
			if (result.value) {
				this.showDeleteLoaderCLIP = index;
				this.apiService.deleteCLIPUser({ id: uId }).subscribe(data => {
					this.showDeleteLoaderCLIP = null;
					Swal.fire('Success', 'User deleted successfully from ' + platform + '.', 'success');
					this.refreshTable();
				}, (error) => {
					this.showDeleteLoaderCLIP = null;
					if (error.status == 401) { return }
					if (error.status == 404) {
						Swal.fire('Error', error.error, 'error');
					} else {
						Swal.fire('Error', 'Something went wrong.', 'error');
					}
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}

	createConfirmCLIP(uId: any, index: number) {
		let platform = "CLIP";
		Swal.fire({
			title: 'Warning !!!',
			html: 'Are you sure you want to create user <p style="text-align:center;font-size: 15px;"><strong>' + this.dataList[index].first_name + " " + this.dataList[index].last_name + '</strong> in ' + platform + '?</p>',
			icon: 'warning',
			showConfirmButton: true,
			showCancelButton: true,
			confirmButtonText: 'Yes, create!',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#198754'
		}).then((result) => {
			if (result.value) {
				this.showCreateLoaderCLIP = index;
				this.apiService.createUpdateUserInClip({ id: uId, UserAccess: JSON.stringify({}) }).subscribe(data => {
					this.showCreateLoaderCLIP = null;
					Swal.fire('Success', 'User created successfully in ' + platform + '.', 'success');
					this.refreshTable();
				}, (error) => {
					this.showCreateLoaderCLIP = null;
					if (error.status == 401) { return }
					if (error.status == 404 || error.status == 400) {
						Swal.fire('Error', error.error.error ? error.error.error : 'Something went wrong.', 'error');
					} else {
						Swal.fire('Error', 'Something went wrong.', 'error');
					}
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}

	deleteConfirmMHS(uId: any, index: number) {
		let platform = "MHS";
		Swal.fire({
			title: 'Warning !!!',
			html: 'Are you sure you want to delete user <p style="text-align:center;font-size: 15px;"><strong>' + this.dataList[index].first_name + " " + this.dataList[index].last_name + '</strong> from ' + platform + '?</p>',
			icon: 'warning',
			showCancelButton: true,
			showConfirmButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#dc3545'
		}).then((result) => {
			if (result.value) {
				this.showDeleteLoaderMHS = index;
				this.apiService.deleteMHSUser({ id: uId }).subscribe(data => {
					this.showDeleteLoaderMHS = null;
					Swal.fire('Success', 'User deleted successfully from ' + platform + '.', 'success');
					this.refreshTable();
				}, (error) => {
					this.showDeleteLoaderMHS = null;
					if (error.status == 401) { return }
					if (error.status == 404) {
						Swal.fire('Error', error.error, 'error');
					} else {
						Swal.fire('Error', 'Something went wrong.', 'error');
					}
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}

	createConfirmMHS(uId: any, index: number) {
		let platform = "MHS";
		Swal.fire({
			title: 'Warning !!!',
			html: 'Are you sure you want to create user <p style="text-align:center;font-size: 15px;"><strong>' + this.dataList[index].first_name + " " + this.dataList[index].last_name + '</strong> in ' + platform + '?</p>',
			icon: 'warning',
			showConfirmButton: true,
			showCancelButton: true,
			confirmButtonText: 'Yes, create!',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#198754'
		}).then((result) => {
			if (result.value) {
				this.showCreateLoaderMHS = index;
				this.apiService.createUpdateUserInMHS({ id: uId }).subscribe(data => {
					this.showCreateLoaderMHS = null;
					Swal.fire('Success', 'User created successfully in ' + platform + '.', 'success');
					this.refreshTable();
				}, (error) => {
					this.showCreateLoaderMHS = null;
					if (error.status == 401) { return }
					if (error.status == 404 || error.status == 400) {
						Swal.fire('Error', error.error.error ? error.error.error : 'Something went wrong.', 'error');
					} else {
						Swal.fire('Error', 'Something went wrong.', 'error');
					}
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}

	deleteConfirmPharma(uId: any, index: number) {
		let platform = "Pharma";
		Swal.fire({
			title: 'Warning !!!',
			html: 'Are you sure you want to delete user <p style="text-align:center;font-size: 15px;"><strong>' + this.dataList[index].first_name + " " + this.dataList[index].last_name + '</strong> from ' + platform + '?</p>',
			icon: 'warning',
			showCancelButton: true,
			showConfirmButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#dc3545'
		}).then((result) => {
			if (result.value) {
				this.showDeleteLoaderPharma = index;
				this.apiService.deletePharmaUser({ id: uId }).subscribe(data => {
					this.showDeleteLoaderPharma = null;
					Swal.fire('Success', 'User deleted successfully from ' + platform + '.', 'success');
					this.refreshTable();
				}, (error) => {
					this.showDeleteLoaderPharma = null;
					if (error.status == 401) { return }
					if (error.status == 404) {
						Swal.fire('Error', error.error, 'error');
					} else {
						Swal.fire('Error', 'Something went wrong.', 'error');
					}
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}

	createConfirmPharma(uId: any, index: number) {
		let platform = "Pharma";
		Swal.fire({
			title: 'Warning !!!',
			html: 'Are you sure you want to create user <p style="text-align:center;font-size: 15px;"><strong>' + this.dataList[index].first_name + " " + this.dataList[index].last_name + '</strong> in ' + platform + '?</p>',
			icon: 'warning',
			showConfirmButton: true,
			showCancelButton: true,
			confirmButtonText: 'Yes, create!',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#198754'
		}).then((result) => {
			if (result.value) {
				this.showCreateLoaderPharma = index;
				this.apiService.createUpdateUserInPharma({ id: uId, UserAccess: JSON.stringify({}) }).subscribe(data => {
					this.showCreateLoaderPharma = null;
					Swal.fire('Success', 'User created successfully in ' + platform + '.', 'success');
					this.refreshTable();
				}, (error) => {
					this.showCreateLoaderPharma = null;
					if (error.status == 401) { return }
					if (error.status == 404 || error.status == 400) {
						Swal.fire('Error', error.error.error ? error.error.error : 'Something went wrong.', 'error');
					} else {
						Swal.fire('Error', 'Something went wrong.', 'error');
					}
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}

	deleteConfirmTraining(uId: any, index: number) {
		let platform = "Training";
		Swal.fire({
			title: 'Warning !!!',
			html: 'Are you sure you want to delete user <p style="text-align:center;font-size: 15px;"><strong>' + this.dataList[index].first_name + " " + this.dataList[index].last_name + '</strong> from ' + platform + '?</p>',
			icon: 'warning',
			showCancelButton: true,
			showConfirmButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#dc3545'
		}).then((result) => {
			if (result.value) {
				this.showDeleteLoaderTraining = index;
				this.apiService.deleteTrainingUser({ id: uId }).subscribe(data => {
					this.showDeleteLoaderTraining = null;
					Swal.fire('Success', 'User deleted successfully from ' + platform + '.', 'success');
					this.refreshTable();
				}, (error) => {
					this.showDeleteLoaderTraining = null;
					if (error.status == 401) { return }
					if (error.status == 404) {
						Swal.fire('Error', error.error, 'error');
					} else {
						Swal.fire('Error', 'Something went wrong.', 'error');
					}
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}

	createConfirmTraining(uId: any, index: number) {
		let platform = "Training";
		Swal.fire({
			title: 'Warning !!!',
			html: 'Are you sure you want to create user <p style="text-align:center;font-size: 15px;"><strong>' + this.dataList[index].first_name + " " + this.dataList[index].last_name + '</strong> in ' + platform + '?</p>',
			icon: 'warning',
			showConfirmButton: true,
			showCancelButton: true,
			confirmButtonText: 'Yes, create!',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#198754'
		}).then((result) => {
			if (result.value) {
				this.showCreateLoaderTraining = index;
				this.apiService.createUpdateUserInTraining({ id: uId, UserAccess: JSON.stringify({}) }).subscribe(data => {
					this.showCreateLoaderTraining = null;
					Swal.fire('Success', 'User created successfully in ' + platform + '.', 'success');
					this.refreshTable();
				}, (error) => {
					this.showCreateLoaderTraining = null;
					if (error.status == 401) { return }
					if (error.status == 404 || error.status == 400) {
						Swal.fire('Error', error.error.error ? error.error.error : 'Something went wrong.', 'error');
					} else {
						Swal.fire('Error', 'Something went wrong.', 'error');
					}
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}

	refreshTable() {
		this.datatableElement.dtInstance.then((dtInstance) => {
			dtInstance.draw();
		});
	}

	passwordMatchValidator(formGroup: FormGroup) {
		const password = formGroup.get('password');
		const confirmPassword = formGroup.get('confirmPassword');
		return password!.value === confirmPassword!.value ? null : { passwordNotMatch: true };
	}

	get f() { return this.resetPasswordForm.controls; }

	resetPasswordModal(id: any) {
		this.passwordId = id;
		$("#changeMDMUserPasswordModal").modal("show");
	}

	resetPasswordLoader = false;
	resetPassword() {
		if (this.resetPasswordForm.invalid) {
			return;
		}
		let data = {
			id: this.passwordId,
			password: this.f.password.value
		}
		this.resetPasswordLoader = true;
		this.apiService.changePasswordMDMUser(data).subscribe(data => {
			this.resetPasswordLoader = false;
			this.clearResetPassword();
			this.toastr.info("Password changed successfully.", 'Success!!!', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
		}, (error) => {
			this.resetPasswordLoader = false;
			this.toastr.error(error.error.error, 'Error!', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
		});
	}

	clearResetPassword() {
		$("#changeMDMUserPasswordModal").modal("hide");
		this.f.password.setValue("");
		this.f.confirmPassword.setValue("");
		this.f.password.updateValueAndValidity();
		this.f.confirmPassword.updateValueAndValidity();
		this.resetPasswordForm.markAsUntouched();
		this.resetPasswordForm.reset();
	}

	reset2FA(id: any) {
		Swal.fire({
			title: 'Warning !!!',
			text: 'Are you sure you want to reset 2 factor authentication?',
			icon: 'warning',
			showCancelButton: true,
			showConfirmButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'Cancel',
			confirmButtonColor: '#dc3545'
		}).then((result) => {
			if (result.value) {
				let data = {
					id: id,
				}
				this.showReset2FALoader = id;
				this.apiService.reset2faMDM(data).subscribe(data => {
					this.showReset2FALoader = null;
					this.toastr.info("Reset Successful.", 'Success!!!', {
						timeOut: 3000,
						tapToDismiss: true,
						closeButton: true
					});
				}, (error) => {
					this.showReset2FALoader = null;
					this.toastr.error(error.error.error, 'Error!', {
						timeOut: 3000,
						tapToDismiss: true,
						closeButton: true
					});
				});
			} else if (result.dismiss === Swal.DismissReason.cancel) { }
		})
	}
}
