<breadcrumb [breadcrumbList]="breadcrumbList"></breadcrumb>
<div class="card" *ngIf="UserModuleAccess=='Full'">
	<div class="card-header bg-primary">
		{{pagetitle}}
	</div>
	<div class="card-body">
		<form class="form-horizontal" [formGroup]="moduleForm" (ngSubmit)="saveData()" #modform="ngForm">
			<div class="row mb-3">
				<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
					<custom-input formControlName="name" [id]="'name'" [label]="'Vessel Name'"
						[required]="f.name.hasValidator(requiredValidator)" [hasError]="showError('name')"
						[errors]="f.name.errors" [customError]="customError?.name"></custom-input>
				</div>
				<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
					<div class="form-floating">
						<input class="form-control" [ngClass]="{'is-invalid':showError('type')}" id="type"
							formControlName="type" placeholder="" [matAutocomplete]="auto_vessel_type">
						<label for="type">Type*</label>
						<mat-autocomplete #auto_vessel_type="matAutocomplete">
							@for (option of filteredVesselTypes | async; track option) {
							<mat-option [value]="option">{{option}}</mat-option>
							}
						</mat-autocomplete>
					</div>
					<!-- <div class="ng-autocomplete">
						<ng-autocomplete formControlName="type" [data]="vesselTypeList" placeholder="Type" [itemTemplate]="typeItemTemplate" [notFoundTemplate]="typeNotFoundTemplate"></ng-autocomplete>
						<ng-template #typeItemTemplate let-item>
							<a [innerHTML]="item"></a>
						</ng-template>
						<ng-template #typeNotFoundTemplate let-notFound>
							<div [innerHTML]="notFound"></div>
						</ng-template>
					</div> -->
					<p *ngIf="showError('type')" class="text-danger">
						<span *ngIf="f.type.errors?.required">
							Required
						</span>
						<span *ngIf="f.type.errors?.pattern">
							Invalid
						</span>
						<span *ngIf="f.type.errors?.incorrect">
							{{customError}}
						</span>
					</p>
				</div>
				<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
					<custom-input formControlName="route" [id]="'route'" [label]="'Route'"
						[required]="f.route.hasValidator(requiredValidator)" [hasError]="showError('route')"
						[errors]="f.route.errors" [customError]="customError?.route"></custom-input>
				</div>
				<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
					<custom-input formControlName="email" [id]="'email'" [label]="'Email Id'"
						[required]="f.email.hasValidator(requiredValidator)" [hasError]="showError('email')"
						[errors]="f.email.errors" [customError]="customError?.email"></custom-input>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
					<phone-email-input formControlName="phone_number" [type]="'phone'" [id]="'phone_number'"
						[label]="'Phone Numbers'" [required]="f.phone_number.hasValidator(requiredValidator)"
						[hasError]="showError('phone_number')" [errors]="f.phone_number.errors"
						[customError]="customError?.phone_number"></phone-email-input>
				</div>
				<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
					<custom-input formControlName="callSign" [id]="'callSign'" [label]="'Call Sign'"
						[required]="f.callSign.hasValidator(requiredValidator)" [hasError]="showError('callSign')"
						[errors]="f.callSign.errors" [customError]="customError?.callSign"></custom-input>
				</div>
				<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
					<custom-input formControlName="ImoNo" [id]="'ImoNo'" [label]="'Imo No.'"
						[required]="f.ImoNo.hasValidator(requiredValidator)" [hasError]="showError('ImoNo')"
						[errors]="f.ImoNo.errors" [customError]="customError?.ImoNo"></custom-input>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
					<custom-input formControlName="MMSI" [id]="'MMSI'" [label]="'MMSI No.'"
						[required]="f.MMSI.hasValidator(requiredValidator)" [hasError]="showError('MMSI')"
						[errors]="f.MMSI.errors" [customError]="customError?.MMSI"></custom-input>
				</div>
				<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
					<ng-select appendTo="body" [items]="companyList" bindLabel="name" bindValue="id" [clearable]="true"
						[multiple]="false" id="company" formControlName="company" placeholder="Company*"
						(change)="getFleetList(true);" [appearance]="'outline'">
					</ng-select>
					<p *ngIf="showError('company')" class="text-danger">
						<span *ngIf="f.company.errors?.required">
							Required
						</span>
						<span *ngIf="f.company.errors?.incorrect">
							{{customError.company}}
						</span>
					</p>
				</div>
				<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
					<ng-select appendTo="body" [items]="fleetList" bindLabel="name" bindValue="id" [clearable]="true"
						[multiple]="false" id="fleet" formControlName="fleet" placeholder="Fleet*"
						[appearance]="'outline'">
					</ng-select>
					<p *ngIf="showError('fleet')" class="text-danger">
						<span *ngIf="f.fleet.errors?.required">
							Required
						</span>
						<span *ngIf="f.fleet.errors?.incorrect">
							{{customError.fleet}}
						</span>
					</p>
				</div>
				<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
					<ng-select appendTo="body" [items]="ownerList" bindLabel="name" bindValue="id" [clearable]="true"
						[multiple]="false" id="vessel_owner" formControlName="vessel_owner" placeholder="Owner"
						[appearance]="'outline'">
					</ng-select>
					<p *ngIf="showError('vessel_owner')" class="text-danger">
						<span *ngIf="f.vessel_owner.errors?.required">
							Required
						</span>
						<span *ngIf="f.vessel_owner.errors?.incorrect">
							{{customError.vessel_owner}}
						</span>
					</p>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col text-center">
					<button type="submit" [disabled]="showLoader || !modform.dirty"
						class="btn btn-sm btn-outline-success">
						<span class="me-1">
							<fa-icon *ngIf="showLoader" [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
							<fa-icon *ngIf="!showLoader" [icon]="['fas', 'save']"></fa-icon>
						</span>
						<span>Save</span>
					</button>
					<a class="btn btn-sm btn-outline-dark ms-1" [routerLink]="['/app/vessel']">Cancel</a>
				</div>
			</div>
		</form>
	</div>
</div>
<div *ngIf="UserModuleAccess!='Full'">
	<h3 class="text-center">You don't have access to this section.</h3>
</div>