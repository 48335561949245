<breadcrumb [breadcrumbList]="breadcrumbList"></breadcrumb>
<div class="card" *ngIf="UserModuleAccess=='Full'">
	<div class="card-body">
		<h2 class="card-title">
			{{pagetitle}}
			<a class="btn btn-sm btn-outline-success float-end" [routerLink]="['/app/create-training']">
				<fa-icon [icon]="['fas', 'plus']"></fa-icon>
				Create Training
			</a>
		</h2>
		<div class="row">
			<div class="col-md-12">
				<div class="table-responsive">
					<table datatable [dtOptions]="dtOptions" class="table table-striped table-bordered table-hover"
						style="width: 100%;">
						<thead>
							<tr class="table_head">
								<th style="width: 20% !important;">Invoice No.</th>
								<th style="width: 20% !important;">Invoice Date</th>
								<th style="width: 20% !important;">Amount</th>
								<th style="width: 15% !important;">Currency</th>
								<th style="width: 15% !important;">Paid</th>
								<th style="width: 10% !important;">Action</th>
							</tr>
						</thead>
						<tbody *ngIf="dataList?.length != 0">
							<tr *ngFor="let v of dataList;let i = index;">
								<td>{{ v.inv_no }}</td>
								<td>{{ v.inv_date }}</td>
								<td>{{ v.amount }}</td>
								<td>{{ v.currency }}</td>
								<td>
									<fa-icon [icon]="['fas', 'check']" class="text-success" *ngIf="v.is_paid"
										matTooltip="Paid"></fa-icon>
									<fa-icon [icon]="['fas', 'times']" class="text-danger" *ngIf="!v.is_paid"
										matTooltip="Unpaid"></fa-icon>
								</td>
								<td>
									<a class="btn btn-sm btn-outline-primary" matTooltip="Edit" id="btnEdit{{v.id}}"
										[routerLink]="['/app/edit-training',v.id]">
										<fa-icon [icon]="['fas', 'pencil-alt']"></fa-icon>
									</a>
									<button class="btn btn-sm btn-outline-danger ms-1" matTooltip="Delete"
										id="btnDelete{{v.id}}" (click)="deleteConfirm(v.id,i)">
										<fa-icon *ngIf="showDeleteLoader != i" [icon]="['fas', 'times']"></fa-icon>
										<fa-icon *ngIf="showDeleteLoader == i" [icon]="['fas', 'spinner']"
											animation="spin"></fa-icon>
									</button>
								</td>
							</tr>
						</tbody>
						<tbody *ngIf="dataList?.length == 0">
							<tr>
								<td colspan="7" class="text-center">No data found!</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</div>
<div *ngIf="UserModuleAccess!='Full'">
	<h3 class="text-center">You don't have access to this section.</h3>
</div>