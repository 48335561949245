import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService, HttpParamsOptions } from '../api.service';
import { ActivatedRoute } from '@angular/router';
import { formatAmount } from '../shared/constants';
import * as FileSaver from 'file-saver';
import { Config } from 'datatables.net';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { DataTableDirective } from 'angular-datatables';
import Swal from 'sweetalert2';

class DataTablesResponse {
	data!: any[];
	draw!: number;
	recordsFiltered!: number;
	recordsTotal!: number;
}

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss', '../../assets/styles/dashboard-styles.scss']
})
export class DashboardComponent implements OnInit {
	inventoryDashboardUrl = this.apiService.getDashboardURL("inventory");
	mhsDashboardUrl = this.apiService.getDashboardURL("mhs");
	hcDashboardUrl = this.apiService.getDashboardURL("hcDash");
	clipDashboardUrl = this.apiService.getDashboardURL("clip");

	currencyList: any = [];
	yearList: any[] = [];
	paidUnpaidList = [
		{ id: true, name: "Paid / Received" },
		{ id: false, name: "Unpaid / Billed" }
	];
	filter: any = {
		is_paid: null,
		currency: "INR",
		year: new Date().getFullYear()
	}
	totals = {
		total_expense: 0,
		total_clip_expense: 0,
		total_pharma_expense: 0,
		total_freight_expense: 0,
		total_overhead_expense: 0,

		total_sales: 0,
		total_clip_shorecare: 0,
		total_clip_sales: 0,
		total_pharma_sales: 0,
		total_mhs_sales: 0,
		total_hc_sales: 0,
		total_training_sales: 0,
		total_tally_sales: 0,
	}
	formatAmount = formatAmount;

	getFilter() {
		let data: any = {};
		if (this.filter.is_paid != null) {
			data.is_paid = this.filter.is_paid;
		}
		if (this.filter.currency != null) {
			data.currency = this.filter.currency;
		}
		if (this.filter.year != null) {
			data.year = this.filter.year;
		} else {
			data.year = new Date().getFullYear();
		}
		return data;
	}
	UserModuleAccess: any = "Full";
	UserModuleAccessPharma: any = "Full";
	UserModuleAccessMHS: any = "Full";
	UserModuleAccessHC: any = "Full";
	UserModuleAccessClip: any = "Full";
	expense_last_updated: any = {}
	sales_last_updated: any = {}

	cardsFilter: any = {
		company_id: null,
		fleet_id: null,
		platform: null,
		start_date: this.apiService.getDefaultDateRange1Month(false).start_date,
		end_date: this.apiService.getDefaultDateRange1Month(false).end_date
	};
	MHSCounts: any = {};
	MHSCountsLoader = false;
	CLIPCounts: any = {};
	CLIPCountsLoader = false;
	HCCounts: any = {};
	HCCountsLoader = false;
	PharmaCounts: any = {};
	PharmaCountsLoader = false;
	user_type: any = "";
	isCompanyIdNullPharma = false;
	isCompanyIdNullMHS = false;
	isCompanyIdNullHC = false;
	isCompanyIdNullClip = false;
	accessLoader = false;
	searchVal: any = "";
	dtOptions: Config = {};
	dataList: any = [];
	baseURL = environment.API_URL + "mdm/";
	@ViewChild(DataTableDirective, { static: false }) datatableElement!: DataTableDirective;
	platform_dropdown: any[] = [];
	excelLoader = false;

	constructor(private http: HttpClient, private apiService: ApiService, private activeRoute: ActivatedRoute, private toastr: ToastrService) { }

	ngOnInit(): void {
		this.activeRoute.params.subscribe(routeParams => {
			let yyyy = new Date().getFullYear();
			for (let i = 0; i < 10; i++) {
				let toYear: any = (yyyy + 1).toString().slice(2);
				this.yearList.push({ value: yyyy, name: yyyy + "-" + toYear });
				yyyy -= 1;
			}
			this.accessLoader = true;
			this.apiService.getUserAccess().subscribe((data: any) => {
				this.accessLoader = false;
				try {
					this.user_type = data.res[0].user_type;
					let accessObj: any = JSON.parse(data.res[0].UserModuleAccess);
					this.UserModuleAccess = accessObj["CDM_dashboard"];
					this.UserModuleAccessPharma = accessObj["PHARMA_dashboard"];
					this.UserModuleAccessMHS = accessObj["MHS_dashboard"];
					this.UserModuleAccessHC = accessObj["HC_dashboard"];
					this.UserModuleAccessClip = accessObj["CLIP_dashboard"];
					if (this.user_type != 'cdm') {
						let company = data.res[0].company_id[0];
						this.cardsFilter.company_id = company;
						this.isCompanyIdNullPharma = company.pharma_id ? false : true;
						this.isCompanyIdNullMHS = company.mhs_id ? false : true;
						this.isCompanyIdNullHC = company.hc_id ? false : true;
						this.isCompanyIdNullClip = company.clip_id ? false : true;
						if (this.user_type == 'fleet') {
							let fleet = data.res[0].fleet_id[0];
							this.cardsFilter.fleet_id = fleet
						}
						if (this.UserModuleAccessPharma == 'Full' && !this.isCompanyIdNullPharma) {
							this.platform_dropdown.push({ name: "Pharma", val: "Pharma" });
						}
						if (this.UserModuleAccessHC == 'Full' && !this.isCompanyIdNullHC) {
							this.platform_dropdown.push({ name: "RMA", val: "HC" });
						}
						if (this.UserModuleAccessClip == 'Full' && !this.isCompanyIdNullClip && this.user_type=='company') {
							this.platform_dropdown.push({ name: "Shore Care", val: "CLIP" });
						}
						if (this.UserModuleAccessMHS == 'Full' && !this.isCompanyIdNullMHS && this.user_type=='company') {
							this.platform_dropdown.push({ name: "Psychometry", val: "MHS" });
						}
						try {
							this.cardsFilter.platform = this.platform_dropdown[0].val;
						} catch (error) {}
						this.initVesselDatatable();
						this.getAllCountData();
					} else {
						if (sessionStorage.getItem("selectedCompanyFilter")) {
							try {
								let obj = JSON.parse(sessionStorage.getItem("selectedCompanyFilter")!);
								if (obj.hc_id || obj.pharma_id || obj.mhs_id || obj.clip_id) {
									this.cardsFilter.company_id = obj;
								}
							} catch (error) { }
						}
						if (this.UserModuleAccessPharma == 'Full') {
							this.platform_dropdown.push({ name: "Pharma", val: "Pharma" });
						}
						if (this.UserModuleAccessHC == 'Full') {
							this.platform_dropdown.push({ name: "RMA", val: "HC" });
						}
						if (this.UserModuleAccessClip == 'Full') {
							this.platform_dropdown.push({ name: "Shore Care", val: "CLIP" });
						}
						if (this.UserModuleAccessMHS == 'Full') {
							this.platform_dropdown.push({ name: "Psychometry", val: "MHS" });
						}
						try {
							this.cardsFilter.platform = this.platform_dropdown[0].val;
						} catch (error) {}
						this.getCurrencyList();
						this.getAllData();
						this.getAllCountData();
						this.companyDropdown();
					}
				} catch (error) { }
			}, (error: any) => {
				this.accessLoader = false;
				this.UserModuleAccess = "NoAccess";
				this.UserModuleAccessPharma = "NoAccess";
				this.UserModuleAccessMHS = "NoAccess";
				this.UserModuleAccessHC = "NoAccess";
				this.UserModuleAccessClip = "NoAccess";
			});
		});
	}

	initVesselDatatable() {
		this.dtOptions = {
			pagingType: 'full_numbers',
			serverSide: true,
			processing: true,
			ordering: false,
			ajax: (dataTablesParameters: any, callback) => {
				this.searchVal = dataTablesParameters.search.value;
				let data: any = {
					draw: dataTablesParameters.draw,
					start: dataTablesParameters.start,
					length: dataTablesParameters.length,
					searchVal: dataTablesParameters.search.value,
				}
				if (this.cardsFilter.company_id) {
					data["company"] = this.cardsFilter.company_id.id;
				}
				if (this.cardsFilter.fleet_id) {
					data["fleet"] = this.cardsFilter.fleet_id.id;
				}
				if (this.cardsFilter.platform) {
					data["platform"] = this.cardsFilter.platform;
				}
				const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
				this.http.get<DataTablesResponse>(this.baseURL + "mdm_vessel", { params: new HttpParams(httpParams) }).subscribe((response: any) => {
					this.dataList = response.data;
					callback({
						recordsTotal: response.recordsTotal,
						recordsFiltered: response.recordsFiltered,
						data: []
					});
				}, (error: any) => {
					if (error.status == 401) { return }
					this.toastr.error("Something went wrong.", 'Error!!!', {
						timeOut: 3000,
						tapToDismiss: true,
						closeButton: true
					});
				});
			}
		}
	}

	refreshTable() {
		this.datatableElement.dtInstance.then((dtInstance) => {
			dtInstance.draw();
		});
	}

	downloadExcel() {
		let data: any = {};
		if (this.searchVal) {
			data["searchVal"] = this.searchVal;
		}
		if (this.cardsFilter.company_id) {
			data["company"] = this.cardsFilter.company_id.id;
		}
		if (this.cardsFilter.fleet_id) {
			data["fleet"] = this.cardsFilter.fleet_id.id;
		}
		if (this.cardsFilter.platform) {
			data["platform"] = this.cardsFilter.platform;
		}
		this.excelLoader = true;
		this.apiService.mdm_vessel_download(data).subscribe(data => {
			this.excelLoader = false;
			let excelData: any = data.body;
			let blob = new Blob([excelData], { type: "application/ms-excel" });
			let fileName = "Vessels" + ".xlsx";
			FileSaver.saveAs(blob, fileName);
		}, (error) => {
			this.excelLoader = false;
			if (error.status == 401) { return }
			Swal.fire('Error', 'Something went wrong.', 'error');
		});
	}

	clipText(text: any, limit: any) {
		if (!text) {
			return text;
		}
		limit = limit ? parseInt(limit, 10) : 10;
		let trail = '...';
		return text.length > limit ? text.substring(0, limit) + trail : text;
	}

	getAllData() {
		this.getTotalExpenseAppWise();
		this.getTotalSalesAppWise();
	}

	getAllCountData() {
		this.MHSCountBox();
		this.PharmaCountBox();
		this.HCCountBox();
		this.CLIPCountBox();
	}

	MHSCountBox() {
		const filter = {
			company_id: this.cardsFilter.company_id ? this.cardsFilter.company_id.mhs_id : null,
			start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date),
			end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date),
		}
		this.MHSCountsLoader = true;
		this.apiService.MHSCountBox(filter).subscribe((data: any) => {
			this.MHSCountsLoader = false;
			this.MHSCounts = data;
		}, (error) => {
			this.MHSCountsLoader = false;
			this.MHSCounts = {};
		});
	}

	PharmaCountBox() {
		const filter = {
			company_id: this.cardsFilter.company_id ? this.cardsFilter.company_id.pharma_id : null,
			start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date),
			end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date),
		}
		this.PharmaCountsLoader = true;
		this.apiService.PharmaCountBox(filter).subscribe((data: any) => {
			this.PharmaCountsLoader = false;
			this.PharmaCounts = data;
		}, (error) => {
			this.PharmaCountsLoader = false;
			this.PharmaCounts = {};
		});
	}

	HCCountBox() {
		const filter = {
			company_id: this.cardsFilter.company_id ? this.cardsFilter.company_id.hc_id : null,
			start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date),
			end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date),
		}
		this.HCCountsLoader = true;
		this.apiService.HCCountBox(filter).subscribe((data: any) => {
			this.HCCountsLoader = false;
			this.HCCounts = data;
		}, (error) => {
			this.HCCountsLoader = false;
			this.HCCounts = {};
		});
	}

	CLIPCountBox() {
		const filter = {
			company_id: this.cardsFilter.company_id ? this.cardsFilter.company_id.clip_id : null,
			start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date),
			end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date),
		}
		this.CLIPCountsLoader = true;
		this.apiService.CLIPCountBox(filter).subscribe((data: any) => {
			this.CLIPCountsLoader = false;
			this.CLIPCounts = data;
		}, (error) => {
			this.CLIPCountsLoader = false;
			this.CLIPCounts = {};
		});
	}

	getCurrencyList() {
		this.apiService.currencyList().subscribe((data: any) => {
			this.currencyList = data;
		}, (error) => {
		});
	}

	companyList_dropdown: any = [];
	companyDropdown() {
		this.apiService.getCompanyDetails({}).subscribe((data: any) => {
			this.companyList_dropdown = data.data;
		}, (error) => {
			this.companyList_dropdown = [];
		});
	}

	columnsTotalExpenseAppWise = ['month', 'overhead_amount', 'clip_amount', 'pharma_amount', 'freight_amount'];
	TotalExpenseAppWise: any[] = [];
	TotalExpenseAppWiseLoader = false;
	getTotalExpenseAppWise() {
		this.totals.total_expense = 0;
		this.totals.total_clip_expense = 0;
		this.totals.total_pharma_expense = 0;
		this.totals.total_freight_expense = 0;
		this.totals.total_overhead_expense = 0;
		this.TotalExpenseAppWiseLoader = true;
		this.apiService.TotalExpenseAppWise(this.getFilter()).subscribe((res: any) => {
			this.TotalExpenseAppWiseLoader = false;
			let data = res.data;
			this.expense_last_updated = res.last_updated;
			try {
				for (let i = 0; i < data.length; i++) {
					if (data[i].clip_amount) {
						this.totals.total_expense += data[i].clip_amount;
						this.totals.total_clip_expense += data[i].clip_amount;
					}
					if (data[i].pharma_amount) {
						this.totals.total_expense += data[i].pharma_amount;
						this.totals.total_pharma_expense += data[i].pharma_amount;
					}
					if (data[i].freight_amount) {
						this.totals.total_expense += data[i].freight_amount;
						this.totals.total_freight_expense += data[i].freight_amount;
					}
					if (data[i].overhead_amount) {
						this.totals.total_expense += data[i].overhead_amount;
						this.totals.total_overhead_expense += data[i].overhead_amount;
					}
				}
			} catch (error) { }
			this.TotalExpenseAppWise = data;
		}, (error) => {
			this.TotalExpenseAppWiseLoader = false;
		});
	}

	expenseExcelLoader = false;
	getExpenseExcel() {
		this.expenseExcelLoader = true;
		this.apiService.getExpenseExcel(this.getFilter()).subscribe(res => {
			let excelData: any = res.body;
			this.expenseExcelLoader = false;
			let blob = new Blob([excelData], { type: "application/ms-excel" });
			let fileName = "Expense.xlsx";
			FileSaver.saveAs(blob, fileName);
		}, (error) => {
			this.expenseExcelLoader = false;
			if (error.status == 401) { return }
			this.toastr.error("Something went wrong", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
		});
	}

	columnsTotalSalesAppWise = ['month', 'training_amount', 'shorecare_amount', 'clip_amount', 'pharma_amount', 'mhs_amount', 'hc_amount', 'tally_amount'];
	TotalSalesAppWise: any[] = [];
	TotalSalesAppWiseLoader = false;
	getTotalSalesAppWise() {
		this.totals.total_sales = 0;
		this.totals.total_clip_shorecare = 0;
		this.totals.total_clip_sales = 0;
		this.totals.total_pharma_sales = 0;
		this.totals.total_mhs_sales = 0;
		this.totals.total_hc_sales = 0;
		this.totals.total_tally_sales = 0;
		this.totals.total_training_sales = 0;
		this.TotalSalesAppWiseLoader = true;
		this.apiService.TotalSalesAppWise(this.getFilter()).subscribe((res: any) => {
			this.TotalSalesAppWiseLoader = false;
			let data = res.data;
			this.sales_last_updated = res.last_updated;
			try {
				for (let i = 0; i < data.length; i++) {
					if (data[i].shorecare_amount) {
						this.totals.total_sales += data[i].shorecare_amount;
						this.totals.total_clip_shorecare += data[i].shorecare_amount;
					}
					if (data[i].clip_amount) {
						this.totals.total_sales += data[i].clip_amount;
						this.totals.total_clip_sales += data[i].clip_amount;
					}
					if (data[i].pharma_amount) {
						this.totals.total_sales += data[i].pharma_amount;
						this.totals.total_pharma_sales += data[i].pharma_amount;
					}
					if (data[i].mhs_amount) {
						this.totals.total_sales += data[i].mhs_amount;
						this.totals.total_mhs_sales += data[i].mhs_amount;
					}
					if (data[i].hc_amount) {
						this.totals.total_sales += data[i].hc_amount;
						this.totals.total_hc_sales += data[i].hc_amount;
					}
					if (data[i].training_amount) {
						this.totals.total_sales += data[i].training_amount;
						this.totals.total_training_sales += data[i].training_amount;
					}
					if (data[i].tally_amount) {
						this.totals.total_sales += data[i].tally_amount;
						this.totals.total_tally_sales += data[i].tally_amount;
					}
				}
			} catch (error) { }
			this.TotalSalesAppWise = data;

		}, (error) => {
			this.TotalSalesAppWiseLoader = false;
		});
	}

	salesExcelLoader = false;
	getSalesExcel() {
		this.salesExcelLoader = true;
		this.apiService.getSalesExcel(this.getFilter()).subscribe(res => {
			let excelData: any = res.body;
			this.salesExcelLoader = false;
			let blob = new Blob([excelData], { type: "application/ms-excel" });
			let fileName = "Sales.xlsx";
			FileSaver.saveAs(blob, fileName);
		}, (error) => {
			this.salesExcelLoader = false;
			if (error.status == 401) { return }
			this.toastr.error("Something went wrong", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
		});
	}

	setCompany(comp: any) {
		if (comp) {
			sessionStorage.setItem("selectedCompanyFilter", JSON.stringify(comp));
		} else {
			sessionStorage.removeItem("selectedCompanyFilter");
		}
	}
}
