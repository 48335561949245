import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from '../../api.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
import Swal from 'sweetalert2';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { emailPattern, mobilePattern, noWhitespaceInRequiredValidator } from '../../shared/constants';

@Component({
	selector: 'app-training-create',
	templateUrl: './training-create.component.html',
	styleUrl: './training-create.component.css'
})
export class TrainingCreateComponent implements OnInit, AfterViewInit {
	breadcrumbList = [
		{
			path: this.apiService.getHomeURL(),
			name: "Home"
		},
		/* {
			path: '/app/dashboard-mdm',
			name: "MDM"
		}, */
		{
			path: "/app/training",
			name: "Training"
		}
	];
	uId: any = null;
	pagetitle = "Create Training";
	moduleForm!: FormGroup;
	showLoader: boolean = false;
	showDeleteLoader: boolean = false;
	pageLoading: boolean = false;
	formSubmitted: boolean = false;
	details: any = null;
	customError: any = {};
	UserModuleAccess: any = "Full";
	hideP = true;
	companyList: any = [];
	currencyList: any = [];
	requiredValidator = Validators.required;

	constructor(private toastr: ToastrService, private apiService: ApiService, private formBuilder: FormBuilder, private router: Router, private activeRoute: ActivatedRoute) {
		/* this.apiService.getUserAccess().subscribe((data: any) => {
			try {
				let accessObj: any = JSON.parse(data.res[0].UserModuleAccess);
				this.UserModuleAccess = accessObj["USER_Module"];
			} catch (error) { }
		}, (error: any) => {
			this.UserModuleAccess = "NoAccess";
		}); */
	}
	ngAfterViewInit(): void { }

	ngOnInit(): void {
		this.activeRoute.params.subscribe(routeParams => {
			this.uId = routeParams["uId"] == undefined ? null : routeParams["uId"];
			this.moduleForm = this.formBuilder.group({
				inv_no: [null, [this.requiredValidator, noWhitespaceInRequiredValidator]],
				inv_date: [null, [this.requiredValidator, noWhitespaceInRequiredValidator]],
				amount: [0, [this.requiredValidator, noWhitespaceInRequiredValidator, Validators.pattern(/^[+-]?([0-9]*[.])?[0-9]+$/)]],
				currency: [null, [this.requiredValidator, noWhitespaceInRequiredValidator]],
				is_paid: [false],
				company_master_id: [null, [this.requiredValidator, noWhitespaceInRequiredValidator]],
			})
			this.getCompanyList();
			this.getCurrencyList();
			if (this.uId) {
				this.breadcrumbList.push({ path: "/app/edit-training/" + this.uId, name: "Edit Training" });
				this.pagetitle = "Edit Training";
				this.pageLoading = true;
				this.getDetails();
			} else {
				this.breadcrumbList.push({ path: "/app/create-training", name: "Add Training" });
				this.pageLoading = true;
			}
		});
	}

	formChanged() {
		this.moduleForm.markAsDirty();
	}

	showError(field: string) {
		if (this.f[field].invalid && (this.formSubmitted || this.f[field].touched || this.f[field].dirty)) {
			return true;
		}
		return false;
	}

	getDetails() {
		this.apiService.getTrainingDetails({ "id": this.uId }).subscribe((data: any) => {
			this.details = data.res[0];
			this.f.inv_no.setValue(this.details.inv_no);
			this.f.inv_date.setValue(this.apiService.getDateFromDateString(this.details.inv_date));
			this.f.amount.setValue(this.details.amount);
			this.f.currency.setValue(this.details.currency);
			this.f.is_paid.setValue(this.details.is_paid);
			this.f.company_master_id.setValue(this.details.company_master);
		}, (error) => {
			this.pageLoading = false;
			if (error.status == 401) { return }
			this.toastr.error("Unable to fetch details.", 'Error', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
		});
	}

	getCompanyList() {
		this.apiService.getCompanyDetails({}).subscribe((data: any) => {
			this.companyList = data.data;
		}, (error) => {
			this.companyList = [];
		});
	}

	getCurrencyList() {
		this.apiService.currencyList().subscribe((data: any) => {
			this.currencyList = data;
		}, (error) => {
		});
	}

	get f() { return this.moduleForm.controls; }

	clearCustomError() {
		Object.keys(this.f).forEach(key => {
			if (this.f[key].hasError('incorrect')) {
				try {
					delete this.f[key].errors?.incorrect;
					this.f[key].updateValueAndValidity();
				} catch (error) {

				}

			}
		});
		this.customError = {};
	}

	saveData() {
		this.formSubmitted = true;
		this.clearCustomError();
		if (this.moduleForm.invalid) {
			this.toastr.info("Please check required parameters.", 'Warning', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
			return;
		}
		let payload: any = {
			inv_no: this.f.inv_no.value,
			inv_date: this.apiService.getDateStringFromDate(this.f.inv_date.value),
			amount: this.f.amount.value,
			currency: this.f.currency.value,
			is_paid: this.f.is_paid.value,
			company_master_id: this.f.company_master_id.value,
		};
		if (this.uId) {
			payload["id"] = this.uId;
		}
		this.showLoader = true;
		this.apiService.createUpdateTraining(payload).subscribe(data => {
			this.showLoader = false;
			this.toastr.success("Saved successfully.", 'Success', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
			this.gotoList();
		}, (error: any) => {
			this.showLoader = false;
			if (error.status == 401) { return }
			if (error.status == 409) {
				/* Swal.fire({
					title: 'Already Exists',
					html: 'Training already exists. Do you want to restore it?',
					icon: 'question',
					showCancelButton: true,
					showConfirmButton: true,
					confirmButtonText: 'Yes, Restore!',
					cancelButtonText: 'Cancel',
					confirmButtonColor: '#3e4fb1'
				}).then((result) => {
					if (result.value) {
						this.apiService.restoreTraining(error.error).subscribe((data: any) => {
							this.toastr.success("Training restored successfully", 'Success', {
								timeOut: 3000,
								tapToDismiss: true,
								closeButton: true
							});
							this.gotoList();
						}, (error) => {
							this.toastr.error("Something went wrong", 'Error', {
								timeOut: 3000,
								tapToDismiss: true,
								closeButton: true
							});
						});
					} else if (result.dismiss === Swal.DismissReason.cancel) { }
				}) */
				this.moduleForm.controls["inv_no"].setErrors({ incorrect: true });
				this.customError["inv_no"] = "Already exists";
				let selector = "#" + "inv_no";
				try {
					$('html, body').animate({
						scrollTop: $(selector).offset().top - 60 + 'px'
					}, 'fast');
				} catch (error) { }
				$(selector).focus();
			} else {
				let errorMsg: any = "Something went wrong.";
				if (error.status == 400) {
					errorMsg = error.error;
					if (typeof errorMsg == 'object') {
						for (const [key, value] of Object.entries(errorMsg)) {
							let val: any = value;
							if (val == "This field must be unique.") {
								val = "Already exists";
							}
							if (this.moduleForm.controls[key]) {
								this.moduleForm.controls[key].setErrors({ incorrect: true });
								this.customError[key] = val;
								let selector = "#" + key;
								try {
									$('html, body').animate({
										scrollTop: $(selector).offset().top - 60 + 'px'
									}, 'fast');
								} catch (error) { }
								$(selector).focus();
							} else {
								this.toastr.error(key + ":" + val, 'Error', {
									timeOut: 3000,
									tapToDismiss: true,
									closeButton: true
								});
							}

						}
						return;
					}
				} else {
					errorMsg = error.error;
				}
				this.toastr.error(errorMsg, 'Error', {
					timeOut: 3000,
					tapToDismiss: true,
					closeButton: true
				});
			}
		});
	}

	gotoList() {
		this.router.navigate(['/app/training']);
	}
}
