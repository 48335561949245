<breadcrumb [breadcrumbList]="breadcrumbList"></breadcrumb>
<div *ngIf="accessLoader" class="loader text-center">
	<img src="../assets/img/cc_loader.gif" />
</div>
<div class="card" *ngIf="UserModuleAccess=='Full' && user_type=='cdm'">
	<div class="card-header bg-primary">
		{{pagetitle}}
	</div>
	<div class="card-body">
		<form class="form-horizontal" [formGroup]="moduleForm" (ngSubmit)="saveData()" #modform="ngForm">
			<div class="row mb-3">
				<div class="col">
					<div class="form-floating">
						<input class="form-control" [ngClass]="{'is-invalid':showError('name')}" id="name"
							formControlName="name" placeholder="">
						<label for="name">Company Name*</label>
					</div>
					<p *ngIf="showError('name')" class="text-danger">
						<span *ngIf="f.name.errors?.required">
							Required
						</span>
						<span *ngIf="f.name.errors?.incorrect">
							{{customError.name}}
						</span>
					</p>
				</div>
				<div class="col">
					<div class="form-floating">
						<input class="form-control" [ngClass]="{'is-invalid':showError('email')}" id="email"
							formControlName="email" placeholder="">
						<label for="email">Email Id*</label>
					</div>
					<p *ngIf="showError('email')" class="text-danger">
						<span *ngIf="f.email.errors?.required">
							Required
						</span>
						<span *ngIf="f.email.errors?.pattern">
							Invalid
						</span>
						<span *ngIf="f.email.errors?.incorrect">
							{{customError.email}}
						</span>
					</p>
				</div>
				<div class="col">
					<div class="form-floating">
						<input class="form-control" [ngClass]="{'is-invalid':showError('phone_no')}" id="phone_no"
							formControlName="phone_no" placeholder="">
						<label for="phone_no">Phone No.*</label>
					</div>
					<p *ngIf="showError('phone_no')" class="text-danger">
						<span *ngIf="f.phone_no.errors?.required">
							Required
						</span>
						<span *ngIf="f.phone_no.errors?.pattern">
							Invalid
						</span>
						<span *ngIf="f.phone_no.errors?.incorrect">
							{{customError.phone_no}}
						</span>
					</p>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col">
					<div class="form-floating">
						<textarea class="form-control" [ngClass]="{'is-invalid':showError('address')}" id="address"
							formControlName="address" placeholder=""></textarea>
						<label for="address">Address*</label>
					</div>
					<p *ngIf="showError('address')" class="text-danger">
						<span *ngIf="f.address.errors?.required">
							Required
						</span>
						<span *ngIf="f.address.errors?.pattern">
							Invalid
						</span>
						<span *ngIf="f.address.errors?.incorrect">
							{{customError.address}}
						</span>
					</p>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col text-center">
					<button type="submit" [disabled]="showLoader || !modform.dirty"
						class="btn btn-sm btn-outline-success">
						<span class="me-1">
							<fa-icon *ngIf="showLoader" [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
							<fa-icon *ngIf="!showLoader" [icon]="['fas', 'save']"></fa-icon>
						</span>
						<span>Save</span>
					</button>
					<a class="btn btn-sm btn-outline-dark ms-1" [routerLink]="['/app/company']">Cancel</a>
				</div>
			</div>
		</form>
	</div>
</div>
<div *ngIf="UserModuleAccess!='Full' || user_type!='cdm'">
	<h3 class="text-center">You don't have access to this section.</h3>
</div>