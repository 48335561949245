import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from '../../api.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
import Swal from 'sweetalert2';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { emailPattern, mobilePattern, noWhitespaceInRequiredValidator } from '../../shared/constants';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-invoice-create',
	templateUrl: './invoice-create.component.html',
	styleUrl: './invoice-create.component.scss'
})
export class InvoiceCreateComponent implements OnInit, AfterViewInit {
	breadcrumbList = [
		{
			path: this.apiService.getHomeURL(),
			name: "Home"
		},
		{
			path: "/app/invoices",
			name: "Invoices"
		}
	];
	uId: any = null;
	pagetitle = "Create Invoice";
	moduleForm!: FormGroup;
	showLoader: boolean = false;
	showDeleteLoader: boolean = false;
	pageLoading: boolean = false;
	formSubmitted: boolean = false;
	details: any = null;
	customError: any = {};
	UserModuleAccess: any = "Full";
	hideP = true;
	companyList: any = [];
	servicesList: any = [];
	currencyList: any = [];
	typeList = [{ val: "EXP", name: "EXPENSES" }, { val: "SALE", name: "SALES" }];
	requiredValidator = Validators.required;
	user_type: any = "";
	accessLoader = false;
	errorLog: any = null;
	errorLogs = false;
	fileUploaderLoader = false;
	File_Storage_URL = environment.File_Storage_URL;

	constructor(private toastr: ToastrService, private apiService: ApiService, private formBuilder: FormBuilder, private router: Router, private activeRoute: ActivatedRoute) {
		this.accessLoader = true;
		this.apiService.getUserAccess().subscribe({
			next: (data: any) => {
				this.accessLoader = false;
				try {
					this.user_type = data.res[0].user_type;
					let accessObj: any = JSON.parse(data.res[0].UserModuleAccess);
					this.UserModuleAccess = accessObj["Invoice_Module"];
				} catch (error) { }
			}, error: (error: any) => {
				this.accessLoader = false;
				this.UserModuleAccess = "NoAccess";
			}
		});
	}
	ngAfterViewInit(): void { }

	ngOnInit(): void {
		this.activeRoute.params.subscribe(routeParams => {
			this.uId = routeParams["uId"] == undefined ? null : routeParams["uId"];
			this.moduleForm = this.formBuilder.group({
				inv_no: [null],
				inv_date: [null, [this.requiredValidator, noWhitespaceInRequiredValidator]],
				amount: [0, [this.requiredValidator, noWhitespaceInRequiredValidator, Validators.pattern(/^[+-]?([0-9]*[.])?[0-9]+$/)]],
				currency: [null, [this.requiredValidator, noWhitespaceInRequiredValidator]],
				is_paid: [false],
				services_master_id: [null, [this.requiredValidator, noWhitespaceInRequiredValidator]],
				paid_date: [null],
				invoice_upload: [null],
				credit_notes: [false],
				company_master_id: [null, [this.requiredValidator, noWhitespaceInRequiredValidator]],
			})
			this.getCompanyList();
			this.getServicesList();
			this.getCurrencyList();
			if (this.uId) {
				this.breadcrumbList.push({ path: "/app/edit-invoice/" + this.uId, name: "Edit Invoice" });
				this.pagetitle = "Edit Invoice";
				this.pageLoading = true;
				this.f.credit_notes.disable();
				this.getDetails();
			} else {
				this.breadcrumbList.push({ path: "/app/create-invoice", name: "Add Invoice" });
				this.pageLoading = true;
			}
		});
	}

	formChanged() {
		this.moduleForm.markAsDirty();
	}

	showError(field: string) {
		if (this.f[field].invalid && (this.formSubmitted || this.f[field].touched || this.f[field].dirty)) {
			return true;
		}
		return false;
	}

	getDetails() {
		this.apiService.getInvoiceDetails({ "id": this.uId }).subscribe({
			next: (data: any) => {
				this.details = data.data;
				this.f.inv_no.setValue(this.details.inv_no);
				this.f.inv_date.setValue(this.apiService.getDateFromDateString(this.details.inv_date));
				this.f.amount.setValue(this.details.amount);
				this.f.currency.setValue(this.details.currency);
				this.f.is_paid.setValue(this.details.is_paid);
				this.f.credit_notes.setValue(this.details.credit_notes);
				this.f.services_master_id.setValue(this.details.services_master ? this.details.services_master.id : null);
				this.f.paid_date.setValue(this.apiService.getDateFromDateString(this.details.paid_date));
				this.f.company_master_id.setValue(this.details.company_master ? this.details.company_master.id : null);
				this.f.invoice_upload.setValue(this.details.invoice_upload);
			}, error: (error) => {
				this.pageLoading = false;
				if (error.status == 401) { return }
				this.toastr.error("Unable to fetch details.", 'Error', {
					timeOut: 3000,
					tapToDismiss: true,
					closeButton: true
				});
			}
		});
	}

	getCompanyList() {
		this.apiService.getCompanyDetails({}).subscribe({
			next: (data: any) => {
				this.companyList = data.data;
			}, error: (error) => {
				this.companyList = [];
			}
		});
	}

	getServicesList() {
		this.apiService.getServicesList().subscribe({
			next: (data: any) => {
				this.servicesList = data;
			}, error: (error) => {
				this.servicesList = [];
			}
		});
	}

	getCurrencyList() {
		this.apiService.currencyList().subscribe({
			next: (data: any) => {
				this.currencyList = data;
			}, error: (error) => {
			}
		});
	}

	get f() { return this.moduleForm.controls; }

	clearCustomError() {
		Object.keys(this.f).forEach(key => {
			if (this.f[key].hasError('incorrect')) {
				try {
					delete this.f[key].errors?.incorrect;
					this.f[key].updateValueAndValidity();
				} catch (error) {

				}

			}
		});
		this.customError = {};
	}

	saveData() {
		this.formSubmitted = true;
		this.clearCustomError();
		if (this.moduleForm.invalid) {
			this.toastr.info("Please check required parameters.", 'Warning', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
			return;
		}
		let inv_date = this.apiService.getDateStringFromDate(this.f.inv_date.value);
		let paid_date = this.apiService.getDateStringFromDate(this.f.paid_date.value);
		let payload: any = {
			inv_no: this.f.inv_no.value,
			inv_date: inv_date ? inv_date : null,
			amount: this.f.amount.value,
			credit_notes: this.f.credit_notes.value,
			currency: this.f.currency.value,
			is_paid: this.f.is_paid.value,
			services_master_id: this.f.services_master_id.value,
			paid_date: paid_date ? paid_date : null,
			company_master_id: this.f.company_master_id.value,
			invoice_upload: this.f.invoice_upload.value
		};
		if (this.uId) {
			payload["id"] = this.uId;
		}
		this.showLoader = true;
		this.apiService.createUpdateInvoice(payload).subscribe({
			next: (data) => {
				this.showLoader = false;
				this.toastr.success("Saved successfully.", 'Success', {
					timeOut: 3000,
					tapToDismiss: true,
					closeButton: true
				});
				this.gotoList();
			}, error: (error: any) => {
				this.showLoader = false;
				if (error.status == 401) { return }
				let errorMsg: any = "Something went wrong.";
				if (error.status == 400) {
					errorMsg = error.error;
					if (typeof errorMsg == 'object') {
						for (const [key, value] of Object.entries(errorMsg)) {
							let val: any = value;
							if (val == "This field must be unique.") {
								val = "Already exists";
							}
							if (this.moduleForm.controls[key]) {
								this.moduleForm.controls[key].setErrors({ incorrect: true });
								this.customError[key] = val;
								let selector = "#" + key;
								try {
									$('html, body').animate({
										scrollTop: $(selector).offset().top - 60 + 'px'
									}, 'fast');
								} catch (error) { }
								$(selector).focus();
							} else {
								this.toastr.error(key + ":" + val, 'Error', {
									timeOut: 3000,
									tapToDismiss: true,
									closeButton: true
								});
							}

						}
						return;
					}
				} else {
					errorMsg = error.error;
				}
				this.toastr.error(errorMsg, 'Error', {
					timeOut: 3000,
					tapToDismiss: true,
					closeButton: true
				});
			}
		});
	}

	gotoList() {
		this.router.navigate(['/app/invoices']);
	}

	addNewService(name: any) {
		Swal.fire({
			title: 'Create Service',
			showCancelButton: true,
			input: "text",
			inputValue: name,
			inputLabel: "Enter Service Name",
			showConfirmButton: true,
			confirmButtonText: 'Create'
		}).then((result) => {
			console.log(result)
			if (result.value) {
				this.apiService.createService(result.value).subscribe({
					next: (data: any) => {
						this.getServicesList();
					}, error: (error) => {
						this.toastr.error("Something went wrong", 'Error', {
							timeOut: 3000,
							tapToDismiss: true,
							closeButton: true
						});
					}
				});
			}
		})
	}

	public uploadFile(files: NgxFileDropEntry[]) {
		let droppedFile = files[0];
		if (droppedFile.fileEntry.isFile) {
			const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
			fileEntry.file((file: File) => {
				const formdata = new FormData();
				formdata.append("invoice_upload", file)
				this.fileUploaderLoader = true;
				this.apiService.uploadInvFile(formdata).subscribe({
					next: (data: any) => {
						this.fileUploaderLoader = false;
						this.f.invoice_upload.setValue(data);
						this.formChanged();
					}, error: (error: any) => {
						this.fileUploaderLoader = false;
						this.toastr.error("Unable to upload.", 'Error', {
							timeOut: 3000,
							tapToDismiss: true,
							closeButton: true
						});
					}
				});
			});
		} else {
			this.errorLog = "Invalid File Format.";
			this.errorLogs = true;
			setTimeout(() => {
				this.errorLogs = false;
			}, 10000);
			this.toastr.error("Invalid File Format.", 'Error', {
				timeOut: 3000,
				tapToDismiss: true,
				closeButton: true
			});
		}
	}
}
