import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import * as FileSaver from 'file-saver';
import { LegendPosition } from '@swimlane/ngx-charts';
/* import { SlideInOutAnimation } from '../animations/animations'; */
import { trigger, state, style, transition, animate, group } from '@angular/animations';

@Component({
	selector: 'app-dashboard-hc',
	templateUrl: './dashboard-hc.component.html',
	styleUrls: ['./dashboard-hc.component.scss', '../../assets/styles/dashboard-styles.scss'],
	animations: [trigger('enterAnimation', [
		transition(':enter', [
			style({ transform: 'translateY(-100%)', opacity: 0 }),
			animate('500ms', style({ transform: 'translateY(0)', opacity: 1 }))
		]),
		transition(':leave', [
			style({ transform: 'translateY(0)', opacity: 1 }),
			animate('500ms', style({ transform: 'translateY(-100%)', opacity: 0 }))
		])
	])]
})
export class DashboardHcComponent implements OnInit, AfterViewInit, OnDestroy {
	UserModuleAccess: any = "Full";
	/* yearList: number[] = []; */

	constructor(private apiService: ApiService, private activeRoute: ActivatedRoute, private toastr: ToastrService) {
		this.apiService.getUserAccess().subscribe((data: any) => {
			try {
				let accessObj: any = JSON.parse(data.res[0].UserModuleAccess);
				this.UserModuleAccess = accessObj["HC_dashboard"];
			} catch (error) { }
		}, (error: any) => {
			this.UserModuleAccess = "NoAccess";
		});
	}

	ngOnInit(): void {
		this.activeRoute.params.subscribe(routeParams => {
			/* let yyyy = new Date().getFullYear();
			for (let i = 0; i < 10; i++) {
				this.yearList.push(yyyy);
				yyyy -= 1;
			} */
			/* this.rma_dashboard_count_section();
			this.rma_dashboard_active_code_blue_section();
			this.rma_dashboard_table_section();
			this.rma_dashboard_chart_section(); */
			this.rma_dashboard_symptoms_dropdown();
			this.rma_dashboard_nationality_dropdown();
			this.rma_dashboard_company_dropdown();
			this.rma_dashboard_rank_dropdown();
			this.getCompanyWiseData();
			this.getOwnAnalysisAll();
		});
	}

	ngAfterViewInit(): void {
		/* setTimeout(()=>{this.toggleShowDiv()},500); */
	}

	ngOnDestroy(): void { }

	/* animationState = 'out';
	toggleShowDiv() {
		this.animationState = 'in';
	} */

	getCompanyWiseData() {
		this.rma_dashboard_company_period_wise_count();
		this.rma_dashboard_case_symptoms_pie_chart();
		this.rma_dashboard_sub_symptoms_bar_chart();
	}

	getOwnAnalysisAll() {
		this.rma_dashboard_own_analysis();
		this.rma_dashboard_company_analysis();
		this.rma_dashboard_case_closure_pie_chart();
	}

	pagetitle = "Health Cube";
	breadcrumbList: any[] = [
		{
			path: this.apiService.getHomeURL(),
			name: "Home"
		},
		{
			path: this.apiService.getDashboardURL("hc"),
			name: this.pagetitle
		}
	];
	dateFilter = this.apiService.getDefaultDateRange3Months(true);
	currentAccordion = "InvoiceModuleAlerts";
	dateChanged(e: any) {
		this.dateFilter = e;
		this.accordionOpened(this.currentAccordion);
	}

	/* rma_dashboard_count_loader = false;
	rma_dashboard_counts: any = {};
	rma_dashboard_count_section() {
	this.rma_dashboard_count_loader = true;
	this.apiService.rma_dashboard_count_section({}).subscribe((data: any) => {
	this.rma_dashboard_count_loader = false;
	this.rma_dashboard_counts = data;
	}, (error) => {
	this.rma_dashboard_counts = {};
	this.rma_dashboard_count_loader = false;
	});
	} */

	/* pieChartLegendPosition:LegendPosition = LegendPosition.Right; */
	/* arc_width = 0.5;
	rma_dashboard_chart_loader = false;
	rma_dashboard_chart_data: any = null;
	rma_dashboard_chart_section() {
		this.rma_dashboard_chart_loader = true;
		this.apiService.rma_dashboard_chart_section({}).subscribe((data: any) => {
			this.rma_dashboard_chart_loader = false;
			let chartData = data;
			for (let i = 0; i < chartData.symptoms_chart.length; i++) {
				chartData.symptoms_chart[i].name = chartData.symptoms_chart[i].name || "-";
			}
			for (let i = 0; i < chartData.sub_symptoms_chart.length; i++) {
				chartData.sub_symptoms_chart[i].name = chartData.sub_symptoms_chart[i].name || "-";
			}
			for (let i = 0; i < chartData.nation_chart.length; i++) {
				chartData.nation_chart[i].name = chartData.nation_chart[i].name || "-";
			}
			for (let i = 0; i < chartData.closure_chart.length; i++) {
				chartData.closure_chart[i].name = chartData.closure_chart[i].name || "-";
			}
			this.rma_dashboard_chart_data = chartData;
		}, (error) => {
			this.rma_dashboard_chart_data = null;
			this.rma_dashboard_chart_loader = false;
		});
	} */

	/* rma_dashboard_active_code_blue_loader = false;
	code_blue_table: any = [];
	rma_dashboard_active_code_blue_section() {
	this.rma_dashboard_active_code_blue_loader = true;
	this.apiService.rma_dashboard_active_code_blue_section({}).subscribe((data: any) => {
	this.rma_dashboard_active_code_blue_loader = false;
	this.code_blue_table = data.code_blue_table;
	}, (error) => {
	this.code_blue_table = [];
	this.rma_dashboard_active_code_blue_loader = false;
	});
	} */

	symptoms_dropdown: any = [];
	rma_dashboard_symptoms_dropdown() {
		this.apiService.rma_dashboard_symptoms_dropdown({}).subscribe((data: any) => {
			this.symptoms_dropdown = data.symptoms_list;
		}, (error) => {
			this.symptoms_dropdown = [];
		});
	}

	subSymptoms_dropdown1: any = [];
	subSymptoms_dropdown2: any = [];
	rma_dashboard_sub_symptoms_dropdown(symptomFilter: number) {
		let payload: any = {};
		if (symptomFilter == 1) {
			payload["symptom"] = this.ownAnalysisFilter.symptom ? this.ownAnalysisFilter.symptom.hc_id : null;
		} else if (symptomFilter == 2) {
			payload["symptom"] = this.cardsFilter.symptom ? this.cardsFilter.symptom.hc_id : null;
		}
		if (!payload["symptom"]) {
			if (symptomFilter == 1) {
				this.subSymptoms_dropdown1 = [];
			} else if (symptomFilter == 2) {
				this.subSymptoms_dropdown2 = [];
			}
			return;
		}
		this.apiService.rma_dashboard_sub_symptoms_dropdown(payload).subscribe((data: any) => {
			if (symptomFilter == 1) {
				this.subSymptoms_dropdown1 = data.sub_symptoms_rows;
			} else if (symptomFilter == 2) {
				this.subSymptoms_dropdown2 = data.sub_symptoms_rows;
			}
		}, (error) => {
			if (symptomFilter == 1) {
				this.subSymptoms_dropdown1 = [];
			} else if (symptomFilter == 2) {
				this.subSymptoms_dropdown2 = [];
			}
		});
	}

	nationalities_dropdown: any = [];
	rma_dashboard_nationality_dropdown() {
		this.apiService.rma_dashboard_nationality_dropdown({}).subscribe((data: any) => {
			this.nationalities_dropdown = data.nationality_list;
		}, (error) => {
			this.nationalities_dropdown = [];
		});
	}

	companyList_dropdown: any = [];
	rma_dashboard_company_dropdown() {
		this.apiService.rma_dashboard_company_dropdown({}).subscribe((data: any) => {
			this.companyList_dropdown = data.company_list;
		}, (error) => {
			this.companyList_dropdown = [];
		});
	}

	fleetList_dropdown1: any = [];
	fleetList_dropdown2: any = [];
	rma_dashboard_fleet_dropdown(companyFilter: number) {
		let payload: any = {};
		if (companyFilter == 1) {
			payload["comp_id"] = this.ownAnalysisFilter.company_id;
		} else if (companyFilter == 2) {
			payload["comp_id"] = this.cardsFilter.company_id;
		}
		if (!payload["comp_id"]) {
			if (companyFilter == 1) {
				this.fleetList_dropdown1 = [];
			} else if (companyFilter == 2) {
				this.fleetList_dropdown2 = [];
			}
			return;
		}
		this.apiService.rma_dashboard_fleet_dropdown(payload).subscribe((data: any) => {
			if (companyFilter == 1) {
				this.fleetList_dropdown1 = data.fleet_list;
			} else if (companyFilter == 2) {
				this.fleetList_dropdown2 = data.fleet_list;
			}
		}, (error) => {
			if (companyFilter == 1) {
				this.fleetList_dropdown1 = [];
			} else if (companyFilter == 2) {
				this.fleetList_dropdown2 = [];
			}
		});
	}

	ranks_dropdown: any = [];
	rma_dashboard_rank_dropdown() {
		this.apiService.rma_dashboard_rank_dropdown({}).subscribe((data: any) => {
			this.ranks_dropdown = data.rank_list;
		}, (error) => {
			this.ranks_dropdown = [];
		});
	}

	/* rma_dashboard_table_section_loader = false;
	rma_dashboard_table_data: any = [];
	tableFilter: any = {
	symptom: null,
	nationality: null,
	}
	rma_dashboard_table_section() {
	this.rma_dashboard_table_section_loader = true;
	this.apiService.rma_dashboard_table_section(this.tableFilter).subscribe((data: any) => {
	this.rma_dashboard_table_section_loader = false;
	this.rma_dashboard_table_data = data.sub_symp_list;
	}, (error) => {
	this.rma_dashboard_table_data = [];
	this.rma_dashboard_table_section_loader = false;
	});
	} */

	company_period_wise_count_loader = false;
	company_period_wise_count: any = {};
	cardsFilter: any = {
		symptom: null,
		/* sub_symptom: null, */
		company_id: null,
		fleet_id: null,
		rank: null,
		/* year: new Date().getFullYear(), */
		start_date: this.apiService.getDefaultDateRange3Months(false).start_date,
		end_date: this.apiService.getDefaultDateRange3Months(false).end_date
	}
	arc_width = 0.5;
	pieChartLegendPosition: LegendPosition = LegendPosition.Below;
	letters = '0123456789ABCDEF';
	colorArr = [
		"#198754",
		"#FFC107",
		"#0D6EFD",
		"#0DCAF0",
		"#DC3545",
		"#6C757D",
		"#6b5b95",
		"#86af49",
		"#c94c4c",
		"#bc5a45",
		"#EF46DD",
		"#D75D5E",
		"#4B0082",
		"#005792",
		"#6643b5"
	];
	getRandomColor() {
		let color = '#';
		for (var i = 0; i < 6; i++) {
			color += this.letters[Math.floor(Math.random() * 16)];
		}
		return color;
	}

	symptomPieChartColors: any[] = [];
	view: [number, number] = [350, 200];
	symptoms_chart_loader = false;
	symptoms_chart_data: any = [];
	rma_dashboard_company_period_wise_count() {
		let filter = {
			company_id: this.cardsFilter.company_id,
			fleet_id: this.cardsFilter.fleet_id,
			rank: this.cardsFilter.rank,
			/* symptom: this.cardsFilter.symptom ? this.cardsFilter.symptom.group_name : null, */
			/* sub_symptom: this.cardsFilter.sub_symptom ? this.cardsFilter.sub_symptom.group_name : null, */
			start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date),
			end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date),
		};
		this.company_period_wise_count_loader = true;
		this.apiService.rma_dashboard_company_period_wise_count(filter).subscribe((data: any) => {
			this.company_period_wise_count_loader = false;
			this.company_period_wise_count = data;
		}, (error) => {
			this.company_period_wise_count = {};
			this.company_period_wise_count_loader = false;
		});
	}

	getHeightWidthForChart(divId: any) {
		let width = $("#" + divId).width();
		if (width != undefined) {
			width -= 20;
			let height = width * 4 / 7;
			return {
				height: height,
				width: width
			}
		} else {
			return {
				height: 350,
				width: 200
			}
		}
	}

	rma_dashboard_case_symptoms_pie_chart() {
		this.symptoms_chart_loader = true;
		let filter = {
			company_id: this.cardsFilter.company_id,
			fleet_id: this.cardsFilter.fleet_id,
			rank: this.cardsFilter.rank,
			start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date),
			end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date),
		};
		this.apiService.rma_dashboard_case_symptoms_pie_chart(filter).subscribe((data: any) => {
			let chartData: any[] = [];
			this.symptomPieChartColors = [];
			let total_appt_symp_count = data.total_appt_symp_count;
			delete data.total_appt_symp_count;
			let i = 0;
			for (const key in data) {
				try {
					let val = data[key];
					let percent = val * 100 / total_appt_symp_count;
					if (!isNaN(percent)) {
						chartData.push({
							name: key,
							value: Math.round((percent + Number.EPSILON) * 100) / 100
						});
						/* let colorAdded = false;
						let color = this.getRandomColor();
						while (!colorAdded) {
							let len = this.symptomPieChartColors.filter((item: any) => item.value == color).length;
							if (!len) {
								this.symptomPieChartColors.push({ name: key, value: color });
								colorAdded = true;
							} else {
								color = this.getRandomColor();
							}
						} */
						this.symptomPieChartColors.push({ name: key, value: this.colorArr[i] || this.getRandomColor() });
						i++;
					}
				} catch (error) { }
			}
			this.symptoms_chart_data = chartData;
			this.symptoms_chart_loader = false;
			/* setTimeout(() => {
				$('.legend-labels').addClass('vertical-legend');
				$('.legend-labels').removeClass('horizontal-legend');
			}, 100); */
		}, (error) => {
			this.symptoms_chart_data = [];
			this.symptomPieChartColors = [];
			this.symptoms_chart_loader = false;
		});
	}

	gaugeChartFormat(value: any) {
		if (value > 100) {
			value = 100;
		}
		value = Math.round((value + Number.EPSILON) * 100) / 100;
		return value + "%";
	}

	sub_symptoms_chart_loader = false;
	subSymptomBarChartColors: any[] = [];
	sub_symptoms_chart_data: any = [];
	rma_dashboard_sub_symptoms_bar_chart() {
		this.sub_symptoms_chart_loader = true;
		let filter = {
			company_id: this.cardsFilter.company_id,
			fleet_id: this.cardsFilter.fleet_id,
			rank: this.cardsFilter.rank,
			symptom: this.cardsFilter.symptom ? this.cardsFilter.symptom.hc_id : null,
			start_date: this.apiService.getDateStringFromDate(this.cardsFilter.start_date),
			end_date: this.apiService.getDateStringFromDate(this.cardsFilter.end_date),
		};
		this.apiService.rma_dashboard_sub_symptoms_bar_chart(filter).subscribe((data: any) => {
			this.sub_symptoms_chart_data = data.case_sub_symptoms;
			this.subSymptomBarChartColors = [];
			console.log(this.sub_symptoms_chart_data);
			if (this.sub_symptoms_chart_data.length) {
				for (let i = 0; i < this.sub_symptoms_chart_data[0].series.length; i++) {
					this.subSymptomBarChartColors.push({
						name: this.sub_symptoms_chart_data[0].series[i].name,
						value: this.colorArr[i] || this.getRandomColor()
					});
					/* let colorAdded = false;
					let color = this.getRandomColor();
					while (!colorAdded) {
						let len = this.subSymptomBarChartColors.filter((item: any) => item.value == color).length;
						if (!len) {
							this.subSymptomBarChartColors.push({
								name: this.sub_symptoms_chart_data[0].series[i].name,
								value: color
							});
							colorAdded = true;
						} else {
							color = this.getRandomColor();
						}
					} */
				}
			}
			this.sub_symptoms_chart_loader = false;
		}, (error) => {
			this.sub_symptoms_chart_data = [];
			this.subSymptomBarChartColors = [];
			this.sub_symptoms_chart_loader = false;
		});
	}

	ownAnalysisFilter: any = {
		symptom: null,
		sub_symptom: null,
		company_id: null,
		fleet_id: null,
		nationality: null,
		rank: null,
		age: "",
		start_date: this.apiService.getDefaultDateRange3Months(false).start_date,
		end_date: this.apiService.getDefaultDateRange3Months(false).end_date,
		case_status: null
	}
	/* ageList = [
		{ id: "18-30", age: "18-30" },
		{ id: "31-40", age: "31-40" },
		{ id: "41-50", age: "41-50" },
		{ id: "51+", age: "51+" }
	]; */
	case_statusList = ['Open', 'Closed', 'Reopen', 'Repatriation'];

	rma_dashboard_own_analysis_loader = false;
	rma_dashboard_own_analysis_data: any = {};
	rma_dashboard_own_analysis() {
		this.rma_dashboard_own_analysis_loader = true;
		let filter = {
			company_id: this.ownAnalysisFilter.company_id,
			fleet_id: this.ownAnalysisFilter.fleet_id,
			nationality: this.ownAnalysisFilter.nationality,
			rank: this.ownAnalysisFilter.rank,
			age: this.ownAnalysisFilter.age,
			start_date: this.apiService.getDateStringFromDate(this.ownAnalysisFilter.start_date),
			end_date: this.apiService.getDateStringFromDate(this.ownAnalysisFilter.end_date),
			symptom: this.ownAnalysisFilter.symptom ? this.ownAnalysisFilter.symptom.group_name : null,
			sub_symptom: this.ownAnalysisFilter.sub_symptom ? this.ownAnalysisFilter.sub_symptom.group_name : null,
		};
		this.apiService.rma_dashboard_own_analysis(filter).subscribe((data: any) => {
			this.rma_dashboard_own_analysis_data = data;

			let total_inner_count = this.rma_dashboard_own_analysis_data.total_inner_count;
			let total_inner_count_percent = total_inner_count * 100 / this.rma_dashboard_own_analysis_data.apptt_count;
			try {
				if (!isNaN(total_inner_count_percent)) {
					total_inner_count_percent = Math.round((total_inner_count_percent + Number.EPSILON) * 100) / 100;
				} else {
					total_inner_count_percent = 0;
				}
			} catch (error) {
				total_inner_count_percent = 0;
			}
			this.rma_dashboard_own_analysis_data.total_inner_count_percent = total_inner_count_percent;
			this.rma_dashboard_own_analysis_loader = false;
		}, (error) => {
			this.rma_dashboard_own_analysis_data = {};
			this.rma_dashboard_own_analysis_loader = false;
		});
	}

	rma_dashboard_company_analysis_loader = false;
	rma_dashboard_company_analysis_data: any[] = [];
	rma_dashboard_company_analysis() {
		this.rma_dashboard_company_analysis_loader = true;
		let filter = {
			company_id: this.ownAnalysisFilter.company_id,
			fleet_id: this.ownAnalysisFilter.fleet_id,
			start_date: this.apiService.getDateStringFromDate(this.ownAnalysisFilter.start_date),
			end_date: this.apiService.getDateStringFromDate(this.ownAnalysisFilter.end_date),
			symptom: this.ownAnalysisFilter.symptom ? this.ownAnalysisFilter.symptom.group_name : null,
			sub_symptom: this.ownAnalysisFilter.sub_symptom ? this.ownAnalysisFilter.sub_symptom.group_name : null,
			case_status: this.ownAnalysisFilter.case_status
		};
		this.apiService.rma_dashboard_company_analysis(filter).subscribe((data: any) => {
			let total_company_count = data.total_company_count;
			let total_company_count_percent = total_company_count * 100 / data.apptt_count;
			try {
				if (!isNaN(total_company_count_percent)) {
					total_company_count_percent = Math.round((total_company_count_percent + Number.EPSILON) * 100) / 100;
				} else {
					total_company_count_percent = 0;
				}
			} catch (error) {
				total_company_count_percent = 0;
			}
			let selected_company_count = data.selected_company_count;
			let selected_company_count_percent = selected_company_count * 100 / data.apptt_count;
			try {
				if (!isNaN(selected_company_count_percent)) {
					selected_company_count_percent = Math.round((selected_company_count_percent + Number.EPSILON) * 100) / 100;
				} else {
					selected_company_count_percent = 0;
				}
			} catch (error) {
				selected_company_count_percent = 0;
			}
			this.rma_dashboard_company_analysis_data = [
				{
					"name": "All Comp.(%)",
					"value": total_company_count_percent
				},
				{
					"name": "Selected Comp.(%)",
					"value": selected_company_count_percent
				}
			]
			this.rma_dashboard_company_analysis_loader = false;
		}, (error) => {
			this.rma_dashboard_company_analysis_data = [];
			this.rma_dashboard_company_analysis_loader = false;
		});
	}

	case_closure_pie_chart_loader = false;
	case_closure_pie_chart_data: any[] = [];
	case_closure_pie_chart_colors: any[] = [];
	rma_dashboard_case_closure_pie_chart() {
		this.case_closure_pie_chart_loader = true;
		let filter = {
			company_id: this.ownAnalysisFilter.company_id,
			fleet_id: this.ownAnalysisFilter.fleet_id,
			start_date: this.apiService.getDateStringFromDate(this.ownAnalysisFilter.start_date),
			end_date: this.apiService.getDateStringFromDate(this.ownAnalysisFilter.end_date),
			symptom: this.ownAnalysisFilter.symptom ? this.ownAnalysisFilter.symptom.group_name : null,
			sub_symptom: this.ownAnalysisFilter.sub_symptom ? this.ownAnalysisFilter.sub_symptom.group_name : null
		};
		console.log("pie"+filter);
		this.apiService.rma_dashboard_case_closure_pie_chart(filter).subscribe((data: any) => {
			let chartData: any[] = [];
			this.case_closure_pie_chart_colors = [];
			let total_cases = data.total_cases;
			delete data.total_cases;
			let i = 0;
			for (const key in data) {
				try {
					let val = data[key];
					let percent = val * 100 / total_cases;
					let name: any = key;
					try {
						let name_temp: any = key.split("_");
						for (let i = 0; i < name_temp.length; i++) {
							name_temp[i] = name_temp[i][0].toUpperCase() + name_temp[i].substr(1);
						}
						name_temp = name_temp.join(" ");
						name_temp = name_temp.slice(0, -6);
						name = name_temp;
					} catch (error) { }
					if (!isNaN(percent)) {
						chartData.push({
							name: name,
							value: Math.round((percent + Number.EPSILON) * 100) / 100
						});
						this.case_closure_pie_chart_colors.push({ name: name, value: this.colorArr[i] || this.getRandomColor() });
						i++;
						/* let colorAdded = false;
						let color = this.getRandomColor();
						while (!colorAdded) {
							let len = this.case_closure_pie_chart_colors.filter((item: any) => item.value == color).length;
							if (!len) {
								this.case_closure_pie_chart_colors.push({ name: name, value: color });
								colorAdded = true;
							} else {
								color = this.getRandomColor();
							}
						} */
					}
				} catch (error) { }
			}
			this.case_closure_pie_chart_data = chartData;
			this.case_closure_pie_chart_loader = false;
		}, (error) => {
			this.case_closure_pie_chart_data = [];
			this.case_closure_pie_chart_colors = [];
			this.case_closure_pie_chart_loader = false;
		});
	}

	accordionOpened(id: any) {
		this.currentAccordion = id;
		/* if (id == 'CaseDistributedByDoctors') {
		this.caseDistributedByDoctors();
		} else if (id == 'CaseReviewStatus') {
		this.caseReviewStatus();
		} else if (id == 'CodeBlueCases') {
		this.codeBlueCases();
		} else if (id == 'CompleteRestDaysCases') {
		this.completeRestDaysCases();
		} else if (id == 'FatalityReportedCases') {
		this.getFatalityReportedCases();
		} else if (id == 'FeedbackManagement') {
		this.getFeedbackManagement();
		} else if (id == 'FollowupManagement') {
		this.getFollowupManagement();
		} else if (id == 'MedicalSignoffCasesDaysCases') {
		this.getMedicalSignoffCasesDaysCases();
		} else if (id == 'NewCasesHC') {
		this.getNewCasesHC();
		} else if (id == 'NotUpdatedCases') {
		this.getNotUpdatedCases();
		} else if (id == 'PortVisitsCases') {
		this.getPortVisitsCases();
		} else if (id == 'ScheduledCallsWithSpecialist') {
		this.getScheduledCallsWithSpecialist();
		} else if (id == 'VesselCountsHC') {
		this.getVesselCountsHC();
		} else */ if (id == 'RmaBillingListReports') {
			this.getRmaBillingListReports();
		}
	}

	RmaBillingListReports: any[] = [];
	RmaBillingListReportsLoader = false;
	RmaBillingListReportsExcelLoader = false;
	RmaBillingListReportsExcelName = "RMA Billing Reports.xlsx";
	RmaBillingListReportsHeader = "RMA Billing Reports";
	getRmaBillingListReports() {
		this.RmaBillingListReportsLoader = true;
		this.apiService.simpleGetAPICall("RmaBillingListReports", this.dateFilter).subscribe((data: any) => {
			this.RmaBillingListReportsLoader = false;
			this.RmaBillingListReports = data;
		}, (error) => {
			this.RmaBillingListReportsLoader = false;
		});
	}
	getRmaBillingListReportsExcel(e: any) {
		e.stopPropagation();
		this.RmaBillingListReportsExcelLoader = true;
		this.apiService.simpleGetAPICallArrayBuffer("RmaBillingListReportsExcelDownload", this.dateFilter).subscribe(res => {
			let excelData: any = res.body;
			this.RmaBillingListReportsExcelLoader = false;
			let blob = new Blob([excelData], { type: "application/ms-excel" });
			let fileName = this.RmaBillingListReportsExcelName;
			FileSaver.saveAs(blob, fileName);
		}, (error) => {
			this.RmaBillingListReportsExcelLoader = false;
			if (error.status == 401) { return }
			this.toastr.error("Unable to download excel", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
		});
	}

	/* CaseDistributedByDoctors: any[] = [];
	CaseDistributedByDoctorsLoader = false;
	CaseDistributedByDoctorsExcelLoader = false;
	CaseDistributedByDoctorsExcelName = "Cases By Doctors.xlsx";
	CaseDistributedByDoctorsHeader = "Cases By Doctors";
	caseDistributedByDoctors() {
	this.CaseDistributedByDoctorsLoader = true;
	this.apiService.simpleGetAPICall("CaseDistributedByDoctors", this.dateFilter).subscribe((data: any) => {
	this.CaseDistributedByDoctorsLoader = false;
	this.CaseDistributedByDoctors = data;
	}, (error) => {
	this.CaseDistributedByDoctorsLoader = false;
	});
	}
	caseDistributedByDoctorsExcelDownload(e: any) {
	e.stopPropagation();
	this.CaseDistributedByDoctorsExcelLoader = true;
	this.apiService.simpleGetAPICallArrayBuffer("CaseDistributedByDoctorsExcelDownload", this.dateFilter).subscribe(res => {
	let excelData: any = res.body;
	this.CaseDistributedByDoctorsExcelLoader = false;
	let blob = new Blob([excelData], { type: "application/ms-excel" });
	let fileName = this.CaseDistributedByDoctorsExcelName;
	FileSaver.saveAs(blob, fileName);
	}, (error) => {
	this.CaseDistributedByDoctorsExcelLoader = false;
	if (error.status == 401) { return }
	this.toastr.error("Unable to download excel", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
	});
	}
	
	CaseReviewStatus: any[] = [];
	CaseReviewStatusLoader = false;
	CaseReviewStatusExcelLoader = false;
	CaseReviewStatusExcelName = "Case Review Status.xlsx";
	CaseReviewStatusHeader = "Case Review Status";
	caseReviewStatus() {
	this.CaseReviewStatusLoader = true;
	this.apiService.simpleGetAPICall("CaseReviewStatus", this.dateFilter).subscribe((data: any) => {
	this.CaseReviewStatusLoader = false;
	try {
	let len = data.length;
	for (let i = 0; i < len; i++) {
	try {
	data[i].symptoms = data[i].symptoms.split(",").join(", ");
	} catch (error) { }
	try {
	data[i].sub_symptoms = data[i].sub_symptoms.split(",").join(", ");
	} catch (error) { }
	}
	} catch (error) { }
	this.CaseReviewStatus = data;
	}, (error) => {
	this.CaseReviewStatusLoader = false;
	});
	}
	caseReviewStatusExcelDownload(e: any) {
	e.stopPropagation();
	this.CaseReviewStatusExcelLoader = true;
	this.apiService.simpleGetAPICallArrayBuffer("CaseReviewStatusExcelDownload", this.dateFilter).subscribe(res => {
	let excelData: any = res.body;
	this.CaseReviewStatusExcelLoader = false;
	let blob = new Blob([excelData], { type: "application/ms-excel" });
	let fileName = this.CaseReviewStatusExcelName;
	FileSaver.saveAs(blob, fileName);
	}, (error) => {
	this.CaseReviewStatusExcelLoader = false;
	if (error.status == 401) { return }
	this.toastr.error("Unable to download excel", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
	});
	}
	
	CodeBlueCases: any[] = [];
	CodeBlueCasesLoader = false;
	CodeBlueCasesExcelLoader = false;
	CodeBlueCasesExcelName = "Code Blue Cases.xlsx";
	CodeBlueCasesHeader = "Code Blue Cases";
	codeBlueCases() {
	this.CodeBlueCasesLoader = true;
	this.apiService.simpleGetAPICall("CodeBlueCases", this.dateFilter).subscribe((data: any) => {
	this.CodeBlueCasesLoader = false;
	try {
	let len = data.length;
	for (let i = 0; i < len; i++) {
	try {
	data[i].symptoms = data[i].symptoms.split(",").join(", ");
	} catch (error) { }
	try {
	data[i].sub_symptoms = data[i].sub_symptoms.split(",").join(", ");
	} catch (error) { }
	}
	} catch (error) { }
	this.CodeBlueCases = data;
	}, (error) => {
	this.CodeBlueCasesLoader = false;
	});
	}
	codeBlueCasesExcelDownload(e: any) {
	e.stopPropagation();
	this.CodeBlueCasesExcelLoader = true;
	this.apiService.simpleGetAPICallArrayBuffer("CodeBlueCasesExcelDownload", this.dateFilter).subscribe(res => {
	let excelData: any = res.body;
	this.CodeBlueCasesExcelLoader = false;
	let blob = new Blob([excelData], { type: "application/ms-excel" });
	let fileName = this.CodeBlueCasesExcelName;
	FileSaver.saveAs(blob, fileName);
	}, (error) => {
	this.CodeBlueCasesExcelLoader = false;
	if (error.status == 401) { return }
	this.toastr.error("Unable to download excel", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
	});
	}
	
	CompleteRestDaysCases: any[] = [];
	CompleteRestDaysCasesLoader = false;
	CompleteRestDaysCasesExcelLoader = false;
	CompleteRestDaysCasesExcelName = "Complete Rest Days Cases.xlsx";
	CompleteRestDaysCasesHeader = "Complete Rest Days Cases";
	completeRestDaysCases() {
	this.CompleteRestDaysCasesLoader = true;
	this.apiService.simpleGetAPICall("CompleteRestDaysCases", this.dateFilter).subscribe((data: any) => {
	this.CompleteRestDaysCasesLoader = false;
	try {
	let len = data.length;
	for (let i = 0; i < len; i++) {
	try {
	data[i].symptoms = data[i].symptoms.split(",").join(", ");
	} catch (error) { }
	try {
	data[i].sub_symptoms = data[i].sub_symptoms.split(",").join(", ");
	} catch (error) { }
	}
	} catch (error) { }
	this.CompleteRestDaysCases = data;
	}, (error) => {
	this.CompleteRestDaysCasesLoader = false;
	});
	}
	completeRestDaysCasesExcelDownload(e: any) {
	e.stopPropagation();
	this.CompleteRestDaysCasesExcelLoader = true;
	this.apiService.simpleGetAPICallArrayBuffer("CompleteRestDaysCasesExcelDownload", this.dateFilter).subscribe(res => {
	let excelData: any = res.body;
	this.CompleteRestDaysCasesExcelLoader = false;
	let blob = new Blob([excelData], { type: "application/ms-excel" });
	let fileName = this.CompleteRestDaysCasesExcelName;
	FileSaver.saveAs(blob, fileName);
	}, (error) => {
	this.CompleteRestDaysCasesExcelLoader = false;
	if (error.status == 401) { return }
	this.toastr.error("Unable to download excel", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
	});
	}
	
	FatalityReportedCases: any[] = [];
	FatalityReportedCasesLoader = false;
	FatalityReportedCasesExcelLoader = false;
	FatalityReportedCasesExcelName = "Fatality Reported Cases.xlsx";
	FatalityReportedCasesHeader = "Fatality Reported Cases";
	getFatalityReportedCases() {
	this.FatalityReportedCasesLoader = true;
	this.apiService.simpleGetAPICall("FatalityReportedCases", this.dateFilter).subscribe((data: any) => {
	this.FatalityReportedCasesLoader = false;
	try {
	let len = data.length;
	for (let i = 0; i < len; i++) {
	try {
	data[i].symptoms = data[i].symptoms.split(",").join(", ");
	} catch (error) { }
	try {
	data[i].sub_symptoms = data[i].sub_symptoms.split(",").join(", ");
	} catch (error) { }
	}
	} catch (error) { }
	this.FatalityReportedCases = data;
	}, (error) => {
	this.FatalityReportedCasesLoader = false;
	});
	}
	getFatalityReportedCasesExcel(e: any) {
	e.stopPropagation();
	this.FatalityReportedCasesExcelLoader = true;
	this.apiService.simpleGetAPICallArrayBuffer("FatalityReportedCasesExcelDownload", this.dateFilter).subscribe(res => {
	let excelData: any = res.body;
	this.FatalityReportedCasesExcelLoader = false;
	let blob = new Blob([excelData], { type: "application/ms-excel" });
	let fileName = this.FatalityReportedCasesExcelName;
	FileSaver.saveAs(blob, fileName);
	}, (error) => {
	this.FatalityReportedCasesExcelLoader = false;
	if (error.status == 401) { return }
	this.toastr.error("Unable to download excel", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
	});
	}
	
	FeedbackManagement: any[] = [];
	FeedbackManagementLoader = false;
	FeedbackManagementExcelLoader = false;
	FeedbackManagementExcelName = "Feedback Management.xlsx";
	FeedbackManagementHeader = "Feedback Management";
	getFeedbackManagement() {
	this.FeedbackManagementLoader = true;
	this.apiService.simpleGetAPICall("FeedbackManagement", this.dateFilter).subscribe((data: any) => {
	this.FeedbackManagementLoader = false;
	try {
	let len = data.length;
	for (let i = 0; i < len; i++) {
	try {
	data[i].symptoms = data[i].symptoms.split(",").join(", ");
	} catch (error) { }
	try {
	data[i].sub_symptoms = data[i].sub_symptoms.split(",").join(", ");
	} catch (error) { }
	}
	} catch (error) { }
	this.FeedbackManagement = data;
	}, (error) => {
	this.FeedbackManagementLoader = false;
	});
	}
	getFeedbackManagementExcel(e: any) {
	e.stopPropagation();
	this.FeedbackManagementExcelLoader = true;
	this.apiService.simpleGetAPICallArrayBuffer("FeedbackManagementExcelDownload", this.dateFilter).subscribe(res => {
	let excelData: any = res.body;
	this.FeedbackManagementExcelLoader = false;
	let blob = new Blob([excelData], { type: "application/ms-excel" });
	let fileName = this.FeedbackManagementExcelName;
	FileSaver.saveAs(blob, fileName);
	}, (error) => {
	this.FeedbackManagementExcelLoader = false;
	if (error.status == 401) { return }
	this.toastr.error("Unable to download excel", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
	});
	}
	
	FollowupManagement: any[] = [];
	FollowupManagementLoader = false;
	FollowupManagementExcelLoader = false;
	FollowupManagementExcelName = "Followup Management.xlsx";
	FollowupManagementHeader = "Followup Management";
	getFollowupManagement() {
	this.FollowupManagementLoader = true;
	this.apiService.simpleGetAPICall("FollowupManagement", this.dateFilter).subscribe((data: any) => {
	this.FollowupManagementLoader = false;
	this.FollowupManagement = data;
	}, (error) => {
	this.FollowupManagementLoader = false;
	});
	}
	getFollowupManagementExcel(e: any) {
	e.stopPropagation();
	this.FollowupManagementExcelLoader = true;
	this.apiService.simpleGetAPICallArrayBuffer("FollowupManagementExcelDownload", this.dateFilter).subscribe(res => {
	let excelData: any = res.body;
	this.FollowupManagementExcelLoader = false;
	let blob = new Blob([excelData], { type: "application/ms-excel" });
	let fileName = this.FollowupManagementExcelName;
	FileSaver.saveAs(blob, fileName);
	}, (error) => {
	this.FollowupManagementExcelLoader = false;
	if (error.status == 401) { return }
	this.toastr.error("Unable to download excel", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
	});
	}
	
	MedicalSignoffCasesDaysCases: any[] = [];
	MedicalSignoffCasesDaysCasesLoader = false;
	MedicalSignoffCasesDaysCasesExcelLoader = false;
	MedicalSignoffCasesDaysCasesExcelName = "Medical Signoff Cases.xlsx";
	MedicalSignoffCasesDaysCasesHeader = "Medical Signoff Cases";
	getMedicalSignoffCasesDaysCases() {
	this.MedicalSignoffCasesDaysCasesLoader = true;
	this.apiService.simpleGetAPICall("MedicalSignoffCasesDaysCases", this.dateFilter).subscribe((data: any) => {
	this.MedicalSignoffCasesDaysCasesLoader = false;
	try {
	let len = data.length;
	for (let i = 0; i < len; i++) {
	try {
	data[i].symptoms = data[i].symptoms.split(",").join(", ");
	} catch (error) { }
	try {
	data[i].sub_symptoms = data[i].sub_symptoms.split(",").join(", ");
	} catch (error) { }
	}
	} catch (error) { }
	this.MedicalSignoffCasesDaysCases = data;
	}, (error) => {
	this.MedicalSignoffCasesDaysCasesLoader = false;
	});
	}
	getMedicalSignoffCasesDaysCasesExcel(e: any) {
	e.stopPropagation();
	this.MedicalSignoffCasesDaysCasesExcelLoader = true;
	this.apiService.simpleGetAPICallArrayBuffer("MedicalSignoffCasesExcelDownload", this.dateFilter).subscribe(res => {
	let excelData: any = res.body;
	this.MedicalSignoffCasesDaysCasesExcelLoader = false;
	let blob = new Blob([excelData], { type: "application/ms-excel" });
	let fileName = this.MedicalSignoffCasesDaysCasesExcelName;
	FileSaver.saveAs(blob, fileName);
	}, (error) => {
	this.MedicalSignoffCasesDaysCasesExcelLoader = false;
	if (error.status == 401) { return }
	this.toastr.error("Unable to download excel", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
	});
	}
	
	NewCasesHC: any[] = [];
	NewCasesHCLoader = false;
	NewCasesHCExcelLoader = false;
	NewCasesHCExcelName = "New Cases.xlsx";
	NewCasesHCHeader = "New Cases";
	getNewCasesHC() {
	this.NewCasesHCLoader = true;
	this.apiService.simpleGetAPICall("NewCasesHC", this.dateFilter).subscribe((data: any) => {
	this.NewCasesHCLoader = false;
	this.NewCasesHC = data;
	}, (error) => {
	this.NewCasesHCLoader = false;
	});
	}
	getNewCasesHCExcel(e: any) {
	e.stopPropagation();
	this.NewCasesHCExcelLoader = true;
	this.apiService.simpleGetAPICallArrayBuffer("NewCasesHCExcelDownload", this.dateFilter).subscribe(res => {
	let excelData: any = res.body;
	this.NewCasesHCExcelLoader = false;
	let blob = new Blob([excelData], { type: "application/ms-excel" });
	let fileName = this.NewCasesHCExcelName;
	FileSaver.saveAs(blob, fileName);
	}, (error) => {
	this.NewCasesHCExcelLoader = false;
	if (error.status == 401) { return }
	this.toastr.error("Unable to download excel", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
	});
	}
	
	NotUpdatedCases: any[] = [];
	NotUpdatedCasesLoader = false;
	NotUpdatedCasesExcelLoader = false;
	NotUpdatedCasesExcelName = "Not Updated Cases.xlsx";
	NotUpdatedCasesHeader = "Not Updated Cases";
	getNotUpdatedCases() {
	this.NotUpdatedCasesLoader = true;
	this.apiService.simpleGetAPICall("NotUpdatedCases", this.dateFilter).subscribe((data: any) => {
	this.NotUpdatedCasesLoader = false;
	try {
	let len = data.length;
	for (let i = 0; i < len; i++) {
	try {
	data[i].symptoms = data[i].symptoms.split(",").join(", ");
	} catch (error) { }
	try {
	data[i].sub_symptoms = data[i].sub_symptoms.split(",").join(", ");
	} catch (error) { }
	}
	} catch (error) { }
	this.NotUpdatedCases = data;
	}, (error) => {
	this.NotUpdatedCasesLoader = false;
	});
	}
	getNotUpdatedCasesExcel(e: any) {
	e.stopPropagation();
	this.NotUpdatedCasesExcelLoader = true;
	this.apiService.simpleGetAPICallArrayBuffer("NotUpdatedCasesExcelDownload", this.dateFilter).subscribe(res => {
	let excelData: any = res.body;
	this.NotUpdatedCasesExcelLoader = false;
	let blob = new Blob([excelData], { type: "application/ms-excel" });
	let fileName = this.NotUpdatedCasesExcelName;
	FileSaver.saveAs(blob, fileName);
	}, (error) => {
	this.NotUpdatedCasesExcelLoader = false;
	if (error.status == 401) { return }
	this.toastr.error("Unable to download excel", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
	});
	}
	
	PortVisitsCases: any[] = [];
	PortVisitsCasesLoader = false;
	PortVisitsCasesExcelLoader = false;
	PortVisitsCasesExcelName = "Port Visit Cases.xlsx";
	PortVisitsCasesHeader = "Port Visit Cases";
	getPortVisitsCases() {
	this.PortVisitsCasesLoader = true;
	this.apiService.simpleGetAPICall("PortVisitsCases", this.dateFilter).subscribe((data: any) => {
	this.PortVisitsCasesLoader = false;
	try {
	let len = data.length;
	for (let i = 0; i < len; i++) {
	try {
	data[i].symptoms = data[i].symptoms.split(",").join(", ");
	} catch (error) { }
	try {
	data[i].sub_symptoms = data[i].sub_symptoms.split(",").join(", ");
	} catch (error) { }
	}
	} catch (error) { }
	this.PortVisitsCases = data;
	}, (error) => {
	this.PortVisitsCasesLoader = false;
	});
	}
	getPortVisitsCasesExcel(e: any) {
	e.stopPropagation();
	this.PortVisitsCasesExcelLoader = true;
	this.apiService.simpleGetAPICallArrayBuffer("PortVisitsCasesExcelDownload", this.dateFilter).subscribe(res => {
	let excelData: any = res.body;
	this.PortVisitsCasesExcelLoader = false;
	let blob = new Blob([excelData], { type: "application/ms-excel" });
	let fileName = this.PortVisitsCasesExcelName;
	FileSaver.saveAs(blob, fileName);
	}, (error) => {
	this.PortVisitsCasesExcelLoader = false;
	if (error.status == 401) { return }
	this.toastr.error("Unable to download excel", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
	});
	}
	
	ScheduledCallsWithSpecialist: any[] = [];
	ScheduledCallsWithSpecialistLoader = false;
	ScheduledCallsWithSpecialistExcelLoader = false;
	ScheduledCallsWithSpecialistExcelName = "Scheduled Calls With Specialist.xlsx";
	ScheduledCallsWithSpecialistHeader = "Scheduled Calls With Specialist";
	getScheduledCallsWithSpecialist() {
	this.ScheduledCallsWithSpecialistLoader = true;
	this.apiService.simpleGetAPICall("ScheduledCallsWithSpecialist", this.dateFilter).subscribe((data: any) => {
	this.ScheduledCallsWithSpecialistLoader = false;
	try {
	let len = data.length;
	for (let i = 0; i < len; i++) {
	try {
	data[i].symptoms = data[i].symptoms.split(",").join(", ");
	} catch (error) { }
	try {
	data[i].sub_symptoms = data[i].sub_symptoms.split(",").join(", ");
	} catch (error) { }
	}
	} catch (error) { }
	this.ScheduledCallsWithSpecialist = data;
	}, (error) => {
	this.ScheduledCallsWithSpecialistLoader = false;
	});
	}
	getScheduledCallsWithSpecialistExcel(e: any) {
	e.stopPropagation();
	this.ScheduledCallsWithSpecialistExcelLoader = true;
	this.apiService.simpleGetAPICallArrayBuffer("ScheduledCallsWithSpecialistExcelDownload", this.dateFilter).subscribe(res => {
	let excelData: any = res.body;
	this.ScheduledCallsWithSpecialistExcelLoader = false;
	let blob = new Blob([excelData], { type: "application/ms-excel" });
	let fileName = this.ScheduledCallsWithSpecialistExcelName;
	FileSaver.saveAs(blob, fileName);
	}, (error) => {
	this.ScheduledCallsWithSpecialistExcelLoader = false;
	if (error.status == 401) { return }
	this.toastr.error("Unable to download excel", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
	});
	}
	
	VesselCountsHC: any[] = [];
	VesselCountsHCLoader = false;
	VesselCountsHCExcelLoader = false;
	VesselCountsHCExcelName = "Vessel Counts.xlsx";
	VesselCountsHCHeader = "Vessel Counts";
	getVesselCountsHC() {
	this.VesselCountsHCLoader = true;
	this.apiService.simpleGetAPICall("VesselCountsHC", this.dateFilter).subscribe((data: any) => {
	this.VesselCountsHCLoader = false;
	this.VesselCountsHC = data;
	}, (error) => {
	this.VesselCountsHCLoader = false;
	});
	}
	getVesselCountsHCExcel(e: any) {
	e.stopPropagation();
	this.VesselCountsHCExcelLoader = true;
	this.apiService.simpleGetAPICallArrayBuffer("VesselCountsHCExcelDownload", this.dateFilter).subscribe(res => {
	let excelData: any = res.body;
	this.VesselCountsHCExcelLoader = false;
	let blob = new Blob([excelData], { type: "application/ms-excel" });
	let fileName = this.VesselCountsHCExcelName;
	FileSaver.saveAs(blob, fileName);
	}, (error) => {
	this.VesselCountsHCExcelLoader = false;
	if (error.status == 401) { return }
	this.toastr.error("Unable to download excel", 'Error', { timeOut: 3000, tapToDismiss: true, closeButton: true });
	});
	} */
}