import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class AppInterceptorService implements HttpInterceptor {
	isRefreshingToken = false;

	constructor(private router: Router, private apiService: ApiService) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let headers: any = {};
		let token: any = localStorage.getItem('cdm_token');
		if (token) {
			headers["Authorization"] = token;
		}
		const httpRequest = req.clone({
			setHeaders: headers
		})
		return next.handle(httpRequest).pipe(tap(() => { }, (err: any) => {
			if (err instanceof HttpErrorResponse) {
				if (err.status == 401) {
					if(req.url.indexOf("signin") <0){
						this.apiService.navigateToLogin(true);
					}
				}
			}
		}));
	}
}
