<breadcrumb [breadcrumbList]="breadcrumbList"></breadcrumb>
<div *ngIf="accessLoader" class="loader text-center">
	<img src="../assets/img/cc_loader.gif" />
</div>
<div class="card" *ngIf="UserModuleAccess=='Full' && user_type=='cdm'">
	<div class="card-header bg-primary">
		{{pagetitle}}
	</div>
	<div class="card-body">
		<form class="form-horizontal" [formGroup]="moduleForm" (ngSubmit)="saveData()" #modform="ngForm">
			<div class="row mb-3">
				<div class="col">
					<div class="form-floating">
						<input class="form-control" [ngClass]="{'is-invalid':showError('name')}" id="name"
							formControlName="name" placeholder="">
						<label for="name">Country Name*</label>
					</div>
					<p *ngIf="showError('name')" class="text-danger">
						<span *ngIf="f.name.errors?.required">
							Required
						</span>
						<span *ngIf="f.name.errors?.incorrect">
							{{customError.name}}
						</span>
					</p>
				</div>
				<div class="col">
					<div class="form-floating">
						<input class="form-control" [ngClass]="{'is-invalid':showError('sh_name')}" id="sh_name"
							formControlName="sh_name" placeholder="">
						<label for="sh_name">Short Name 1*</label>
					</div>
					<p *ngIf="showError('sh_name')" class="text-danger">
						<span *ngIf="f.sh_name.errors?.required">
							Required
						</span>
						<span *ngIf="f.sh_name.errors?.incorrect">
							{{customError.sh_name}}
						</span>
					</p>
				</div>
				<div class="col">
					<div class="form-floating">
						<input class="form-control" [ngClass]="{'is-invalid':showError('sh_name1')}" id="sh_name1"
							formControlName="sh_name1" placeholder="">
						<label for="sh_name1">Short Name 2*</label>
					</div>
					<p *ngIf="showError('sh_name1')" class="text-danger">
						<span *ngIf="f.sh_name1.errors?.required">
							Required
						</span>
						<span *ngIf="f.sh_name1.errors?.incorrect">
							{{customError.sh_name1}}
						</span>
					</p>
				</div>
				<div class="col">
					<div class="form-floating">
						<input class="form-control" [ngClass]="{'is-invalid':showError('nationality')}" id="nationality"
							formControlName="nationality" placeholder="">
						<label for="nationality">Nationality*</label>
					</div>
					<p *ngIf="showError('nationality')" class="text-danger">
						<span *ngIf="f.nationality.errors?.required">
							Required
						</span>
						<span *ngIf="f.nationality.errors?.incorrect">
							{{customError.nationality}}
						</span>
					</p>
				</div>
			</div>
			<div class="row mb-3">
				<div class="col text-center">
					<button type="submit" [disabled]="showLoader || !modform.dirty"
						class="btn btn-sm btn-outline-success">
						<span class="me-1">
							<fa-icon *ngIf="showLoader" [icon]="['fas', 'spinner']" animation="spin"></fa-icon>
							<fa-icon *ngIf="!showLoader" [icon]="['fas', 'save']"></fa-icon>
						</span>
						<span>Save</span>
					</button>
					<a class="btn btn-sm btn-outline-dark ms-1" [routerLink]="['/app/country']">Cancel</a>
				</div>
			</div>
		</form>
	</div>
</div>
<div *ngIf="UserModuleAccess!='Full' || user_type!='cdm'">
	<h3 class="text-center">You don't have access to this section.</h3>
</div>